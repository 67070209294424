import { atom, Atom, PrimitiveAtom, useAtomValue, useSetAtom, WritableAtom } from "jotai";
import { FC, useEffect } from "react";

import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";

type Context<T extends GlobalModalProps> = Omit<T, keyof GlobalModalProps>;

/**
 * A modal atom is actually a collection of atoms that represent the different states of a modal
 * as well as the modal component itself.
 */
interface ModalAtomValue<T extends GlobalModalProps> {
  isOpenAtom: PrimitiveAtom<boolean>;
  contextAtom: PrimitiveAtom<Context<T> | undefined>;
  openAtom: WritableAtom<null, Array<Context<T> | undefined>, void>;
  closeAtom: WritableAtom<null, [], void>;
  toggleAtom: WritableAtom<null, [], void>;
  Component: FC<React.PropsWithChildren<T>>;
}

export type ModalAtom<T extends GlobalModalProps> = Atom<ModalAtomValue<T>>;

export type GlobalModalAtom = ModalAtom<any>;

/**
 * This atom holds all the global modals that are currently being used somewhere in the app.
 *
 * Registration of a modal happens automatically anytime the useModal hook is used.
 */
const globalModalsAtom = atom<Set<GlobalModalAtom>>(new Set<GlobalModalAtom>());

/**
 * Convert the global modals set to an array for easier consumption.
 */
export const globalModalsListAtom = atom(get => Array.from(get(globalModalsAtom)));

/**
 * Adds modal to the global set. This happens automatically when the useModal hook is used, so generally
 * you shouldn't need to call this directly.
 */
const registerGlobalModalAtom = atom(null, (get, set, globalAtom: GlobalModalAtom) => {
  const globalModals = get(globalModalsAtom);
  globalModals.add(globalAtom);

  set(globalModalsAtom, new Set(globalModals));
});

/**
 * Creates a modal atom that can be used in conjuction with the useModal hook
 * to manage the state of a modal
 *
 * @param component This is the component this atom should be "tied" to
 * @param initialContext The initial value of the context atom. This is optional and can be
 * overriden when calling the open function for a modal
 */
export const modalAtom = <T extends GlobalModalProps>(
  component: FC<React.PropsWithChildren<T>>,
  initialContext?: Context<T>
): ModalAtom<T> => {
  const isOpenAtom = atom(false);

  const contextAtom = atom(initialContext);

  const openAtom = atom(null, (get, set, args?: Context<T>) => {
    set(isOpenAtom, true);
    if (contextAtom) {
      set(contextAtom, args);
    }
  });

  const closeAtom = atom(null, (get, set) => set(isOpenAtom, false));

  const toggleAtom = atom(null, (get, set, args?: Context<T>) => {
    set(isOpenAtom, prev => !prev);
    if (contextAtom) {
      set(contextAtom, args);
    }
  });

  return atom(() => {
    return { isOpenAtom, contextAtom, openAtom, closeAtom, toggleAtom, Component: component };
  });
};

interface UseModalReturn<T extends GlobalModalProps> {
  isOpen: boolean;
  context: Context<T>;
  open: (args?: Context<T>) => void;
  close: () => void;
  toggle: (args?: Context<T>) => void;
  Component: FC<React.PropsWithChildren<T>>;
}

/**
 * A hook that gives you access to the state of a modal and the ability to open and close it.
 */
export const useModal = <T extends GlobalModalProps>(modalAtom: ModalAtom<T>): UseModalReturn<T> => {
  // Grab the various atoms we need from the modal atom
  const { isOpenAtom, contextAtom, openAtom, closeAtom, toggleAtom, Component } = useAtomValue<ModalAtomValue<T>>(
    modalAtom
  );

  // Register the modal with the global modals set
  // This will only register it if it isn't already registered
  //
  // Needs to be in an effect because it will actually trigger React re-renders for it's subscribers
  // and React doesn't allow that to happen in the body of a render function
  const registerGlobalModal = useSetAtom(registerGlobalModalAtom);
  useEffect(() => registerGlobalModal(modalAtom), [modalAtom, registerGlobalModal]);

  // Extract the values we need from the atoms
  const isOpen = useAtomValue(isOpenAtom);
  const context = useAtomValue(contextAtom) as Context<T>;
  const open = useSetAtom(openAtom);
  const close = useSetAtom(closeAtom);
  const toggle = useSetAtom(toggleAtom);

  return {
    isOpen,
    context,
    open,
    close,
    toggle,
    Component,
  };
};
