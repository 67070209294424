import { CompanySetupSectionType } from "App/routing/types";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useIsContractPlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { companySectionConfiguration, SECTIONS_ONLY_FOR_PAID_CUSTOMERS } from "views/CompanySetup/constants";

export const useGetCompanySectionConfigurationKeys = (): CompanySetupSectionType[] => {
  const clientIsNotPaid = !useIsContractPlanCustomer();
  const isDisplayLegacySolutionsEnabled = useFeatureFlag(FeatureFlag.DisplayLegacySolutions);

  let sections = Object.keys(companySectionConfiguration) as CompanySetupSectionType[];

  // remove some stuff not accessible to free people
  if (clientIsNotPaid) {
    sections = sections.filter(
      (section: CompanySetupSectionType) => !SECTIONS_ONLY_FOR_PAID_CUSTOMERS.includes(section)
    );
  }

  // Only show DoverInterviewer if legacy solutions is enabled
  if (!isDisplayLegacySolutionsEnabled) {
    sections = sections.filter(
      (section: CompanySetupSectionType) => section !== CompanySetupSectionType.DOVER_INTERVIEWER
    );
  }

  return sections;
};
