import { CalendlyRole, Region } from "App/components/Onboarding/AccountDetailsFormV2";
import { BDR_ONLY_CALENDLY, GEORGE_ONLY_CALENDLY } from "components/constants";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";

/**
 * Returns the Calendly URL based on the user's role.
 * This relies on the getUserInfo API call to be pre-loaded, otherwise there will be a malformed URL while the call is loading
 * This call is generally required in all dover pages but could be a caveat somewhere down the line.
 */
export function useGetCalendlyUrl(utmMedium: string, utmSource: string, utmCampaign: string): string {
  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const { data: client } = useGetUsersClientQuery();

  const roleTitleData = (userInfo?.roleTitleData as any) || {};
  const calendlyRole = roleTitleData["calendlyRole"];

  // Show George's calendly link if ALL conditions are met:
  // 1. Role is Founder/Owner or Executive/Hiring Manager
  // 2. Company is based in US/Canada or Europe
  // 3. Company is NOT a staffing agency
  const isFounderOrExec = calendlyRole === CalendlyRole.Founder || calendlyRole === CalendlyRole.HiringManager;
  const isUSCanadaOrEurope = client?.region === Region.USACanada || client?.region === Region.Europe;
  const isNotStaffing = !client?.isAgency;

  if (isFounderOrExec && isUSCanadaOrEurope && isNotStaffing) {
    return `${GEORGE_ONLY_CALENDLY}?utm_medium=${utmMedium}&utm_source=${utmSource}&utm_campaign=${utmCampaign}`;
  }

  // All other cases go to Alexandra
  return `${BDR_ONLY_CALENDLY}?utm_medium=${utmMedium}&utm_source=${utmSource}&utm_campaign=${utmCampaign}`;
}
