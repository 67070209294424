import { Box, Card, Stack } from "@mui/material";
import React from "react";

import { ONLINE_ACCOUNT_PROVIDER_CONFIGS } from "components/dover/OnlineAccounts/constants";
import { Subtitle2 } from "components/library/typography";

export const ConnectEmailCard: React.FC<React.PropsWithChildren<any>> = () => {
  return (
    <Card>
      <Stack sx={{ alignItems: "center", justifyContent: "center" }} spacing={3} p={5}>
        <Stack sx={{ alignItems: "center", justifyContent: "center" }} spacing={2}>
          <Stack direction="row" spacing={1}>
            {ONLINE_ACCOUNT_PROVIDER_CONFIGS.map(oap => (
              <Box sx={{ width: "32px", height: "32px" }} key={`email-icon-${oap.name}`}>
                {oap.emailIcon}
              </Box>
            ))}
          </Stack>
          <Subtitle2>Connect your email</Subtitle2>
        </Stack>
        <Stack direction="row" spacing={1}>
          {ONLINE_ACCOUNT_PROVIDER_CONFIGS.map(oap => oap.emailConnectButton)}
        </Stack>
      </Stack>
    </Card>
  );
};
