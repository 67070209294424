import { Progress } from "@doverhq/dover-ui";
import { Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import { FileViewer } from "components/FileViewer";
import PageHelmet from "components/PageHelmet";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { usePrefetchFileUrl } from "services/fetchFilesApi";
import { ApplicationReview } from "services/openapi";
import ApplicationReviewForm from "views/ApplicationsReviewer/ApplicationReviewForm";
import ApplicationsBrowser from "views/ApplicationsReviewer/ApplicationsBrowser";
import { useListApplicationReviewsQuery } from "views/ApplicationsReviewer/endpoints";
import NotFound from "views/NotFound";

const ITEMS_PER_PAGE = 10;

const ApplicationsReviewer = (): React.ReactElement => {
  const hasFF = useFeatureFlag(FeatureFlag.ApplicationReviewerAccess);
  const [showReviewed, setShowReviewed] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ApplicationReview | undefined>(undefined);
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, isFetching } = useListApplicationReviewsQuery({
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
    reviewed: showReviewed,
  });

  const prefetchFile = usePrefetchFileUrl("getFile");

  useEffect((): void => {
    if (data?.results && data.results.length) {
      setSelectedItem(data.results[0]);
      data.results.forEach(app => {
        if (app.resumeLink) prefetchFile(app.resumeLink);
      });
    }
  }, [data, prefetchFile]);

  if (!hasFF) return <NotFound />;

  return (
    <>
      <PageHelmet title="Applications Reviewer" />

      <Stack direction="row">
        <Stack sx={{ width: "70%", overflowY: "auto", height: "100vh", padding: "4px" }}>
          <FileViewer url={selectedItem?.resumeLink ?? undefined} />
        </Stack>
        <Stack spacing={2} sx={{ overflowY: "auto", height: "100vh", width: "30%", padding: 1 }}>
          <Paper>
            {!(isLoading || isFetching) ? (
              <ApplicationReviewForm data={selectedItem} />
            ) : (
              <Stack sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "500px" }}>
                <Progress text="" size="large" />
              </Stack>
            )}
          </Paper>
          <Paper>
            <ApplicationsBrowser
              onItemSelected={setSelectedItem}
              data={data?.results || []}
              loading={isLoading || isFetching}
              onPageChange={setPage}
              page={page}
              showReviewed={showReviewed}
              setShowReviewed={setShowReviewed}
              currentItem={selectedItem}
              pagesCount={data ? Math.round(data.count / ITEMS_PER_PAGE) : 10}
            />
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};

export default ApplicationsReviewer;
