import React, { FC } from "react";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

interface LearnMoreLinkProps {
  linkUrl?: string;
  description: string;
  label?: string;
}

export const LearnMoreLink: FC<React.PropsWithChildren<LearnMoreLinkProps>> = ({
  linkUrl,
  description,
  label,
}: LearnMoreLinkProps) => {
  return (
    <BodySmall color={colors.grayscale.gray500}>
      {description}{" "}
      {linkUrl && (
        <ExternalLink href={linkUrl} target="_blank" rel="noopener noreferrer">
          {label ?? "Learn more"}
        </ExternalLink>
      )}
    </BodySmall>
  );
};
