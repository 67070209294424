/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobCompensation
 */
export interface JobCompensation {
    /**
     * 
     * @type {number}
     * @memberof JobCompensation
     */
    upperBound?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobCompensation
     */
    lowerBound?: number | null;
    /**
     * 
     * @type {string}
     * @memberof JobCompensation
     */
    currencyCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobCompensation
     */
    openToSharingComp?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JobCompensation
     */
    equityLowerBound?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobCompensation
     */
    equityUpperBound?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof JobCompensation
     */
    openToSharingEquity?: boolean | null;
}

export function JobCompensationFromJSON(json: any): JobCompensation {
    return JobCompensationFromJSONTyped(json, false);
}

export function JobCompensationFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobCompensation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upperBound': !exists(json, 'upper_bound') ? undefined : json['upper_bound'],
        'lowerBound': !exists(json, 'lower_bound') ? undefined : json['lower_bound'],
        'currencyCode': json['currency_code'],
        'openToSharingComp': !exists(json, 'open_to_sharing_comp') ? undefined : json['open_to_sharing_comp'],
        'equityLowerBound': !exists(json, 'equity_lower_bound') ? undefined : json['equity_lower_bound'],
        'equityUpperBound': !exists(json, 'equity_upper_bound') ? undefined : json['equity_upper_bound'],
        'openToSharingEquity': !exists(json, 'open_to_sharing_equity') ? undefined : json['open_to_sharing_equity'],
    };
}

export function JobCompensationToJSON(value?: JobCompensation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upper_bound': value.upperBound,
        'lower_bound': value.lowerBound,
        'currency_code': value.currencyCode,
        'open_to_sharing_comp': value.openToSharingComp,
        'equity_lower_bound': value.equityLowerBound,
        'equity_upper_bound': value.equityUpperBound,
        'open_to_sharing_equity': value.openToSharingEquity,
    };
}


