import React from "react";

import { ReactComponent as GoogleSVG } from "assets/icons/google.svg";
import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import ConnectButton from "components/dover/OnlineAccounts/ConnectButton";
import { googleConnectModalAtom } from "components/dover/OnlineAccounts/GoogleConnectModal";
import { appConfig } from "config/appConfig";
import { useModal } from "GlobalOverlays/atoms";

const ConnectGmailButton = (): React.ReactElement => {
  const { userAuthedSuccessfully: authed, isGmailAuthLoading: isLoading } = useUserAuthedSuccessfully();

  const { open: openModal } = useModal(googleConnectModalAtom);

  const click = (): void => {
    if (!authed) {
      openModal({});
      return;
    }
    window.open(`${appConfig.legacyAppUrl}/utils/auth`, "_blank");
  };

  return (
    <ConnectButton
      isLoading={isLoading}
      text={`${authed ? "Reauthenticate" : "Connect"} Google`}
      icon={<GoogleSVG width="14px" height="14px" />}
      onClick={click}
    />
  );
};

export default ConnectGmailButton;
