import { Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import CustomModal from "components/Modal";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";

export interface ReferAndEarnModalProps extends GlobalModalProps {}

const ReferAndEarnModal: React.FC<ReferAndEarnModalProps> = ({ isOpen, close }) => {
  return (
    <CustomModal open={isOpen} onClose={close} title="Dover Referral Program" maxWidth="sm">
      <Stack spacing={3} sx={{ padding: "24px" }}>
        {/* Header */}
        <Stack spacing={1}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "30px",
              lineHeight: "38px",
              color: "#101828",
              fontWeight: 500,
            }}
          >
            Earn $1,000 per referral
          </div>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#475467",
            }}
          >
            Introduce a new customer to Dover and get rewarded.
          </div>
        </Stack>

        {/* How it works section */}
        <Stack spacing={3}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#101828",
              fontWeight: 500,
            }}
          >
            How it works:
          </div>

          <Stack spacing={3}>
            {[
              "Recommend Dover Recruiting Partners to any company",
              "Send an email introduction to vip@dover.com",
              "Get paid when your referral spends $5,000",
            ].map((text, index) => (
              <Stack key={index} direction="row" spacing={3} alignItems="flex-start">
                <div
                  style={{
                    width: "28px",
                    height: "28px",
                    border: "1px solid #667085",
                    borderRadius: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#667085",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    flexShrink: 0,
                  }}
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#101828",
                  }}
                >
                  {text}
                </div>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* Footer text */}
        <Stack spacing={1}>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#475467",
            }}
          >
            To learn more about Dover Recruiting Partners visit:{" "}
            <a
              href="https://dover.com/recruiter"
              style={{ color: "#475467", textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              dover.com/recruiter
            </a>
          </div>
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#98A2B3",
            }}
          >
            *Referrals qualify if they haven&apos;t contacted Dover about Recruiting Partners in the past 90 days. Free
            ATS users are eligible to participate in referrals.
          </div>
        </Stack>

        {/* Share Button */}
        <Button
          variant={ButtonVariant.Primary}
          onClick={(): void => {
            const subject = "Introduction to Dover";
            const body = `Hey,

We've been using Dover for hiring and have really enjoyed it. I'd love to share Dover Recruiting Partners with you––you work with an experienced, fractional recruiter at a fraction of the cost of an agency.

More info here: dover.com/recruiter

I'm CC'ing vip@dover.com who can help give more details and get you set up. 

Best,

`;
            const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
              body
            )}&cc=vip@dover.com`;
            window.location.href = mailtoUrl;
            close();
          }}
          width="100%"
          height="44px"
          borderRadius="8px"
        >
          Share Dover Now
        </Button>
      </Stack>
    </CustomModal>
  );
};

export default ReferAndEarnModal;
