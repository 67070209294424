import { ReactElement } from "react";

export enum OnlineAccountProviderEnum {
  Google = "Google",
  Microsoft = "Microsoft",
}

export interface OnlineAccountProviderConfig {
  type: OnlineAccountProviderEnum;
  name: string;
  emailConnectButton: ReactElement;
  calendarConnectButton: ReactElement;
  icon: React.ReactElement;
  emailIcon: React.ReactElement;
  calendarIcon: React.ReactElement;
}
