import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  GENERIC_EMAIL_RESPONSE_TEMPLATE,
  HIRING_STAGE_EMAIL_TEMPLATE,
  LIST_TAG,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiGetHiringStageEmailTemplateRequest,
  ApiApiListGenericEmailResponseTemplatesRequest,
  GenericEmailResponseTemplate,
  HiringStageEmailTemplate,
} from "services/openapi";
import { showErrorToast, showSuccessToast, showPendingToast } from "utils/showToast";

const emailTemplates = doverApi.injectEndpoints({
  endpoints: build => ({
    listGenericEmailResponseTemplate: build.query<
      GenericEmailResponseTemplate[],
      ApiApiListGenericEmailResponseTemplatesRequest
    >({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listGenericEmailResponseTemplates(args);

          return {
            data: response.results,
          };
        } catch (error) {
          if (error.message) {
            showErrorToast(error.message);
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, args) => {
        return result
          ? // successful query
            [
              ...result.map(template => ({ type: GENERIC_EMAIL_RESPONSE_TEMPLATE, id: template.id } as const)),
              { type: GENERIC_EMAIL_RESPONSE_TEMPLATE, id: `${LIST_TAG}${args.jobId}` },
            ]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: GENERIC_EMAIL_RESPONSE_TEMPLATE, id: `${LIST_TAG}${args.jobId}` }];
      },
    }),
    partialUpdateGenericEmailResponseTemplate: build.mutation<
      GenericEmailResponseTemplate,
      {
        templateId: string;
        updatedTemplate: GenericEmailResponseTemplate;
        showToasts: boolean;
      }
    >({
      queryFn: async ({
        templateId,
        updatedTemplate,
        showToasts,
      }: {
        templateId: string;
        updatedTemplate: GenericEmailResponseTemplate;
        showToasts: boolean;
      }) => {
        let result: GenericEmailResponseTemplate;
        const { apiApi } = await getOpenApiClients({});

        try {
          result = await apiApi.partialUpdateGenericEmailResponseTemplate({
            id: templateId,
            data: updatedTemplate,
          });
          if (showToasts) {
            showSuccessToast("Successfully updated email template");
          }
        } catch (error) {
          let msg = "Failed to update email template. Please refresh and try again.";
          try {
            const errBody = await error.json();
            msg = Object.keys(errBody).includes("non_field_errors") ? errBody["non_field_errors"][0] : msg;
          } catch (e) {
            // eslint-disable-next-line no-empty
          }
          showErrorToast(msg);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: GENERIC_EMAIL_RESPONSE_TEMPLATE, id: args.templateId } as const];
      },
    }),
    getHiringStageEmailTemplate: build.query<HiringStageEmailTemplate, ApiApiGetHiringStageEmailTemplateRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.getHiringStageEmailTemplate(args);

          return {
            data: response,
          };
        } catch (error) {
          showErrorToast("Failed to get email template. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, args) => {
        return result ? [{ type: HIRING_STAGE_EMAIL_TEMPLATE, id: args.id }] : [];
      },
    }),
    listHiringStageEmailTemplates: build.query<HiringStageEmailTemplate[], string>({
      queryFn: async jobId => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listHiringStageEmailTemplates({ job: jobId, limit: 999 });

          return {
            data: response.results,
          };
        } catch (error) {
          showErrorToast("Failed to get email templates. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, jobId) => {
        return result
          ? // successful query
            [
              ...result.map(template => ({ type: HIRING_STAGE_EMAIL_TEMPLATE, id: template.id } as const)),
              { type: HIRING_STAGE_EMAIL_TEMPLATE, id: `${LIST_TAG}${jobId}` },
            ]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: HIRING_STAGE_EMAIL_TEMPLATE, id: `${LIST_TAG}${jobId}` }];
      },
    }),
    createHiringStageEmailTemplate: build.mutation<
      HiringStageEmailTemplate,
      { template: HiringStageEmailTemplate; jobId: string }
    >({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});

          showPendingToast("Creating email template");

          const response = await apiApi.createHiringStageEmailTemplate({ data: args.template });
          showSuccessToast("Successfully created email template");
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to create email template. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [
              // Invalidate our list so that it includes our newly updated HiringStageEmailTemplate
              { type: HIRING_STAGE_EMAIL_TEMPLATE, id: `${LIST_TAG}${args.jobId}` } as const,
            ]
          : [];
      },
    }),
    partialUpdateHiringStageEmailTemplate: build.mutation<
      HiringStageEmailTemplate,
      {
        hiringStageEmailTemplateId: string;
        updatedHiringStageEmailTemplate: HiringStageEmailTemplate;
        showToasts: boolean;
      }
    >({
      queryFn: async ({
        hiringStageEmailTemplateId,
        updatedHiringStageEmailTemplate,
        showToasts,
      }: {
        hiringStageEmailTemplateId: string;
        updatedHiringStageEmailTemplate: HiringStageEmailTemplate;
        showToasts: boolean;
      }) => {
        let result: HiringStageEmailTemplate;
        const { apiApi } = await getOpenApiClients({});

        try {
          result = await apiApi.partialUpdateHiringStageEmailTemplate({
            id: hiringStageEmailTemplateId,
            data: updatedHiringStageEmailTemplate,
          });
          if (showToasts) {
            showSuccessToast("Successfully updated email template");
          }
        } catch (error) {
          showErrorToast("Failed to update email template. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: HIRING_STAGE_EMAIL_TEMPLATE, id: args.hiringStageEmailTemplateId } as const];
      },
    }),
    deleteHiringStageEmailTemplate: build.mutation<
      void,
      {
        emailTemplateId: string;
      }
    >({
      queryFn: async ({ emailTemplateId }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          await apiApi.deleteHiringStageEmailTemplate({ id: emailTemplateId });
          return { data: undefined };
        } catch (error) {
          showErrorToast("Failed to delete hiring stage email template. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: HIRING_STAGE_EMAIL_TEMPLATE, id: args.emailTemplateId } as const];
      },
    }),
    updateAllHiringStageEmailTemplatesState: build.mutation<
      HiringStageEmailTemplate[],
      {
        jobId: string;
        state: string;
      }
    >({
      queryFn: async ({ jobId, state }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.setAllEmailTemplateState({ data: { job: jobId, state } });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update email templates. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: HIRING_STAGE_EMAIL_TEMPLATE, id: `${LIST_TAG}${args.jobId}` } as const];
      },
    }),
    sendTestEmail: build.mutation<void, HiringStageEmailTemplate>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});

          await apiApi.hiringStageEmailTemplateSendTestEmail({ data: { message: args } });
          showSuccessToast("Sent test email");
          return { data: undefined };
        } catch (error) {
          showErrorToast("Failed to send test email. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useListGenericEmailResponseTemplateQuery,
  usePartialUpdateGenericEmailResponseTemplateMutation,
  useGetHiringStageEmailTemplateQuery,
  useListHiringStageEmailTemplatesQuery,
  usePartialUpdateHiringStageEmailTemplateMutation,
  useCreateHiringStageEmailTemplateMutation,
  useDeleteHiringStageEmailTemplateMutation,
  useUpdateAllHiringStageEmailTemplatesStateMutation,
  useSendTestEmailMutation,
} = emailTemplates;
