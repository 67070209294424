/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationReview
 */
export interface ApplicationReview {
    /**
     * 
     * @type {string}
     * @memberof ApplicationReview
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationReview
     */
    readonly userName?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationReview
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationReview
     */
    readonly modified?: Date;
    /**
     * Resume pre-signed url, usually expires in 7 days.
     * @type {string}
     * @memberof ApplicationReview
     */
    resumeLink?: string | null;
    /**
     * Resume link expiration date
     * @type {Date}
     * @memberof ApplicationReview
     */
    resumeLinkExpirationDate?: Date | null;
    /**
     * Date of the application
     * @type {Date}
     * @memberof ApplicationReview
     */
    applicationDate?: Date | null;
    /**
     * Automatically extracted total years of experience
     * @type {number}
     * @memberof ApplicationReview
     */
    computedTotalYoe?: number | null;
    /**
     * Automatically extracted T1 Titles
     * @type {Array<string>}
     * @memberof ApplicationReview
     */
    computedTitlesRaw?: Array<string> | null;
    /**
     * Automatically extracted T2 Titles
     * @type {Array<string>}
     * @memberof ApplicationReview
     */
    computedTitlesT2?: Array<string> | null;
    /**
     * T1 Titles annotated by reviewer
     * @type {Array<string>}
     * @memberof ApplicationReview
     */
    annotatedTitlesRaw?: Array<string> | null;
    /**
     * T2 Titles annotated by reviewer
     * @type {Array<string>}
     * @memberof ApplicationReview
     */
    annotatedTitlesT2?: Array<string> | null;
    /**
     * Years of Experience annotated by reviewer
     * @type {number}
     * @memberof ApplicationReview
     */
    annotatedTotalYoe?: number;
    /**
     * Location annotated by reviewer
     * @type {string}
     * @memberof ApplicationReview
     */
    annotatedLocation?: string | null;
    /**
     * Automatically extracted location
     * @type {string}
     * @memberof ApplicationReview
     */
    computedLocation?: string | null;
    /**
     * Friendly title to use as label
     * @type {string}
     * @memberof ApplicationReview
     */
    title?: string | null;
    /**
     * Indicates the application was reviewed
     * @type {boolean}
     * @memberof ApplicationReview
     */
    reviewed?: boolean;
    /**
     * Indicates if the application is valid
     * @type {boolean}
     * @memberof ApplicationReview
     */
    valid?: boolean;
    /**
     * Automatically extracted job category
     * @type {string}
     * @memberof ApplicationReview
     */
    computedJobCategory?: string | null;
    /**
     * Related inbound application
     * @type {string}
     * @memberof ApplicationReview
     */
    application?: string | null;
    /**
     * User that reviewed this application
     * @type {string}
     * @memberof ApplicationReview
     */
    user?: string | null;
}

export function ApplicationReviewFromJSON(json: any): ApplicationReview {
    return ApplicationReviewFromJSONTyped(json, false);
}

export function ApplicationReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationReview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'user_name') ? undefined : json['user_name'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'resumeLink': !exists(json, 'resume_link') ? undefined : json['resume_link'],
        'resumeLinkExpirationDate': !exists(json, 'resume_link_expiration_date') ? undefined : (json['resume_link_expiration_date'] === null ? null : new Date(json['resume_link_expiration_date'])),
        'applicationDate': !exists(json, 'application_date') ? undefined : (json['application_date'] === null ? null : new Date(json['application_date'])),
        'computedTotalYoe': !exists(json, 'computed_total_yoe') ? undefined : json['computed_total_yoe'],
        'computedTitlesRaw': !exists(json, 'computed_titles_raw') ? undefined : json['computed_titles_raw'],
        'computedTitlesT2': !exists(json, 'computed_titles_t2') ? undefined : json['computed_titles_t2'],
        'annotatedTitlesRaw': !exists(json, 'annotated_titles_raw') ? undefined : json['annotated_titles_raw'],
        'annotatedTitlesT2': !exists(json, 'annotated_titles_t2') ? undefined : json['annotated_titles_t2'],
        'annotatedTotalYoe': !exists(json, 'annotated_total_yoe') ? undefined : json['annotated_total_yoe'],
        'annotatedLocation': !exists(json, 'annotated_location') ? undefined : json['annotated_location'],
        'computedLocation': !exists(json, 'computed_location') ? undefined : json['computed_location'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'reviewed': !exists(json, 'reviewed') ? undefined : json['reviewed'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'computedJobCategory': !exists(json, 'computed_job_category') ? undefined : json['computed_job_category'],
        'application': !exists(json, 'application') ? undefined : json['application'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function ApplicationReviewToJSON(value?: ApplicationReview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resume_link': value.resumeLink,
        'resume_link_expiration_date': value.resumeLinkExpirationDate === undefined ? undefined : (value.resumeLinkExpirationDate === null ? null : value.resumeLinkExpirationDate.toISOString()),
        'application_date': value.applicationDate === undefined ? undefined : (value.applicationDate === null ? null : value.applicationDate.toISOString()),
        'computed_total_yoe': value.computedTotalYoe,
        'computed_titles_raw': value.computedTitlesRaw,
        'computed_titles_t2': value.computedTitlesT2,
        'annotated_titles_raw': value.annotatedTitlesRaw,
        'annotated_titles_t2': value.annotatedTitlesT2,
        'annotated_total_yoe': value.annotatedTotalYoe,
        'annotated_location': value.annotatedLocation,
        'computed_location': value.computedLocation,
        'title': value.title,
        'reviewed': value.reviewed,
        'valid': value.valid,
        'computed_job_category': value.computedJobCategory,
        'application': value.application,
        'user': value.user,
    };
}


