import React, { useEffect } from "react";

interface ExternalRedirectProps {
  url: string;
}

export const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ url }) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return <></>;
};
