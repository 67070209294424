import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";

import CreateJobButton from "components/dover/CreateJob/CreateJobButton";
import { ButtonVariant } from "components/library/Button";
import { BodyExtraSmall } from "components/library/typography";
import { colors } from "styles/theme";

const DoNotSeeJob = (): React.ReactElement => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5}>
      <BodyExtraSmall>{"Don't see your job?"}</BodyExtraSmall>
      <CreateJobButton
        buttonProps={{ removePadding: true, variant: ButtonVariant.Ghost }}
        buttonContent={
          <Box
            sx={{
              "&:hover": {
                borderColor: colors.primary.light,
                backgroundColor: colors.primary.light,
                color: colors.primary.base,
              },
              borderRadius: "3px",
            }}
          >
            <BodyExtraSmall color={colors.primary.base}>Create one</BodyExtraSmall>
          </Box>
        }
      />
    </Stack>
  );
};

export default DoNotSeeJob;
