import { StyledEngineProvider, useTheme } from "@mui/material";
import * as Sentry from "@sentry/react";
import queryString from "query-string";
import React, { useEffect } from "react";
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import App from "App";
import { ExternalRedirect } from "App/components/ExternalRedirect";
import { AccountDetailsFormV2 } from "App/components/Onboarding/AccountDetailsFormV2";
import { AppRootProvider } from "App/context";
import MainLayout from "App/Layout";
import { DoverRouteWrapper } from "App/routing/DoverRouteWrapper";
import ErrorFallback from "App/routing/ErrorFallback";
import { useIsOnPublicRoute } from "App/routing/hooks";
import InitialRoute from "App/routing/InitialRoute";
import { APP_ROUTE_PATHS, CANDIDATE_INTERVIEWS_PATH, JOB_PATH, FALLBACK_ROUTE } from "App/routing/route-path-constants";
import {
  AddCandidateDrawerTabPaths,
  BillingSessionPathEnum,
  IndividualRouteConfig,
  IndivididualRouteConfigMetaData,
} from "App/routing/types";
import TopLevelModalManager from "components/dover/top-level-modal-manager/TopLevelModalManager";
import { OnboardingRecruitingPartnersSplash } from "components/onboarding/OnboardingRecruitingPartnersSplash";
import { ScheduleOnboarding } from "components/onboarding/ScheduleOnboarding";
import { Role } from "components/RBAC";
import GlobalOverlays from "GlobalOverlays/GlobalOverlays";
import { FeatureFlag } from "hooks/useFeatureFlag";
import { theme } from "styles/theme";
import CampaignsAdminView from "views/admin/Campaigns";
import CampaignView from "views/admin/CampaignView";
import ImpersonatorPage from "views/admin/Impersonator";
import JobEmailTemplates from "views/admin/job/HiringStageEmailTemplates";
import RecentInterviews from "views/admin/RecentInterviews";
import JobAgenciesPage from "views/agencies";
import AgencyForm from "views/AgencyForm";
import ApplicationsReviewer from "views/ApplicationsReviewer";
import BeginCheckoutSessionRedirect from "views/billing/BeginCheckoutSessionRedirect";
import CheckoutSessionSuccess from "views/billing/CheckoutSessionSuccess";
import ApplicationReview from "views/candidates/ApplicationReview";
import { ApplicationReviewRedirect } from "views/candidates/ApplicationReview/ApplicationReviewRedirect";
import CandidatesReview from "views/candidates/CandidatesReview";
import CandidateTable from "views/candidates/CandidateTable";
import { CandidateViewRedirect } from "views/candidates/CandidateTable/CandidateDetailRedirect";
import { DoverTalentNetworkWrapper } from "views/candidates/DoverTalentNetwork";
import CompanySetup from "views/CompanySetup";
import ApiKey from "views/CompanySetup/components/ApiKey";
import AtsSettings from "views/CompanySetup/components/AtsSettings";
import BasicInfo from "views/CompanySetup/components/BasicInfo";
import CompanyBio from "views/CompanySetup/components/CompanyBio";
import EmailConnectionCard from "views/CompanySetup/components/EmailConnectionCard";
import { EmailTemplatesTable } from "views/CompanySetup/components/EmailTemplates";
import FeedbackTemplates from "views/CompanySetup/components/FeedbackTemplates";
import GmailAuth from "views/CompanySetup/components/GmailAuth";
import InterviewPreferences from "views/CompanySetup/components/InterviewPreferences";
import Notifications from "views/CompanySetup/components/Notifications";
import { UsersSection } from "views/CompanySetup/components/Users";
import ContactsPortal from "views/Contacts";
import ApplicantSortingSetupFlow from "views/create-job/ApplicantSortingSetupFlow";
import AtsSetupFlow from "views/create-job/AtsSetupFlow";
import CreateJobPage from "views/create-job/CreateJob";
import ExtensionJobCreationFlow from "views/create-job/ExtensionJobCreationFlow";
import { EditCareersPage } from "views/EditCareersPage";
import GetHelp from "views/GetHelp";
import HiringPlan from "views/HiringPlan";
import Homepage from "views/Homepage";
import InboundView from "views/InboundApplication";
import AICompliancePage from "views/inboundExternal/AICompliancePage";
import CareersPage from "views/inboundExternal/CareersPage";
import InboundApplicationPortal from "views/inboundExternal/InboundApplication";
import CandidateInterview from "views/interview/CandidateInterview";
import InterviewScheduler from "views/interview/InterviewScheduler";
import DoverInterviewerPage from "views/Interviewer";
import JobView from "views/job";
import JobBoardsPage from "views/job/JobBoards";
import CampaignVariants from "views/job/JobSetup/steps/CampaignVariants";
import { JobPosting } from "views/job/JobSetup/steps/JobPosting";
import Overview from "views/job/JobSetup/steps/Overview";
import { InterviewPlanData } from "views/job/JobSetup/steps/Scheduling";
import NotFound from "views/NotFound";
import SourcingCopilot from "views/RecruitingCopilot";
import RecruitingPartnersPage from "views/RecruitingPartners";
import ReferralsForm from "views/ReferralForm";
import ReviewReferralsWrapper from "views/Referrals/ReviewReferrals";
import UploadConnections from "views/Referrals/UploadConnections";
import JobReferralsPage from "views/referralsV2";
import JobReport from "views/Reporting/JobReport";
import MetabaseReporting from "views/Reporting/MetabaseReporting";
import JobSourcingPage from "views/sourcing/JobSourcingPage";
import Unauthorized from "views/Unauthorized";

const AppRoot = (): React.ReactElement => {
  const muiTheme = useTheme();

  const isOnPublicRoute = useIsOnPublicRoute();
  // only show statuspage banner for pages which require auth
  const shouldShowStatusPageBanner = !isOnPublicRoute;

  useEffect(() => {
    // inject statuspage.io banner script
    if (!shouldShowStatusPageBanner) {
      return;
    }
    const script = document.createElement("script");

    script.src = "https://9ns8rs9sdd6r.statuspage.io/embed/script.js";
    script.async = true;

    script.onerror = (): void => {
      console.log("Failed to load the statuspage.io script.");
    };

    document.body.appendChild(script);
    return (): void => {
      document.body.removeChild(script);
    };
  }, [shouldShowStatusPageBanner]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={{ ...theme, breakpoints: muiTheme.breakpoints, mode: "light" }}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
          }}
        >
          <AppRootProvider>
            <ToastContainer />
            <GlobalOverlays />
            <TopLevelModalManager>
              <App />
            </TopLevelModalManager>
          </AppRootProvider>
        </QueryParamProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

// Route definitions
export const allRoutesConfig: IndividualRouteConfig[] = [
  {
    path: "",
    element: <AppRoot />,
    isTopLevel: true,
    isMetaRoute: true,
    authedRouteMetaData: {
      requiresAuth: false, // child routes may override this
    },
    children: [
      {
        path: "/",
        element: <MainLayout />,
        authedRouteMetaData: {
          requiresAuth: true,
        },
        children: [
          {
            path: "/",
            element: <InitialRoute />,
            authedRouteMetaData: {
              requiresAuth: true,
            },
            children: [
              {
                path: "/",
                element: <Navigate to={APP_ROUTE_PATHS.home()} replace />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: APP_ROUTE_PATHS.home(),
                element: <Homepage />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: APP_ROUTE_PATHS.sourcingCopilot(),
                element: <SourcingCopilot />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: APP_ROUTE_PATHS.recruitingPartners(),
                element: <RecruitingPartnersPage />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: APP_ROUTE_PATHS.doverInterviewer(),
                element: <DoverInterviewerPage />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: JOB_PATH,
                element: <JobView />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
                children: [
                  {
                    path: "*",
                    element: <Overview />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "overview",
                    element: <Overview />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "job-posting",
                    element: <JobPosting />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "job-boards",
                    element: <JobBoardsPage />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "candidates",
                    element: <CandidateTable />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "candidates/:candidateId",
                    element: <CandidateTable />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "review",
                    element: <CandidatesReview />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "review/:candidateId",
                    element: <CandidateTable />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "application-review", // Maintain in case links still exists (e.g. we were sending this to slack)
                    element: <ApplicationReview />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "application-review-v2",
                    element: <ApplicationReview />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "dover-talent-network",
                    element: <DoverTalentNetworkWrapper />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "outreach",
                    element: <CampaignVariants />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "interview-plan",
                    element: <InterviewPlanData />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "reporting",
                    element: <JobReport />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "sourcing",
                    element: <JobSourcingPage />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "recruiting-partners-tab",
                    element: <RecruitingPartnersPage />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "referrals",
                    element: <JobReferralsPage />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "agencies",
                    element: <JobAgenciesPage />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
              {
                path: APP_ROUTE_PATHS.candidates.candidatesTable(),
                element: <CandidateViewRedirect />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: `${APP_ROUTE_PATHS.candidates.candidatesTable()}/:candidateId`,
                element: <CandidateViewRedirect />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/candidate/:candidateId",
                element: <CandidateViewRedirect />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "review",
                element: <CandidatesReview />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "review/:candidateId",
                element: <CandidatesReview />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/inbound-application/:inboundApplicationId",
                element: <InboundView />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: `${CANDIDATE_INTERVIEWS_PATH}/:candidateId/:interviewRubricResponseId`,
                element: <CandidateInterview />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  allowedRoles: [Role.ADMIN, Role.INTERVIEWER, Role.CLIENT_ADMIN, Role.TEAM_MEMBER],
                },
              },
              {
                path: "/interview-scheduler",
                element: <InterviewScheduler />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  allowedRoles: [Role.ADMIN, Role.INTERVIEWER],
                },
              },
              {
                path: "/referrals/upload-connections",
                element: <UploadConnections />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/referrals/review",
                element: <ReviewReferralsWrapper />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/referrals/review/:triageTabPath",
                element: <ReviewReferralsWrapper />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/reports/:jobId?",
                element: <JobReport />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/job-report/:jobId?", //old route backward compatibility
                element: <JobReport />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: APP_ROUTE_PATHS.reports.visualExplorer(),
                element: <MetabaseReporting />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/setup/company",
                element: <CompanySetup />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
                children: [
                  {
                    path: "basic-info", // Legacy link
                    element: <Navigate to="/settings/general" />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "credentials", // Legacy link
                    element: <Navigate to="../integrations" />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "biography", // Legacy link
                    element: <Navigate to="../dover-interviewer" />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "integrations",
                    element: (
                      <ExternalRedirect url="https://help.dover.com/en/articles/6649755-connect-yc-work-at-a-startup-to-dover" />
                    ),
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                ],
              },
              {
                path: "/settings/company",
                element: <CompanySetup />,
                children: [
                  {
                    path: "general",
                    element: <BasicInfo />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "members",
                    element: <UsersSection />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "users", // Legacy link
                    element: <Navigate to="../members" />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "email-templates",
                    element: <EmailTemplatesTable />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "feedback-forms",
                    element: <FeedbackTemplates />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "ats-settings",
                    element: <AtsSettings />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "api-key",
                    element: <ApiKey />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "dover-interviewer",
                    element: <CompanyBio />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                ],
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/settings/account",
                element: <CompanySetup />,
                children: [
                  {
                    path: "interview-scheduling",
                    element: <InterviewPreferences />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "email-connection",
                    element: <EmailConnectionCard />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                  {
                    path: "notifications",
                    element: <Notifications />,
                    authedRouteMetaData: {
                      requiresAuth: true,
                    },
                  },
                ],
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/jobs",
                element: <Navigate replace to={APP_ROUTE_PATHS.home()} />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/create-job",
                element: <CreateJobPage />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/create-job/extension/:step?",
                element: <ExtensionJobCreationFlow />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/contacts",
                element: <ContactsPortal />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  requiresSelectedClientOverride: true,
                },
              },
              {
                path: "/settings/interview-preferences",
                element: <Navigate replace to="/settings/account/interview-scheduling" />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/settings/gmail-auth",
                element: <GmailAuth />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/settings/:section?",
                element: <Navigate replace to="/settings/account/interview-scheduling" />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/edit-careers-page",
                element: <EditCareersPage />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/get-help",
                element: <GetHelp />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "application-review", // Maintain in case links still exists (e.g. we were sending this to slack)
                element: <ApplicationReviewRedirect />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/application-review-v2", // Maintain in case links still exists (e.g. we were sending this to slack)
                element: <ApplicationReviewRedirect />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/add-candidates",
                element: <Navigate replace to={APP_ROUTE_PATHS.addCandidate(AddCandidateDrawerTabPaths.Outreach)} />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/source-candidates",
                element: <Navigate replace to={APP_ROUTE_PATHS.addCandidate(AddCandidateDrawerTabPaths.Outreach)} />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/add-candidate",
                element: <Navigate replace to={APP_ROUTE_PATHS.addCandidate(AddCandidateDrawerTabPaths.Pipeline)} />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "add-candidate-v2",
                element: <Navigate replace to={APP_ROUTE_PATHS.addCandidate(AddCandidateDrawerTabPaths.Pipeline)} />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/billing/session/begin/:billingSessionType",
                element: <BeginCheckoutSessionRedirect />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/billing/session/begin",
                element: <Navigate replace to={`/billing/session/begin/${BillingSessionPathEnum.JobBoards}`} />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/billing/session/success/:billingSessionType",
                element: <CheckoutSessionSuccess />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/admin/impersonator",
                element: <ImpersonatorPage />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  requiresSelectedClientOverride: false,
                },
              },
              {
                path: "/admin/job/:jobId/hiring-stage-email-templates",
                element: <JobEmailTemplates />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  requiresSelectedClientOverride: true,
                  allowedRoles: [Role.ADMIN],
                },
              },
              {
                path: "/admin/job/:jobId/hiring-stage-email-templates/:emailTemplateId",
                element: <JobEmailTemplates />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  requiresSelectedClientOverride: true,
                  allowedRoles: [Role.ADMIN],
                },
              },
              {
                path: "/admin/campaign/:campaignId",
                element: <CampaignView />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  allowedRoles: [Role.ADMIN],
                },
              },
              {
                path: "/admin/campaigns",
                element: <CampaignsAdminView />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  allowedRoles: [Role.ADMIN],
                },
              },
              {
                path: "/admin/interviews",
                element: <RecentInterviews />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  allowedRoles: [Role.ADMIN, Role.INTERVIEWER],
                },
              },
              {
                path: "/hiring-plan",
                element: <HiringPlan />,
                authedRouteMetaData: {
                  requiresAuth: true,
                },
              },
              {
                path: "/applications-reviewer",
                element: <ApplicationsReviewer />,
                authedRouteMetaData: {
                  requiresAuth: true,
                  requiredFeatureFlag: FeatureFlag.ApplicationReviewerAccess,
                },
              },
            ],
          },
        ],
      },
      // Fallback if no path is found
      {
        path: FALLBACK_ROUTE,
        element: <NotFound />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
      {
        path: APP_ROUTE_PATHS.unauthorized(),
        element: <Unauthorized />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
      {
        path: APP_ROUTE_PATHS.onboarding.applicantSorting(),
        element: <ApplicantSortingSetupFlow />,
        authedRouteMetaData: {
          requiresAuth: true,
        },
      },
      {
        path: APP_ROUTE_PATHS.onboarding.atsSetup(),
        element: <AtsSetupFlow />,
        authedRouteMetaData: {
          requiresAuth: true,
        },
      },
      {
        path: APP_ROUTE_PATHS.onboarding.scheduleOnboarding(),
        element: <ScheduleOnboarding />,
        authedRouteMetaData: {
          requiresAuth: true,
        },
      },
      {
        path: APP_ROUTE_PATHS.onboarding.recruitingPartners(),
        element: <OnboardingRecruitingPartnersSplash />,
        authedRouteMetaData: {
          requiresAuth: true,
        },
      },

      {
        path: APP_ROUTE_PATHS.onboarding.accountDetails(),
        element: <AccountDetailsFormV2 />,
        authedRouteMetaData: {
          requiresAuth: true,
        },
      },
      {
        path: "/apply/:clientName/:searchOrJobId",
        element: <InboundApplicationPortal />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
      {
        path: "/submit-referral/:clientId",
        element: <ReferralsForm />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
      {
        path: "/agency-portal/:referrerId",
        element: <AgencyForm />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
      {
        // note: i dont think clientName is actually used, rather it can be anything and link will still work
        // note 2: IMPORTANT. if you change the name of this path, make sure to also update it in portal/views/__init__.py
        path: ":clientName/careers/:clientId",
        element: <CareersPage />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
      {
        // note: IMPORTANT. if you change the name of this path, make sure to also update it in portal/views/__init__.py
        path: "/jobs/:clientSlug",
        element: <CareersPage />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
      {
        path: ":clientName/careers/:clientId/audit",
        element: <AICompliancePage />,
        authedRouteMetaData: {
          requiresAuth: false,
        },
      },
    ],
  },
];

const mapChildRoutes = (routes?: IndividualRouteConfig[]): RouteObject[] => {
  if (!routes) {
    return [];
  }

  return routes.map(route => {
    let routeElement = route.element;
    if (!route.isMetaRoute) {
      // If the route is not a meta route, wrap it in the DoverRouteWrapper to collect analytics and make sure we require a selected client if indicated
      routeElement = (
        <DoverRouteWrapper authedRouteMetaData={route.authedRouteMetaData} path={route.path}>
          {routeElement}
        </DoverRouteWrapper>
      );
    }

    return individualRouteConfigToRouteObject(route, routeElement);
  });
};

/**
 * Converter helper to give us consistent conversion logic when going from IndividualRouteConfig -> RouteObject
 * @param route
 * @param routeElement
 * @param errorElement
 * @returns
 */
const individualRouteConfigToRouteObject = (
  route: IndividualRouteConfig,
  routeElement: React.ReactElement,
  errorElement?: React.ReactElement
): RouteObject => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { path, children, element, ...restOfRoute } = route;
  return {
    path: route.path,
    element: routeElement,
    errorElement: errorElement,
    children: route.children ? mapChildRoutes(route.children) : undefined,
    // see https://reactrouter.com/en/main/hooks/use-matches for more info on the handle property and how to access it
    handle: { ...(restOfRoute as IndivididualRouteConfigMetaData) },
  };
};

const wrappedCreateBrowserRouter =
  // @ts-ignore
  process.env.NODE_ENV === "production" ? Sentry.wrapCreateBrowserRouter(createBrowserRouter) : createBrowserRouter;

const AllRoutes = (): React.ReactElement => {
  const topLevelRoutes = allRoutesConfig.filter(route => route.isTopLevel);

  const router = wrappedCreateBrowserRouter(
    topLevelRoutes.map(route => {
      return individualRouteConfigToRouteObject(route, route.element, <ErrorFallback />);
    })
  );

  return <RouterProvider router={router} />;
};

export default AllRoutes;
