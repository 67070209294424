/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from "react";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { NextAction, PipelineCandidate } from "services/openapi";
import { getNextActionColors, getNextActionTitle } from "views/CandidateDetail/components/next-action/utils/helpers";

interface Props {
  nextAction: NextAction;
  candidate: PipelineCandidate;
  condensed?: boolean;
  maxWidth?: string;
}

export const NextUpCell = ({ nextAction, candidate, condensed, maxWidth }: Props): React.ReactElement => {
  const colors = getNextActionColors(nextAction, candidate);
  const title = useMemo(() => {
    return getNextActionTitle(nextAction, candidate);
  }, [nextAction, candidate]);

  if (!title) {
    return <></>;
  }
  return (
    <StyledNextActionBox colors={colors} condensed={condensed || false} maxWidth={maxWidth}>
      <Overline color={colors.headerTextColor} inline ellipsis>
        {title}
      </Overline>
    </StyledNextActionBox>
  );
};

interface NextActionBoxProps {
  colors: { backgroundColor: string; borderColor: string; headerTextColor: string };
  condensed: boolean;
  maxWidth?: string;
}

const StyledNextActionBox = styled.div<NextActionBoxProps>`
  border-radius: 4px;
  padding-top: ${(props): any => (props.condensed ? "2px" : "10px")};
  padding-bottom: ${(props): any => (props.condensed ? "5px" : "10px")};
  padding-left: ${(props): any => (props.condensed ? "8px" : "10px")};
  padding-right: ${(props): any => (props.condensed ? "8px" : "10px")};
  background: ${({ colors }): string => colors.backgroundColor};
  color: ${({ colors }): string => colors.headerTextColor};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({ maxWidth }): string | undefined => maxWidth};
  width: fit-content;
`;
