import LoadingOverlay from "react-loading-overlay";
import styled from "styled-components";

import { theme } from "styles/theme";

LoadingOverlay.propTypes = undefined;

export const CustomLoadingOverlay = styled(LoadingOverlay)`
  width: ${(props: any): string => props.width || "100%"};
  min-height: ${(props: any): string => props.minHeight || "50vh"};
  height: ${(props: any): string => props.height || "100%"};
  position: relative;

  &.absolute {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &.fullScreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1000;

    .custom_overlay {
      background-color: ${(props: any): string => props.backgroundColor || "rgba(244, 246, 248, 0.9)"};
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .custom_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    z-index: 1000;
  }

  &.overlay {
    .custom_overlay {
      background-color: ${(props: any): string => props.backgroundColor || "rgba(244, 246, 248, 0.9)"};
    }
  }

  .custom_content {
    text-align: center;
    color: ${theme.colors.black};
  }

  .custom_spinner {
    width: ${(props: any): string => props.spinnerSize || "60px"};
    height: ${(props: any): string => props.spinnerSize || "60px"};
    position: relative;
    margin: 0;

    svg {
      filter: ${(props: any): string => props.filter || theme.filters.filterDarkGreen};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      circle {
        stroke-width: 3px;
      }
    }
  }
`;
