import { JobFeatureFeatureNameEnum } from "services/openapi";

interface JobFeatureGatingConstants {
  title: string;
  bannerLabel: string;
  icon?: string;
  description?: string;
}

export const JOB_FEATURE_GATING_CONFIG: Readonly<{ [key in JobFeatureFeatureNameEnum]?: JobFeatureGatingConstants }> = {
  [JobFeatureFeatureNameEnum.DoverInterviewer]: {
    title: "Dover Interviewer",
    bannerLabel:
      "Additional setup required to set up Dover Interviewer. Please navigate to the overview page to complete setup.",
  },
  // [JobFeatureFeatureNameEnum.E2EScheduling]: {
  //   // Not implemented yet
  // },
};
