import { Card, Stack } from "@mui/material";
import React from "react";

import GreenCheckSVG from "assets/icons/check-green-filled.svg";
import { ONLINE_ACCOUNT_PROVIDER_CONFIGS } from "components/dover/OnlineAccounts/constants";
import { BodySmall } from "components/library/typography";
import { StyledSVG } from "views/CompanySetup/components/GmailAuth/styles";

export const ConnectedCard: React.FC<React.PropsWithChildren<any>> = ({ isCalendar }: { isCalendar?: boolean }) => {
  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems={"center"} p={2}>
        <Stack direction="row" spacing={0.5} alignItems={"center"}>
          <StyledSVG src={GreenCheckSVG} />
          <BodySmall>{isCalendar ? "Calendar" : "Email"} Connected</BodySmall>
        </Stack>

        <Stack spacing={1} direction="row">
          {ONLINE_ACCOUNT_PROVIDER_CONFIGS.map(oap =>
            isCalendar ? oap.calendarConnectButton : oap.emailConnectButton
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
