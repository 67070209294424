import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as ChevronRight } from "@doverhq/dover-ui/icons/chevron-right.svg";
import { Skeleton, Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Card } from "components/library/Card";
import { Body, BodySmall } from "components/library/typography";
import { useHasRole, Role } from "components/RBAC";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  useGetFormattedPreviouslyAddedInboundSourceRows,
  useGetSourceDisplaySectionData,
} from "services/doverapi/endpoints/job-source-settings/customHooks";
import { SourceStatus } from "services/doverapi/endpoints/job-source-settings/types";
import { colors } from "styles/theme";
import { SourceCard } from "views/job/JobBoards/components/SourceCard";
import { PUBLISHED_SECTION_ID } from "views/job/JobBoards/constants";
import { CardProps } from "views/job/JobBoards/types";

const Rotate = styled.div`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.1s ease;
  }
  &.rotate {
    svg {
      transform: rotate(90deg);
    }
  }
`;

const PublishedCard: React.FC<React.PropsWithChildren<CardProps>> = ({ openModalWithSource }) => {
  const jobId = useJobIdFromUrl();
  const [showInactiveSources, setShowInactiveSources] = React.useState<boolean>(false);

  const { isFetching: isFetchingSources } = useGetSourceDisplaySectionData({ jobId, includeAdminPreview: false });
  const isAdmin = useHasRole(Role.ADMIN);

  const formattedActiveInboundSourceRows = useGetFormattedPreviouslyAddedInboundSourceRows({
    jobId,
    includeAdminPreview: isAdmin,
    includeYcWaas: true,
  });

  const inactiveSourceRows = formattedActiveInboundSourceRows.filter(
    source => source.enrichedSourceDetails.status === SourceStatus.Inactive
  );

  const allOtherSourceRows = formattedActiveInboundSourceRows.filter(source => {
    return source.enrichedSourceDetails.status !== SourceStatus.Inactive;
  });

  const toggleShowInactiveList = (): void => setShowInactiveSources(!showInactiveSources);
  const numActiveSources = allOtherSourceRows.length;

  return (
    <Card id={PUBLISHED_SECTION_ID}>
      <Stack spacing={1}>
        <Body weight="600">Published</Body>
        {isFetchingSources && <Skeleton height="80px" width="100%" />}
        {numActiveSources === 0 && !isFetchingSources && (
          <BodySmall centered color={colors.grayscale.gray600}>
            Promote your job on the job boards listed below.
          </BodySmall>
        )}
        {numActiveSources > 0 ? (
          <Card padding="0px">
            {allOtherSourceRows.map(source => {
              return (
                <SourceCard
                  key={source.id}
                  source={source}
                  onClick={(): void => {
                    openModalWithSource(source.enrichedSourceDetails);
                  }}
                />
              );
            })}
          </Card>
        ) : null}
        {inactiveSourceRows.length > 0 ? (
          <>
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              onClick={toggleShowInactiveList}
              sx={{ cursor: "pointer" }}
            >
              <Rotate className={showInactiveSources ? "rotate" : ""}>
                <Icon Icon={ChevronRight} size={16} />
              </Rotate>
              <BodySmall>Inactive</BodySmall>
            </Stack>
            {showInactiveSources && (
              <Card padding="0px">
                {inactiveSourceRows.map(source => {
                  return (
                    <SourceCard
                      key={source.id}
                      source={source}
                      onClick={(): void => openModalWithSource(source.enrichedSourceDetails)}
                    />
                  );
                })}
              </Card>
            )}
          </>
        ) : null}
      </Stack>
    </Card>
  );
};

export default PublishedCard;
