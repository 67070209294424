import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useGetJobSetupStepsQuery } from "services/doverapi/endpoints/job/endpoints";
import { JobFeatureUIState } from "services/doverapi/endpoints/job/types";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import {
  JobFeatureFeatureNameEnum,
  JobFeatureStateEnum,
  JobSetupStep,
  JobSetupStepStateEnum,
  JobSetupStepStepNameEnum,
} from "services/openapi";

interface UseGetJobFeatureUIStateArgs {
  featureName: JobFeatureFeatureNameEnum;
  jobId?: string;
  allowAutoEnablement: boolean;
}

const PENDING_CALL_STATE_NAMES = [JobSetupStepStepNameEnum.SourcingAutopilotPendingCallState];

function useGetIncompleteOrHiddenSetupStepsForFeature(
  jobId: string | undefined,
  featureName: JobFeatureFeatureNameEnum
): JobSetupStep[] | undefined {
  const { data: jobSetupSteps } = useGetJobSetupStepsQuery(jobId ?? skipToken);
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  return useMemo(() => {
    if (!jobSetupSteps || !jobFeatures) {
      return undefined;
    }

    const feature = jobFeatures.features.find(jobFeature => jobFeature.featureName === featureName);
    if (!feature) {
      return undefined;
    }

    return jobSetupSteps.setupSteps.filter(
      step =>
        feature.dependentJobSetupSteps.includes(step.stepName) &&
        [JobSetupStepStateEnum.Pending, JobSetupStepStateEnum.StartedPendingClientAction].includes(step.state)
    );
  }, [featureName, jobFeatures, jobSetupSteps]);
}

export function useGetJobFeatureUIState({
  featureName,
  jobId,
}: UseGetJobFeatureUIStateArgs): JobFeatureUIState | undefined {
  const incompleteSetupStepsForFeature = useGetIncompleteOrHiddenSetupStepsForFeature(jobId, featureName);
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  return useMemo(() => {
    if (!jobFeatures || !incompleteSetupStepsForFeature) {
      return undefined;
    }

    const feature = jobFeatures.features.find(jobFeature => jobFeature.featureName === featureName);
    if (!feature) {
      return undefined;
    }

    // if one of the job feature UI state's is pending call, return this State
    const pendingCall = incompleteSetupStepsForFeature.find(step => PENDING_CALL_STATE_NAMES.includes(step.stepName));
    if (pendingCall) {
      return JobFeatureUIState.PendingCall;
    }

    if (feature.state === JobFeatureStateEnum.Enabled) {
      return incompleteSetupStepsForFeature.length === 0 ? JobFeatureUIState.Active : JobFeatureUIState.NeedsSetup;
    }

    return JobFeatureUIState.Inactive;
  }, [featureName, incompleteSetupStepsForFeature, jobFeatures]);
}
