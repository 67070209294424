import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { useEffect } from "react";
import { useLocalStorage } from "react-use";

import { DoverInterviewerCard } from "components/dover/DoverInterviewerCard";
import JobFeatureToggleHandler from "components/dover/feature-toggle-handler/JobFeatureToggleHandler";
import { BodySmall } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { CandidateFilterStatusEnum } from "services/openapi";
import { screenSizesNumbers } from "styles/theme";
import { useParams } from "views/candidates/hooks";
import AgenciesOverview from "views/job/JobSetup/steps/Overview/components/AgenciesOverview";
import ApplicantsOverview from "views/job/JobSetup/steps/Overview/components/ApplicantsOverview";
import CandidatesOverview from "views/job/JobSetup/steps/Overview/components/CandidatesOverview";
import { EmbeddedRecruiterCard } from "views/job/JobSetup/steps/Overview/components/EmbeddedRecruiterCard";
import { JobSetupGuide } from "views/job/JobSetup/steps/Overview/components/JobSetupGuide";
import ReferralsOverview from "views/job/JobSetup/steps/Overview/components/ReferralsOverview";
import { InterviewStatsTable } from "views/Reporting/components/InterviewStatsTable";
import { DateOption } from "views/Reporting/constants";
import { getFilterDates } from "views/Reporting/utils";

const Overview = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();
  const { data: job } = useGetJobQuery(jobId ?? skipToken);

  // Responsive design
  const theme = useTheme();
  const smallishScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tablet));

  // Jotai state
  const freeCustomer = useIsBasePlanCustomer();

  // Load in status query param for candidate counts
  const [{ status }, setParams] = useParams();
  const [jobSetupGuideDismissed] = useLocalStorage(`setupGuideDismissed${jobId}`, false);
  const [dateRangeOption, setDateRangeOption] = React.useState<DateOption>(DateOption.AllTime);

  // Set params for initial load
  useEffect(() => {
    if (!status.length) {
      setParams({ status: [CandidateFilterStatusEnum.Active] });
    }

    // We intentionally only want to run this on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only show solutions cards for contract customers and have feature flag
  const isDisplayLegacySolutionsEnabled = useFeatureFlag(FeatureFlag.DisplayLegacySolutions);
  const showSolutionsCards = !freeCustomer && isDisplayLegacySolutionsEnabled;

  return (
    <JobFeatureToggleHandler>
      <PageHelmet title={job?.title ? `${job.title} - Overview` : "Overview"} />
      <Stack width="100%" direction="row" justifyContent={"center"}>
        <Stack spacing={2} width="100%" maxWidth={"800px"}>
          {jobId && !jobSetupGuideDismissed && <JobSetupGuide jobId={jobId} />}
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} direction={smallishScreen ? "column" : "row"}>
              <Grid item xs={7}>
                <CandidatesOverview />
              </Grid>
              <Grid item xs={5}>
                <ApplicantsOverview />
              </Grid>
              <Grid item xs={3.5}>
                <ReferralsOverview />
              </Grid>
              <Grid item xs={3.5}>
                <AgenciesOverview />
              </Grid>
            </Grid>
          </Box>
          {showSolutionsCards && (
            <>
              <BodySmall weight="700">Solutions</BodySmall>
              <EmbeddedRecruiterCard />
              <DoverInterviewerCard />
            </>
          )}
          {jobId && (
            <InterviewStatsTable
              job={{
                id: jobId,
                title: "",
                client: "",
                isSample: false,
              }}
              dateRange={getFilterDates(dateRangeOption)}
              dateRangeOption={dateRangeOption}
              onDateRangeChange={setDateRangeOption}
            />
          )}
        </Stack>
      </Stack>
    </JobFeatureToggleHandler>
  );
};

export default Overview;
