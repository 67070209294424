import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import { Box, CircularProgress, Divider } from "@mui/material";
import React from "react";

import { BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { usePartialUpdateJobReferrerMutation } from "views/referralsV2/endpoints";

interface DeactivateAgencyConfirmModalProps {
  jobReferrerId: string;
  jobId: string;
  agencyName: string;
  recruiterName: string;
}

export const DeactivateAgencyConfirmModal: React.FC<React.PropsWithChildren<
  GlobalModalProps & DeactivateAgencyConfirmModalProps
>> = ({ isOpen, close, jobId, agencyName, jobReferrerId, recruiterName }) => {
  const [updateJobAgencyReferrer, { isLoading }] = usePartialUpdateJobReferrerMutation();

  const deactivateBtn = (
    <Button
      loading={isLoading}
      sx={{ "&.MuiButton-colorPrimary": { backgroundColor: "#E06363" } }}
      onClick={(): void => {
        updateJobAgencyReferrer({
          id: jobReferrerId,
          data: {
            disabled: true,
          },
          jobId,
        })
          .then(() => close())
          .catch(e => {
            console.error(e);
          });
      }}
      loadingIndicator={<CircularProgress color="inherit" size="18px" />}
    >
      Deactivate
    </Button>
  );

  const actionSection = (
    <Stack spacing={1} p={0} sx={{ width: "100%" }}>
      <Divider sx={{ width: "100%" }} />
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", p: 1 }}>{deactivateBtn}</Box>
    </Stack>
  );

  return (
    <CustomModal
      omitDividers
      onClose={close}
      open={isOpen}
      title={`Deactivate ${agencyName} ${recruiterName}`}
      dialogActions={actionSection}
      dialogActionsStyles={{ p: 0, width: "100%" }}
      maxWidth="sm"
    >
      <BodySmall>
        {agencyName} {recruiterName.split(" ")[0]} will no longer be able to submit candidates for this role.
      </BodySmall>
    </CustomModal>
  );
};

export const deactivateAgencyConfirmModalAtom = modalAtom(DeactivateAgencyConfirmModal);
