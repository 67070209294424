import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, FormControl, FormHelperText, Box } from "@mui/material";
import { TextFieldProps } from "@mui/material";
import { TextField as MuiTextField } from "@mui/material";
import React, { ReactElement, useMemo, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { styled } from "styled-components";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Subtitle1, Subtitle2 } from "components/library/typography";
import { useListTitlesQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { ApplicationReview } from "services/openapi";
import { colors } from "styles/theme";
import { usePatchApplicationReviewMutation } from "views/ApplicationsReviewer/endpoints";
import MultipleAutocomplete from "views/ApplicationsReviewer/MultipleAutocomplete";
import ReviewItemTitle from "views/ApplicationsReviewer/ReviewItemTitle";
import { ApplicationReviewSchemaType, ApplicationReviewSchema } from "views/ApplicationsReviewer/types";

const TextField = styled((props: TextFieldProps) => <MuiTextField {...props} />)`
  & .MuiInputBase-root {
    height: 32px;
    maxheight: 32px;
    font-size: 16px;
    padding: 4px;
  }

  & .MuiOutlinedInput-root {
    border-radius: 3px;
  }

  & .MuiInputLabel-root {
    font-size: 14px;
  }
`;

interface ApplicationReviewFormProps {
  data?: ApplicationReview;
  afterSubmitCb?: (id?: string) => void;
}

const ApplicationReviewForm = ({ data, afterSubmitCb }: ApplicationReviewFormProps): React.ReactElement => {
  const { data: allTitles } = useListTitlesQuery();
  const [patchApp, { isLoading: isSubmitting }] = usePatchApplicationReviewMutation();

  const formMethods = useForm<ApplicationReviewSchemaType>({
    resolver: zodResolver(ApplicationReviewSchema),
    defaultValues: useMemo(() => {
      return {
        title: data?.title ?? "",
        id: data?.id ?? undefined,
        annotatedTotalYoe: data?.annotatedTotalYoe,
        annotatedTitlesT2: data?.annotatedTitlesT2 ?? [],
        annotatedLocation: data?.annotatedLocation ?? "",
      };
    }, [data]),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = formMethods;

  useEffect(() => {
    if (data)
      reset({
        title: data?.title ?? "",
        id: data?.id ?? undefined,
        annotatedTotalYoe: data?.annotatedTotalYoe,
        annotatedTitlesT2: data?.annotatedTitlesT2 ?? [],
        annotatedLocation: data?.annotatedLocation ?? "",
      });
  }, [data, reset]);

  const onSubmit = async (data: ApplicationReview): Promise<void> => {
    if (data.id) {
      await patchApp({
        id: data.id,
        data: {
          ...(data.annotatedTitlesT2 ? { annotatedTitlesT2: data.annotatedTitlesT2 } : {}),
          ...(data.annotatedTotalYoe ? { annotatedTotalYoe: data.annotatedTotalYoe } : {}),
          ...(data.annotatedLocation ? { annotatedLocation: data.annotatedLocation } : {}),
          reviewed: true,
        },
      });
      if (afterSubmitCb) afterSubmitCb(data.id);
    }
  };

  const getTitles = async (name?: string): Promise<string[]> => {
    if (!allTitles) return ["Other"];
    const filteredData = allTitles.filter((t): t is { name: string } => t.name !== undefined).map(t => t.name);
    const data = [...filteredData, "Other"];
    if (!name) return data;
    return data.filter(t => t.toLowerCase().includes(name.toLowerCase()));
  };

  if (!data) {
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ width: "100%", minHeight: "400px" }}>
        <Subtitle1>No data to display</Subtitle1>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Box p={1} sx={{ height: "48px" }}>
        <ReviewItemTitle item={data} />
      </Box>

      <FormProvider {...formMethods}>
        <Stack p={1} spacing={1}>
          <FormControl>
            <Subtitle2>Total YoE</Subtitle2>
            <Controller
              name="annotatedTotalYoe"
              control={control}
              render={({ field }): ReactElement => (
                <TextField
                  variant="outlined"
                  {...field}
                  type="number"
                  value={field.value || ""}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                    const value = evt.target.value;
                    field.onChange(value ? parseInt(value) : null);
                  }}
                  inputProps={{ autoFocus: true }}
                />
              )}
            />
            {errors.annotatedTotalYoe && <FormHelperText error>{errors.annotatedTotalYoe.message}</FormHelperText>}
          </FormControl>

          <FormControl>
            <Subtitle2>Titles</Subtitle2>
            <MultipleAutocomplete control={control} name={"annotatedTitlesT2"} getOptions={getTitles} />
          </FormControl>

          <FormControl>
            <Subtitle2>Location</Subtitle2>
            <Controller
              control={control}
              name="annotatedLocation"
              defaultValue={""}
              render={({ field }): ReactElement => (
                <TextField
                  variant="outlined"
                  {...field}
                  value={field.value || ""}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                    field.onChange(evt.target.value);
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>
      </FormProvider>
      <Box p={1}>
        <Button variant={ButtonVariant.Primary} onClick={handleSubmit(onSubmit)} loading={isSubmitting} width="100%">
          Next <ArrowRight display="inline" color={colors.white} className="svg-color" />
        </Button>
      </Box>
    </Stack>
  );
};

export default ApplicationReviewForm;
