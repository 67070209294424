import React from "react";
import styled from "styled-components";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { filters } from "styles/theme";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Loading = (): React.ReactElement => (
  <SpinnerWrapper>
    <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />
  </SpinnerWrapper>
);

export default Loading;
