import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Divider } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { FC, useMemo, useCallback } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { StringParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { useBasicResumeUploader } from "components/BasicResumeUploader";
import { InfoTip } from "components/InfoTip";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonRadio } from "components/library/ButtonRadio";
import Drawer from "components/library/Drawer";
import { TextField } from "components/library/TextField";
import { BodySmall } from "components/library/typography";
import { Body, ButtonText, Subtitle2 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { appConfig } from "config/appConfig";
import { modalAtom, useModal } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { AddCandidateCTA } from "sections/addCandidateV2/components/AddCandidateCTA";
import DrawerHeader from "sections/addCandidateV2/components/DrawerHeader";
import { AddCandidateJobSelector } from "sections/addCandidateV2/components/JobSelector";
import PipelineStageSelect from "sections/addCandidateV2/components/SelectPipelineStage";
import {
  InterviewPipelineCandidateInfoSchema,
  interviewPipelineCandidateInfoZodSchema,
} from "sections/addCandidateV2/types/candidateInfo";
import {
  useAddCandidateInitialOutreachInterviewMutation,
  useLazyCanSourceProfileQuery,
} from "services/doverapi/endpoints/candidate";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import {
  AddCandidateInterviewProcessResponse,
  DashboardJob,
  HiringPipelineStageMilestone,
  HiringPipelineStageType,
  ParseCandidateInfoResult,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";
import { useSubmitReferralMutation } from "views/ReferralForm/endpoints";

export const TWENTY_MEGA_BYTES = 20 * 1024 * 1024;

const CANDIDATE_SOURCE_CHOICES = [
  {
    internalName: "APPLIED",
    value: "APPLIED",
    label: "Applied",
  },
  {
    internalName: "SOURCED",
    value: "SOURCED",
    label: "Sourced",
  },

  {
    internalName: "CANDIDATE_REFERRED",
    value: "CANDIDATE_REFERRED",
    label: "Referral",
  },
  {
    internalName: "AGENCY",
    value: "AGENCY",
    label: "Agency",
  },
];

interface ConfirmDrawerCloseDialogProps {
  confirmClose: () => void;
}
export const ConfirmDrawerCloseDialog: FC<React.PropsWithChildren<
  GlobalModalProps & ConfirmDrawerCloseDialogProps
>> = ({ isOpen, close, confirmClose }) => {
  return (
    <CustomModal
      open={isOpen}
      title={<Subtitle2>Are you sure?</Subtitle2>}
      onClose={close}
      maxWidth="sm"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <Button
            onClick={(): void => {
              confirmClose();
              close();
            }}
            variant={ButtonVariant.Secondary}
          >
            <ButtonText>Yes, close</ButtonText>
          </Button>
          <Button onClick={close} variant={ButtonVariant.Primary}>
            <ButtonText color="white">Cancel</ButtonText>
          </Button>
        </Stack>
      }
    >
      <Body>Closing will discard all of your changes.</Body>
    </CustomModal>
  );
};

export const confirmCloseModalAtom = modalAtom(ConfirmDrawerCloseDialog);

//------------------------------------------------------------------------------
// Main Component

export const AddCandidateV2Drawer: FC<React.PropsWithChildren<GlobalModalProps>> = ({ isOpen, close }) => {
  const jobIdFromUrl = useJobIdFromUrl();
  const [hpsIdFromUrl] = useQueryParam("hpsId", StringParam);

  const { activeJobs: unsortedJobs, authedUsersActiveJobs, jobsLoading } = useGetJobsDerivedData({});

  const getDefaultJob = useCallback(
    (jobs: DashboardJob[] | undefined): string | undefined => {
      if (!jobs || jobs.length === 0) return undefined;

      // 0. If we have a job ID from URL, use that as highest priority
      if (jobIdFromUrl) return jobIdFromUrl;

      // 1. If you have any jobs you're related to, use the first one
      if (authedUsersActiveJobs && authedUsersActiveJobs.length > 0) {
        return authedUsersActiveJobs[0].id;
      }

      // 2. If there are any jobs at all, use the first one
      if (jobs.length > 0) {
        return jobs[0].id;
      }

      return undefined;
    },
    [authedUsersActiveJobs, jobIdFromUrl]
  );

  // Initialize jobId with either the URL param or the default job
  const [jobId, setJobId] = React.useState<string | undefined>(() => {
    if (jobIdFromUrl) return jobIdFromUrl;
    if (!jobsLoading && unsortedJobs) {
      return getDefaultJob(unsortedJobs);
    }
    return undefined;
  });

  // Update jobId when jobs load if we don't have one set
  React.useEffect(() => {
    if (!jobId && !jobsLoading && unsortedJobs) {
      const defaultJobId = getDefaultJob(unsortedJobs);
      if (defaultJobId) {
        setJobId(defaultJobId);
      }
    }
  }, [jobId, jobsLoading, unsortedJobs, getDefaultJob]);

  const job: DashboardJob | undefined = useMemo(() => unsortedJobs?.find(job => job.id === jobId), [
    unsortedJobs,
    jobId,
  ]);

  const [hpsId, setHpsId] = React.useState<string | undefined>(hpsIdFromUrl ?? undefined);
  const [existingCandidateId, setExistingCandidateId] = React.useState<string | undefined>(undefined);

  // Set initial call stage if no stage is selected
  if (job && !hpsId) {
    const initialCallHpsId = job?.hiringPipelineStages?.find(
      hps => hps.milestone === HiringPipelineStageMilestone.INITIAL_CALL
    )?.id;
    setHpsId(initialCallHpsId);
  }

  const { open: showDismissDialog } = useModal(confirmCloseModalAtom);

  const navigate = useNavigate();

  const appliedHpsId = job?.hiringPipelineStages?.find(
    hps => hps.stageType === HiringPipelineStageType.APPLICATION_REVIEW
  )?.id;

  const [
    addCandidateToInterviewProcess,
    { isLoading: isAddingCandidate },
  ] = useAddCandidateInitialOutreachInterviewMutation();

  const [submitReferral, { isLoading: isSubmittingReferral }] = useSubmitReferralMutation();

  const [canSourceProfile] = useLazyCanSourceProfileQuery();

  // RHF setup
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<InterviewPipelineCandidateInfoSchema>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      linkedinUrl: "",
      source: "APPLIED",
      agencyEmail: "",
      agencyName: "",
    },
    mode: "onBlur",
    resolver: zodResolver(interviewPipelineCandidateInfoZodSchema),
  });

  const { field: firstName } = useController({
    name: "firstName",
    control,
  });
  const { field: lastName } = useController({
    name: "lastName",
    control,
  });
  const { field: email } = useController({
    name: "email",
    control,
  });
  const { field: linkedinUrl } = useController({
    name: "linkedinUrl",
    control,
  });
  const { field: source } = useController({
    name: "source",
    control,
  });
  const { field: agencyName } = useController({
    name: "agencyName",
    control,
  });
  const { field: agencyEmail } = useController({
    name: "agencyEmail",
    control,
  });
  const { field: resumeField } = useController({
    name: "resume",
    control,
  });

  const onSuccessfulUpload = (resume: File): void => {
    resumeField.onChange(resume);
  };

  const onParseSuccessful = (result: ParseCandidateInfoResult): void => {
    if (result.firstName) {
      firstName.onChange(result.firstName);
    }
    if (result.lastName && !lastName.value) {
      lastName.onChange(result.lastName);
    }
    if (result.email && !email.value) {
      email.onChange(result.email);
    }
    if (result.linkedinUrl && !linkedinUrl.value) {
      linkedinUrl.onChange(result.linkedinUrl);
    }
  };

  const onClearUpload = (): void => {
    resumeField.onChange(undefined);
  };

  const [isParsingResume, basicResumeUploader] = useBasicResumeUploader({
    onSuccessfulUpload,
    onParseSuccessful,
    onClearUpload,
  });

  const formHasErrors = !!Object.keys(errors).length;
  const missingJobOrStageID = !jobId || !hpsId;

  useDebounce(
    () => {
      trigger(["email", "linkedinUrl"]).then(
        async (isValid: boolean): Promise<void> => {
          if (isValid && (!!email.value || !!linkedinUrl.value)) {
            const canSourceProfileResult = await canSourceProfile({
              data: {
                email: email.value || undefined,
                jobId: jobId!,
                linkedinPublicUrl: linkedinUrl.value || undefined,
              },
            }).unwrap();
            if (canSourceProfileResult.candidateId) {
              setExistingCandidateId(canSourceProfileResult.candidateId);
            } else {
              setExistingCandidateId(undefined);
            }
          }
        }
      );
    },
    250,
    [errors, jobId, trigger, email.value, linkedinUrl.value, setExistingCandidateId]
  );

  const addCandidate: SubmitHandler<InterviewPipelineCandidateInfoSchema> = async (
    data: InterviewPipelineCandidateInfoSchema
  ): Promise<void> => {
    const source = ["APPLIED", "SOURCED"].includes(data.source) ? "UNKNOWN" : data.source;
    const isReferral = source === "CANDIDATE_REFERRED";

    showPendingToast("Adding candidate");

    const call = isReferral
      ? submitReferral({
          jobId: jobId!,
          referrerEmail: data.agencyEmail!,
          referrerName: data.agencyName!,
          candidateFirstName: data.firstName,
          candidateLastName: data.lastName,
          candidateEmail: data.email!,
          candidateLinkedinUrl: data.linkedinUrl,
          resume: data.resume as Blob,
        })
      : addCandidateToInterviewProcess({
          job: jobId!,
          // Candidate info
          pipelineStage: hpsId!,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email ?? undefined,
          linkedinProfileUrl: data.linkedinUrl,
          resume: data.resume as Blob,
          sourceName: source,
          agencyName: data.agencyName ?? undefined,
          agencyEmail: data.agencyEmail ?? undefined,
        });

    const addResponse = await call.unwrap();

    if (addResponse.success) {
      showSuccessToast("Candidate added succesfully");
      close();

      if (!isReferral) {
        const candidateId = (addResponse as AddCandidateInterviewProcessResponse)?.candidate;
        if (candidateId) {
          navigate(APP_ROUTE_PATHS.candidates.candidateDetail(candidateId));
        }
      }
    } else {
      const defaultErrorMsg = "Couldn't add candidate due to an unknown error";
      const msg =
        (!isReferral ? (addResponse as AddCandidateInterviewProcessResponse)?.message : defaultErrorMsg) ??
        defaultErrorMsg;
      showErrorToast(msg);
      console.error(msg);
    }
  };

  const CTA_INFO_MAP: { [key: string]: { description: string; linkUrl: string } } = {
    ["APPLIED"]: {
      description: "Use the Dover careers page to automatically add candidates when they apply.",
      linkUrl: "https://help.dover.com/en/articles/6480726-careers-page",
    },
    ["SOURCED"]: {
      description: "Try our free sourcing extension to save time on adding candidates manually.",
      linkUrl: "https://help.dover.com/en/articles/6301760-dover-sourcing-extension",
    },
    ["CANDIDATE_REFERRED"]: {
      description: "Invite your network to refer candidates.",
      linkUrl: "https://help.dover.com/en/articles/10172682-employee-referrals",
    },
    ["AGENCY"]: {
      description: "Get flexible recruiting help at a fraction of the cost of agencies.",
      linkUrl: "https://www.dover.com/recruiters",
    },
  };

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={(): void => {
        if (firstName.value || lastName.value || resumeField.value || email.value || linkedinUrl.value) {
          showDismissDialog({ confirmClose: close });
        } else {
          close();
        }
      }}
      sx={{ position: "relative", overflow: "auto" }}
    >
      <DrawerHeader />
      {isAddingCandidate ? (
        <Stack justifyContent={"center"} alignItems={"center"} height={"100%"} width={"100%"}>
          <DoverLoadingSpinner />
        </Stack>
      ) : (
        <Stack
          direction="column"
          spacing={2}
          width="100%"
          sx={{ p: 3, width: "100%", height: "100%", overflow: "auto" }}
        >
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
              <BodySmall weight="600">Resume</BodySmall>
              <BodySmall>(optional)</BodySmall>
            </Stack>
            {basicResumeUploader}
          </Stack>
          <Stack spacing={0.5}>
            <BodySmall weight="600">First Name</BodySmall>
            <TextField
              error={!!errors.firstName}
              errorMessage="First name is required"
              placeholderText="Enter first name"
              onTextUpdated={firstName.onChange}
              text={firstName.value}
              loading={isParsingResume}
            />
          </Stack>
          <Stack spacing={0.5}>
            <BodySmall weight="600">Last Name</BodySmall>
            <TextField
              error={!!errors.lastName}
              errorMessage="Last name is required"
              placeholderText="Enter last name"
              onTextUpdated={lastName.onChange}
              text={lastName.value}
              loading={isParsingResume}
            />
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
              <BodySmall weight="600">Email</BodySmall>
              {source.value !== "CANDIDATE_REFERRED" && <BodySmall>(optional)</BodySmall>}
            </Stack>

            <TextField
              error={!!errors.email}
              errorMessage={errors.email?.message}
              placeholderText="Enter email"
              onTextUpdated={email.onChange}
              text={email.value}
              loading={isParsingResume}
            />
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
              <BodySmall weight="600">LinkedIn</BodySmall>
              <BodySmall>(optional)</BodySmall>
            </Stack>

            <TextField
              error={!!errors.linkedinUrl}
              errorMessage={errors.linkedinUrl?.message}
              placeholderText="Enter URL"
              onTextUpdated={linkedinUrl.onChange}
              text={linkedinUrl.value}
              loading={isParsingResume}
            />
          </Stack>
          {existingCandidateId && (
            <Banner variant={BannerVariant.Critical}>
              <BodySmall>
                {"A candidate with this LinkedIn or email already exists for this job. "}
                <InternalLink
                  $variant="primary"
                  to={APP_ROUTE_PATHS.candidates.candidateDetail(existingCandidateId)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View
                </InternalLink>
              </BodySmall>
            </Banner>
          )}
          {jobsLoading ? (
            <></>
          ) : (
            <Stack direction="row" spacing={2}>
              <Stack spacing={0.5} sx={{ width: "60%" }} flexGrow={1}>
                <BodySmall weight="600">Job</BodySmall>
                <AddCandidateJobSelector
                  jobId={jobId}
                  setJobId={(jobId): void => {
                    setJobId(jobId);
                    setHpsId(undefined);
                  }}
                />
              </Stack>
              {["AGENCY", "CANDIDATE_REFERRED"].includes(source.value) && (
                <Stack spacing={0.5} sx={{ width: "40%" }}>
                  <BodySmall weight="600">Stage</BodySmall>
                  <PipelineStageSelect
                    job={job}
                    initialHpsId={hpsId}
                    setPipelineStage={(stage): void => setHpsId(stage?.id)}
                  />
                </Stack>
              )}
            </Stack>
          )}
          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="flex-start" alignItems={"center"} spacing={0.5}>
              <BodySmall weight="600">Source</BodySmall>
              <InfoTip text="Where did you find this person? This is used for reporting and filtering." />
            </Stack>

            <Stack direction="row" spacing={1}>
              {CANDIDATE_SOURCE_CHOICES.map(option => (
                <ButtonRadio
                  active={source.value === option.value}
                  label={option.label}
                  onClick={(): void => source.onChange(option.value)}
                  width="auto"
                />
              ))}
            </Stack>
          </Stack>
          {(source.value === "AGENCY" || source.value === "CANDIDATE_REFERRED") && (
            <Stack direction="row" spacing={1} justifySelf="stretch" sx={{ marginBottom: "12px !important" }}>
              <Stack spacing={0.5} flexGrow={1}>
                <BodySmall weight="600">{source.value === "AGENCY" ? "Agency" : "Referrer"} Name</BodySmall>
                <TextField
                  error={!!errors.agencyName}
                  errorMessage={errors.agencyName?.message}
                  placeholderText="Enter name"
                  onTextUpdated={agencyName.onChange}
                  text={agencyName.value}
                />
              </Stack>
              <Stack spacing={0.5} flexGrow={1}>
                <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
                  <BodySmall weight="600">{source.value === "AGENCY" ? "Agency" : "Referrer"} Email</BodySmall>
                </Stack>
                <TextField
                  error={!!errors.agencyEmail}
                  errorMessage={errors.agencyEmail?.message}
                  placeholderText="Enter email"
                  onTextUpdated={agencyEmail.onChange}
                  text={agencyEmail.value}
                />
              </Stack>
            </Stack>
          )}

          {appliedHpsId && appliedHpsId === hpsId && (
            <Banner variant={BannerVariant.Warning}>
              <BodySmall>
                Candidates manually added to the Applied stage will not be visible in Dover&apos;s Application Review
                interface. To view applicants via this interface, please apply candidates via the job&apos;s
                <Box
                  sx={{ cursor: "pointer", color: colors.link, display: "inline", paddingLeft: "4px" }}
                  onClick={(): void => {
                    window.open(`${appConfig.appUrl}/apply/${job?.client}/${job?.id}`, "_blank", "noopener noreferrer");
                  }}
                >
                  application page
                </Box>
                .
              </BodySmall>
            </Banner>
          )}

          <div style={{ marginTop: "auto" }}>
            <Stack spacing={1}>
              <Divider sx={{ marginLeft: "-24px", marginRight: "-24px" }} />
              <div style={{ marginRight: "-16px", marginLeft: "-16px" }}>
                <Stack spacing={1}>
                  {Object.keys(CTA_INFO_MAP).includes(source.value) && (
                    <AddCandidateCTA
                      description={CTA_INFO_MAP[source.value].description}
                      linkUrl={CTA_INFO_MAP[source.value].linkUrl}
                    />
                  )}
                  <Stack direction="row" justifyContent="flex-end" sx={{ p: 0 }} spacing={1}>
                    <Button
                      disabled={formHasErrors || missingJobOrStageID || !!existingCandidateId}
                      tooltip={
                        existingCandidateId
                          ? "Candidate already exists"
                          : missingJobOrStageID
                          ? "Select a job and stage to add candidate"
                          : undefined
                      }
                      loading={isAddingCandidate || isSubmittingReferral}
                      onClick={handleSubmit(addCandidate)}
                      variant={ButtonVariant.Primary}
                    >
                      Add Candidate
                    </Button>
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </div>
        </Stack>
      )}
    </Drawer>
  );
};

export const addCandidateV2DrawerAtom = modalAtom(AddCandidateV2Drawer);
