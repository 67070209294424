import styled from "styled-components";

import { colors } from "styles/theme";

export const CANDIDATE_CARD_HEIGHT = 120;

export const KanbanWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow-x: auto;
`;

interface StyledStageColumnProps {
  minimized: boolean;
}

export const StageColumn = styled.div<StyledStageColumnProps>`
  width: ${(props): string => (props.minimized ? "36px" : "275px !important")};
  flex-shrink: 0;
  border-right: 1px solid ${(props): string => props.theme.colors.grayscale.gray200};
  overflow-y: auto;
  padding: 12px 8px;
  transition: width 0.3s ease;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

interface StageTitleProps {
  rotate: boolean;
}

export const StageTitle = styled.div<StageTitleProps>`
  position: sticky;
  top: -12px;
  margin-top: -12px;
  margin-bottom: 4px;
  transform-origin: left;
  transform: ${({ rotate }): string => (rotate ? "rotate(90deg) translate(-8px, -8px)" : "none")};
  transition: transform 0.3s ease;
  white-space: nowrap;
  display: flex;
  z-index: 5;
  padding: 12px 8px;
  background-color: ${colors.grayscale.gray025};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CandidateCard = styled.div`
  background-color: white;
  border: 1px solid ${(props): string => props.theme.colors.grayscale.gray200};
  border-radius: 6px;
  padding: 8px;
  width: 100%;
  margin-bottom: 8px;
  z-index: 1;
  min-height: ${CANDIDATE_CARD_HEIGHT}px;
`;

interface CandidateCard2Props {
  disabled: boolean;
  preview?: boolean;
}

export const CandidateCard2 = styled.div<CandidateCard2Props>`
  background-color: white;
  border: 1px solid ${(props): string => props.theme.colors.grayscale.gray200};
  border-radius: 6px;
  cursor: ${({ disabled }): string | undefined => (disabled ? undefined : "pointer")};
  padding: 8px;
  width: 100%;
  opacity: ${({ preview }): number => (preview ? 0.7 : 1)};
`;
