import React from "react";

import { ReactComponent as MicrosoftSVG } from "assets/icons/microsoft.svg";
import ConnectButton from "components/dover/OnlineAccounts/ConnectButton";
import { microsoftConnectModalAtom } from "components/dover/OnlineAccounts/MicrosoftConnectModal";
import { useModal } from "GlobalOverlays/atoms";

const ConnectMicrosoftButton = (): React.ReactElement => {
  const { open: openModal } = useModal(microsoftConnectModalAtom);

  const click = (): void => {
    openModal({});
  };

  return (
    <ConnectButton
      isLoading={false}
      text="Connect Microsoft"
      icon={<MicrosoftSVG width="14px" height="14px" />}
      onClick={click}
    />
  );
};

export default ConnectMicrosoftButton;
