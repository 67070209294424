import { Box, Stack } from "@mui/material";
import pick from "lodash/pick";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

import { AccountSettingsSectionType } from "App/routing/types";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import Banner from "components/Banner";
import { ClientPickerWarningBanner } from "components/dover/ClientPickerWarningBanner";
import { ConnectCalendarCard } from "components/dover/OnlineAccounts/ConnectCalendarCard";
import { ConnectedCard } from "components/dover/OnlineAccounts/ConnectedCard";
import Loading from "components/HotLoading";
import { BlockNav } from "components/library/BlockNav";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Body, BodySmall, Subtitle1 } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import {
  useGetInterviewerProUserQuery,
  useGetUserInterviewerSchedulingInfoQuery,
  usePartialUpdateInterviewerSchedulingInfoMutation,
} from "services/doverapi/endpoints/proUser";
import { InterviewerSchedulingInfo, InterviewerSchedulingInfoSchedulingLinkConfigEnum } from "services/openapi/models";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import AINotetakerSettings from "views/CompanySetup/components/InterviewPreferences/components/AINotetakerSettings";
import { AvailabilityChart } from "views/CompanySetup/components/InterviewPreferences/components/AvailabilityChart";
import { AvailabilityDebugger } from "views/CompanySetup/components/InterviewPreferences/components/AvailabilityDebugger";
import { MaxInterviewsPerDay } from "views/CompanySetup/components/InterviewPreferences/components/MaxInterviewsPerDay";
import { MeetingBuffer } from "views/CompanySetup/components/InterviewPreferences/components/MeetingBuffer";
import { MeetingTypePicker } from "views/CompanySetup/components/InterviewPreferences/components/MeetingTypePicker";
import { MinFutureBookingTimeOverride } from "views/CompanySetup/components/InterviewPreferences/components/MinFutureBookingTimeOverride";
import { ProtectedCalendars } from "views/CompanySetup/components/InterviewPreferences/components/ProtectedCalendars";
import RFFTimezonePicker from "views/CompanySetup/components/InterviewPreferences/components/RFFTimezonePicker";
import { RoleTitle } from "views/CompanySetup/components/InterviewPreferences/components/RoleTitle";
import { SchedulingLinkConfigPicker } from "views/CompanySetup/components/InterviewPreferences/components/SchedulingLinkConfigPicker";
import { SchedulingLinkInput } from "views/CompanySetup/components/InterviewPreferences/components/SchedulingLinkInput";
import { RequiredAsterisk, WhiteCard } from "views/CompanySetup/components/InterviewPreferences/styles";
import { InterviewPreferencesFormValues } from "views/CompanySetup/components/InterviewPreferences/types";
import { validateForm } from "views/CompanySetup/components/InterviewPreferences/utils";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { accountSettingsConfiguration } from "views/CompanySetup/constants";

const StickyDiv = styled.div`
  overflow: hidden;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 0;
  background-color: ${(props): string => props.theme.backgrounds.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid ${(props): string => props.theme.colors.grayscale.gray200};
  z-index: 100;
  margin-top: 16px;
  margin-bottom: -16px;
`;

const SetupInterviewer = (): React.ReactElement => {
  // Group config with general AI Notetaker FF
  const showAINotetakerConfig = useFeatureFlag(FeatureFlag.AINotetaker);

  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [polling, setPolling] = React.useState<boolean>(true);

  const isAdmin = useHasRole(Role.ADMIN);
  const isInterviewer = useHasRole(Role.INTERVIEWER);
  const {
    data: currentInterviewerSchedulingInfo,
    isLoading: isLoadingInterviewerSchedulingInfo,
  } = useGetUserInterviewerSchedulingInfoQuery(undefined, {
    pollingInterval: polling ? 3000 : 0,
  });

  useEffect(() => {
    setPolling(!currentInterviewerSchedulingInfo?.calendarConnected);
  }, [currentInterviewerSchedulingInfo?.calendarConnected]);

  const { isLoading: isLoadingProUser } = useGetInterviewerProUserQuery();

  const [
    partialUpdateInterviewerSchedulingInfo,
    { isLoading: isSaving },
  ] = usePartialUpdateInterviewerSchedulingInfoMutation();

  useEffect(() => {
    if (currentInterviewerSchedulingInfo?.calendarConnected) {
      if (polling) {
        setPolling(false);
      }
    } else {
      setPolling(true);
    }
  }, [currentInterviewerSchedulingInfo?.calendarConnected, polling]);

  if (isLoadingProUser || isLoadingInterviewerSchedulingInfo || !currentInterviewerSchedulingInfo?.id) {
    return <Loading />;
  }

  const onSubmit = async (values: InterviewPreferencesFormValues): Promise<void> => {
    try {
      await partialUpdateInterviewerSchedulingInfo({
        id: currentInterviewerSchedulingInfo.id!,
        data: { ...values } as InterviewerSchedulingInfo,
      });

      setIsDirty(false);
    } catch {
      showErrorToast("Failed to save. Please refresh and try again.");
    }
  };

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  /* TODO: make a utils fxn and unit test to create initialValues */
  const initialValues = pick(currentInterviewerSchedulingInfo, [
    "availableTimes",
    "busyGcalIds",
    "interviewLocation",
    "interviewMeetingType",
    "meetingBuffer",
    "maxInterviewsPerDay",
    "minFutureBookingTimeOverride",
    "schedulingLinkConfig",
    "schedulingLinkOverride",
    "timezone",
  ]) as InterviewPreferencesFormValues;

  return (
    <SectionWrapper config={accountSettingsConfiguration[AccountSettingsSectionType.INTERVIEW_SCHEDULING]}>
      <BlockNav
        when={isDirty}
        hook={(): void => {
          setIsDirty(false);
        }}
        blockRefresh={true}
      />
      <Stack spacing={2} margin="0 auto" mb={8}>
        {showAINotetakerConfig && <AINotetakerSettings />}
        <Card>
          <Stack spacing={2}>
            <Box>
              <Stack direction="row" spacing={0.5} alignItems="center" mb={0.5}>
                <CalendarIcon />
                <Body weight="600">Scheduling Preferences</Body>
              </Stack>
              <BodySmall color={colors.grayscale.gray600}>
                {
                  "Connect your calendar to set your availability and scheduling preferences below. Meetings will be scheduled automatically on your calendar based on your availability."
                }
              </BodySmall>
            </Box>
            <ClientPickerWarningBanner
              warningText={`The client picker is currently set to a different client. If you wish to save your own interview preferences, reset the client picker to Dover. If you are trying to update another person's inteview preferences, use the impersonator.`}
            />
            <RoleTitle />
            {currentInterviewerSchedulingInfo?.calendarConnected && <ConnectedCard isCalendar />}

            <Form
              initialValues={initialValues}
              validate={validateForm}
              onSubmit={onSubmit}
              render={({ handleSubmit, invalid, values }): React.ReactElement => {
                return (
                  <form onSubmit={handleSubmit}>
                    <WhiteCard>
                      <Stack spacing={2}>
                        <SchedulingLinkConfigPicker setIsDirty={setIsDirty} />
                        {values.schedulingLinkConfig ===
                          InterviewerSchedulingInfoSchedulingLinkConfigEnum.DoverSchedulingLink &&
                          (currentInterviewerSchedulingInfo?.calendarConnected ? (
                            <>
                              <MeetingTypePicker
                                googleMeetEnabled={currentInterviewerSchedulingInfo?.googleMeetEnabled!}
                                setIsDirty={setIsDirty}
                              />

                              <Subtitle1>
                                When are you available for interviews?
                                <RequiredAsterisk />
                              </Subtitle1>
                              {timeZone != values.timezone ? (
                                <Banner type="warning">
                                  <Body>
                                    We have noticed that your system timezone is {timeZone}. Please update your
                                    preferred timezone below if needed.
                                  </Body>
                                </Banner>
                              ) : (
                                <></>
                              )}

                              <RFFTimezonePicker
                                label="Timezone"
                                initialValue={currentInterviewerSchedulingInfo.timezone}
                                setIsDirty={setIsDirty}
                              />

                              <AvailabilityChart setIsDirty={setIsDirty} />

                              <ProtectedCalendars setIsDirty={setIsDirty} />

                              <MeetingBuffer setIsDirty={setIsDirty} />

                              <MaxInterviewsPerDay setIsDirty={setIsDirty} />

                              {(isAdmin || isInterviewer) && <MinFutureBookingTimeOverride setIsDirty={setIsDirty} />}

                              {currentInterviewerSchedulingInfo.availabilityDebuggerLink && (
                                <AvailabilityDebugger
                                  availabilityDebuggerLink={currentInterviewerSchedulingInfo.availabilityDebuggerLink}
                                />
                              )}
                            </>
                          ) : (
                            <ConnectCalendarCard />
                          ))}
                        {values.schedulingLinkConfig ===
                          InterviewerSchedulingInfoSchedulingLinkConfigEnum.ThirdPartySchedulingLink && (
                          <SchedulingLinkInput />
                        )}
                      </Stack>
                    </WhiteCard>
                    <StickyDiv>
                      <Box width="calc(100% - 100px)" margin="0 auto" justifyContent="flex-end" display="flex">
                        <Button disabled={isSaving || invalid} variant={ButtonVariant.Primary} onClick={handleSubmit}>
                          Save
                        </Button>
                      </Box>
                    </StickyDiv>
                  </form>
                );
              }}
            />
          </Stack>
        </Card>
      </Stack>
    </SectionWrapper>
  );
};

export default SetupInterviewer;
