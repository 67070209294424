/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubmitFeedbackReminderRequest
 */
export interface SubmitFeedbackReminderRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitFeedbackReminderRequest
     */
    interviewId: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitFeedbackReminderRequest
     */
    isMultipart?: boolean;
}

export function SubmitFeedbackReminderRequestFromJSON(json: any): SubmitFeedbackReminderRequest {
    return SubmitFeedbackReminderRequestFromJSONTyped(json, false);
}

export function SubmitFeedbackReminderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitFeedbackReminderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interviewId': json['interview_id'],
        'isMultipart': !exists(json, 'is_multipart') ? undefined : json['is_multipart'],
    };
}

export function SubmitFeedbackReminderRequestToJSON(value?: SubmitFeedbackReminderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interview_id': value.interviewId,
        'is_multipart': value.isMultipart,
    };
}


