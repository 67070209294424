import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { ExternalFooter } from "components/ExternalFooter";
import { TitleLarge } from "components/library/typography";
import { CareersPageClient } from "services/openapi";
import { colors } from "styles/theme";

interface PublicNotFoundProps {
  title: string;
  subtitle: string;
  client?: CareersPageClient;
}

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  font-family: Inter, sans-serif;
  background-color: white;
  padding-top: 24px;
`;

export const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${(props): string => props.color ?? colors.black};
`;

const PublicNotFound: React.FC<PublicNotFoundProps> = ({ title, subtitle, client }) => {
  return (
    <MainContainer>
      <Stack justifyContent={"space-between"} height={"100%"}>
        <Stack spacing={2} alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"}>
          <TitleLarge color={colors.grayscale.gray600}>{title}</TitleLarge>
          <SectionHeader style={{ fontWeight: 400 }} color={colors.grayscale.gray600}>
            {subtitle}
          </SectionHeader>
        </Stack>
        <ExternalFooter client={client} />
      </Stack>
    </MainContainer>
  );
};

export default PublicNotFound;
