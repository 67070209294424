/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CareersPageCompanyStat,
    CareersPageCompanyStatFromJSON,
    CareersPageCompanyStatFromJSONTyped,
    CareersPageCompanyStatToJSON,
    CareersPageSocialLink,
    CareersPageSocialLinkFromJSON,
    CareersPageSocialLinkFromJSONTyped,
    CareersPageSocialLinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface CareersPageClient
 */
export interface CareersPageClient {
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    readonly logo?: string;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    careersPageInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    readonly careersPageImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    careersPageLogoRedirectUrl?: string | null;
    /**
     * 
     * @type {Array<CareersPageCompanyStat>}
     * @memberof CareersPageClient
     */
    careersPageDisplayStats?: Array<CareersPageCompanyStat>;
    /**
     * 
     * @type {Array<CareersPageSocialLink>}
     * @memberof CareersPageClient
     */
    careersPageSocialLinks?: Array<CareersPageSocialLink>;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    biasAuditLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    doverPlan?: CareersPageClientDoverPlanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CareersPageClient
     */
    enableTalentNetwork?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CareersPageClient
     */
    readonly primaryDomain?: string;
}

/**
* @export
* @enum {string}
*/
export enum CareersPageClientDoverPlanEnum {
    NoAccess = 'NO_ACCESS',
    Free = 'FREE',
    Credits = 'CREDITS',
    PayAsYouGo = 'PAY_AS_YOU_GO',
    JobSlots = 'JOB_SLOTS',
    Growth = 'GROWTH',
    Concierge = 'CONCIERGE',
    FreeAts = 'FREE_ATS',
    FreeExtension = 'FREE_EXTENSION'
}

export function CareersPageClientFromJSON(json: any): CareersPageClient {
    return CareersPageClientFromJSONTyped(json, false);
}

export function CareersPageClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareersPageClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'name': json['name'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'careersPageInfo': !exists(json, 'careers_page_info') ? undefined : json['careers_page_info'],
        'careersPageImage': !exists(json, 'careers_page_image') ? undefined : json['careers_page_image'],
        'careersPageLogoRedirectUrl': !exists(json, 'careers_page_logo_redirect_url') ? undefined : json['careers_page_logo_redirect_url'],
        'careersPageDisplayStats': !exists(json, 'careers_page_display_stats') ? undefined : ((json['careers_page_display_stats'] as Array<any>).map(CareersPageCompanyStatFromJSON)),
        'careersPageSocialLinks': !exists(json, 'careers_page_social_links') ? undefined : ((json['careers_page_social_links'] as Array<any>).map(CareersPageSocialLinkFromJSON)),
        'biasAuditLink': !exists(json, 'bias_audit_link') ? undefined : json['bias_audit_link'],
        'doverPlan': !exists(json, 'dover_plan') ? undefined : json['dover_plan'],
        'enableTalentNetwork': !exists(json, 'enable_talent_network') ? undefined : json['enable_talent_network'],
        'primaryDomain': !exists(json, 'primary_domain') ? undefined : json['primary_domain'],
    };
}

export function CareersPageClientToJSON(value?: CareersPageClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'name': value.name,
        'careers_page_info': value.careersPageInfo,
        'careers_page_logo_redirect_url': value.careersPageLogoRedirectUrl,
        'careers_page_display_stats': value.careersPageDisplayStats === undefined ? undefined : ((value.careersPageDisplayStats as Array<any>).map(CareersPageCompanyStatToJSON)),
        'careers_page_social_links': value.careersPageSocialLinks === undefined ? undefined : ((value.careersPageSocialLinks as Array<any>).map(CareersPageSocialLinkToJSON)),
        'bias_audit_link': value.biasAuditLink,
        'dover_plan': value.doverPlan,
        'enable_talent_network': value.enableTalentNetwork,
    };
}


