import { Skeleton, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as RocketIcon } from "assets/icons/rocket-black.svg";
import { BodySmall } from "components/library/typography";
import { SetupGuide, SetupGuideStep } from "components/SetupGuide";
import { ReactComponent as InviteIntervewPanelIllustration } from "components/SetupGuide/illustrations/invite-interview-panel.svg";
import { ReactComponent as InviteReferralsIllustration } from "components/SetupGuide/illustrations/invite-referrals.svg";
import { ReactComponent as PostJobBoardsIllustration } from "components/SetupGuide/illustrations/post-job-boards.svg";
import { ReactComponent as RecruitingExpertsIlustration } from "components/SetupGuide/illustrations/recruiting-experts.svg";
import { CTAButton, GrayBody } from "components/SetupGuide/SetupGuideCTA";
import { appConfig } from "config/appConfig";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useAllHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";
import { useListReferrersQuery } from "views/referralsV2/endpoints";

/* -----------------------------------------------------------------------------
 * SetupGuide
 * -------------------------------------------------------------------------- */

export const JobSetupGuide = ({ jobId }: { jobId: string }): React.ReactElement => {
  const { data: referrersListResponse } = useListReferrersQuery({ limit: 1, isAgencyRecruiter: false, jobId });

  // make requests for setup steps
  const { data: job, isFetching: isFetchingJob } = useGetJobQuery(jobId);

  const { stages, isFetching: isFetchingHps } = useAllHps(jobId);

  const [dismissed, setDismissed] = useLocalStorage(`setupGuideDismissed${jobId}`, false);
  const [hidden, setHidden] = useLocalStorage(`setupGuideHidden${jobId}`, false);

  const [hasBookedConsultation, setHasBookedConsultation] = useLocalStorage("hasBookedConsultation", false);

  const steps: Array<SetupGuideStep> = [
    {
      name: "Post to job boards",
      content: <GrayBody>Promote your job to get lots of applications.</GrayBody>,
      graphic: <PostJobBoardsIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.jobBoards(jobId)}>Add to job boards</CTAButton>,
      isComplete: !!job?.jobBoardsSetupComplete,
    },
    {
      name: "Set your interview plan",
      content: <GrayBody>Add your team to your interview plan to organize your process.</GrayBody>,
      graphic: <InviteIntervewPanelIllustration width="100%" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.interviewPlan(jobId)}>Build your plan</CTAButton>,
      isComplete:
        !job?.isSample &&
        !!stages?.some(s => moment(s.modified).diff(moment(job?.created), "minutes") > 0) &&
        !!stages
          ?.filter(s => s.multipartInterviewStage)
          .every(s => s.multipartInterviewStage?.substages?.filter(s => s.possibleInterviewers.length > 0)),
    },
    {
      name: "Invite referrers",
      content: <GrayBody>Tap into your extended network to find your next great hires.</GrayBody>,
      graphic: <InviteReferralsIllustration width="100%" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.referrals(jobId)}>Add referrals</CTAButton>,
      isComplete: !!referrersListResponse?.count,
    },
    {
      name: "Get expert recruiting help",
      isComplete: hasBookedConsultation,
      content: <GrayBody>Our expert fractional recruiters will help you hire top talent.</GrayBody>,
      graphic: <RecruitingExpertsIlustration />,
      ctaButton: (
        <CTAButton
          onPress={(): void => {
            setHasBookedConsultation(true);
            window.open(`${appConfig.appUrl}${APP_ROUTE_PATHS.recruitingPartners()}`, "_blank", "noopener noreferrer");
          }}
        >
          Meet our Recruiting Partners
        </CTAButton>
      ),
    },
  ];

  const isLoading = isFetchingHps || isFetchingJob;

  const allComplete = steps.every(s => s.isComplete);

  useEffect(() => {
    if (allComplete) {
      setHidden(true);
    }
  }, [allComplete, setHidden]);

  if (isLoading) {
    return <Skeleton width="100%" height="240px" />;
  }

  const dismissGuide = (): void => {
    setDismissed(true);
  };

  if (dismissed) {
    return <></>;
  }

  return (
    <Stack spacing={1}>
      <SetupGuide
        steps={steps}
        onDismiss={dismissGuide}
        loading={isLoading}
        heading={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Stack direction="row" alignItems={"center"} spacing={0.5}>
              <RocketIcon color="black" />
              <BodySmall weight="600">Setup checklist</BodySmall>
            </Stack>
            {/* {!allComplete && <Chip variant="Informational" label="Incomplete" />} */}
          </Stack>
        }
        showHideButton
        isHiddenInitially={hidden}
        onToggleHide={(): void => setHidden(!hidden)}
      />
    </Stack>
  );
};
