import { Button, Typography } from "@mui/joy";
import React from "react";

interface ConnectButtonProps {
  onClick?: () => void;
  isLoading?: boolean;
  icon: React.ReactElement;
  text: string;
}

const ConnectButton = ({ onClick, isLoading, icon, text }: ConnectButtonProps): React.ReactElement => {
  return (
    <Button
      loading={isLoading}
      onClick={onClick}
      startDecorator={icon}
      variant="outlined"
      sx={{
        "&.MuiButton-variantOutlined": {
          borderColor: "#E1E7EC",
          color: "#465361",
        },
      }}
    >
      <Typography sx={{ color: "#465361", fontWeigth: 400, fontFamily: "Inter", fontSize: "12px" }}>{text}</Typography>
    </Button>
  );
};

export default ConnectButton;
