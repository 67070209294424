import { Stack } from "@mui/material";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { SOURCING_EXTENSION_STORE_URL } from "components/constants";
import { SourcingExtensionCard } from "components/dover/sourcing/SourcingExtensionCard";
import { Heading } from "components/library/typography";
import { SetupGuide, SetupGuideStep } from "components/SetupGuide";
import { ReactComponent as CreateCareersPageIllustration } from "components/SetupGuide/illustrations/create-careers-page.svg";
import { ReactComponent as ExtensionIllustration } from "components/SetupGuide/illustrations/extension.svg";
import { ReactComponent as InterviewPreferencesIllustration } from "components/SetupGuide/illustrations/interview-preferences.svg";
import { ReactComponent as PostJobBoardsIllustration } from "components/SetupGuide/illustrations/post-job-boards.svg";
import { ReactComponent as RecruitingExpertsIlustration } from "components/SetupGuide/illustrations/recruiting-experts.svg";
import { CTAButton, GrayBody } from "components/SetupGuide/SetupGuideCTA";
import { appConfig } from "config/appConfig";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useIsExtensionInstalled } from "hooks/useIsExtensionInstalled";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useGetCompanySetupStepsQuery } from "services/doverapi/endpoints/client/endpoints";
import { selectFromListJobsQueryResult } from "services/doverapi/endpoints/job";
import { useListJobsQuery } from "services/doverapi/endpoints/job/endpoints";
import {
  useGetAuthedUserInfoQuery,
  useGetUserInterviewerSchedulingInfoQuery,
  usePartialUpdateProUserMutation,
} from "services/doverapi/endpoints/proUser";
import { CompanySetupStepStateEnum, CompanySetupStepStepNameEnum } from "services/openapi";
import { InterviewerSchedulingInfoSchedulingLinkConfigEnum } from "services/openapi/models/InterviewerSchedulingInfo";
import { useHasManuallySourcedCandidates } from "views/candidates/hooks";

export const HomepageSetupGuide = ({ jobId }: { jobId: string }): React.ReactElement => {
  const isExtensionInstalled = useIsExtensionInstalled();
  const showExtensionAd = useFeatureFlag(FeatureFlag.AdvertiseSourcingExtension);

  // make requests for setup steps
  const { activeJobs, isFetching } = useListJobsQuery(undefined, {
    selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults),
  });
  const onlyHasSampleJob = activeJobs?.length === 1 && activeJobs[0].isSample;

  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const [partialUpdateProUser] = usePartialUpdateProUserMutation();

  const { data: companySetupSteps, isLoading: isCompanySetupStepsLoading } = useGetCompanySetupStepsQuery();
  const {
    data: currentInterviewerSchedulingInfo,
    isLoading: isInterviewerSchedulingInfoLoading,
  } = useGetUserInterviewerSchedulingInfoQuery();
  const { isFetching: isFetchingManuallySourcedCandidates } = useHasManuallySourcedCandidates(jobId);

  // We do not want to display the ATS setup steps if the client's ATS type
  // is one of Lever, Greenhouse, or Ashby
  // determine steps completed
  const hasCareersPage =
    companySetupSteps?.setupSteps?.find(step => step.stepName === CompanySetupStepStepNameEnum.CareersPageState)
      ?.state === CompanySetupStepStateEnum.Complete;
  const hasInterviewPreferences =
    !!currentInterviewerSchedulingInfo?.googleMeetEnabled ||
    currentInterviewerSchedulingInfo?.schedulingLinkConfig ===
      InterviewerSchedulingInfoSchedulingLinkConfigEnum.NoSchedulingLink ||
    (currentInterviewerSchedulingInfo?.schedulingLinkConfig ===
      InterviewerSchedulingInfoSchedulingLinkConfigEnum.ThirdPartySchedulingLink &&
      currentInterviewerSchedulingInfo?.schedulingLinkOverride) ||
    (currentInterviewerSchedulingInfo?.schedulingLinkConfig ===
      InterviewerSchedulingInfoSchedulingLinkConfigEnum.DoverSchedulingLink &&
      currentInterviewerSchedulingInfo?.calendarConnected);

  const [hasBookedConsultation, setHasBookedConsultation] = useLocalStorage("hasBookedConsultation", false);

  const dismissGuide = (): void => {
    if (userInfo?.id) {
      partialUpdateProUser({ user: userInfo.id.toString(), data: { setupGuideDismissed: true } });
    }
  };

  const isLoading =
    isCompanySetupStepsLoading ||
    isInterviewerSchedulingInfoLoading ||
    isFetchingManuallySourcedCandidates ||
    isFetching;

  const steps: Array<SetupGuideStep> = [
    {
      name: "Create your careers page",
      content: (
        <GrayBody>
          Create a careers page that attracts top talent and showcases your company&apos;s unique culture.
        </GrayBody>
      ),
      graphic: <CreateCareersPageIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.editCareersPage()}>Get started</CTAButton>,
      isComplete: hasCareersPage,
    },
    {
      name: "Add your first job",
      isComplete: !isFetching && !onlyHasSampleJob,
      content: <GrayBody>Create a job and add your hiring team in less than a minute.</GrayBody>,
      graphic: <PostJobBoardsIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.createJob()}>Create a Job</CTAButton>,
    },
    {
      name: "Set interview preferences",
      isComplete: !!hasInterviewPreferences,
      content: (
        <GrayBody>
          Configure your personalized interview workflow in minutes to streamline candidate interviews.
        </GrayBody>
      ),
      graphic: <InterviewPreferencesIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.userSettings.interviewPreferences()}>Set your preferences</CTAButton>,
    },
    ...(showExtensionAd
      ? [
          isExtensionInstalled
            ? {
                name: "Install sourcing extension",
                isComplete: true,
                ctaMaxWidth: "90%",
                content: (
                  <GrayBody>
                    <SourcingExtensionCard />
                  </GrayBody>
                ),
              }
            : {
                name: "Install sourcing extension",
                isComplete: false,
                ctaMaxWidth: "70%",
                content: (
                  <GrayBody>
                    Source top talent on LinkedIn, send personalized emails, and add them directly to your job.
                  </GrayBody>
                ),
                graphic: <ExtensionIllustration />,
                ctaButton: (
                  <CTAButton
                    onPress={(): void => {
                      window.open(SOURCING_EXTENSION_STORE_URL, "_blank", "noopener noreferrer");
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <span>Download</span>
                    </Stack>
                  </CTAButton>
                ),
              },
        ]
      : []),
    {
      name: "Get expert recruiting help",
      isComplete: hasBookedConsultation,
      content: <GrayBody>Our expert fractional recruiters will help you hire top talent.</GrayBody>,
      graphic: <RecruitingExpertsIlustration />,
      ctaButton: (
        <CTAButton
          onPress={(): void => {
            setHasBookedConsultation(true);
            window.open(`${appConfig.appUrl}${APP_ROUTE_PATHS.recruitingPartners()}`, "_blank", "noopener noreferrer");
          }}
        >
          Meet our Recruiting Partners
        </CTAButton>
      ),
    },
  ];

  return (
    <SetupGuide
      heading={<Heading>Get started</Heading>}
      steps={steps}
      loading={isLoading}
      onDismiss={dismissGuide}
      showHideButton={true}
      isHiddenInitially={steps.every(s => s.isComplete)}
    />
  );
};
