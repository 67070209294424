import { Box, Divider, Stack } from "@mui/material";
import React, { SyntheticEvent } from "react";
import { Control, useController, useFormState } from "react-hook-form";

import Toggle from "components/library/Toggle";
import { BodySmall, Subtitle1, Subtitle2 } from "components/library/typography";
import StyledNumberField from "components/StyledNumberField";
import { colors } from "styles/theme";
import { DetailsFormSchema } from "views/job/JobSetup/steps/JobPosting/JobPosting";

interface EquityFieldsProps {
  control?: Control<DetailsFormSchema>;
}

const EquityFields = ({ control }: EquityFieldsProps): React.ReactElement => {
  const { errors } = useFormState<DetailsFormSchema>({ control });
  const { field: toggleField } = useController({ control, name: "openToSharingEquity" });

  return (
    <Stack spacing={1.5}>
      <Subtitle2>Equity Range</Subtitle2>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        height={{ xs: "auto", sm: "60px" }}
        alignItems={{ xs: "stretch", sm: "start" }}
      >
        <StyledNumberField control={control} name={"equityLowerBound"} error={!!errors.equityLowerBound} />

        <Divider
          sx={{
            width: "11px",
            height: "0.3px",
            marginTop: "20px !important",
            backgroundColor: colors.grayscale.gray400,
            display: { xs: "none", sm: "block" },
          }}
        />
        <StyledNumberField control={control} name={"equityUpperBound"} error={!!errors.equityUpperBound} />
        <Box
          sx={{
            paddingTop: "10px",
            display: { xs: "none", sm: "block" },
          }}
        >
          <Subtitle1>%</Subtitle1>
        </Box>
      </Stack>
      <Stack sx={{ marginTop: "-10px !important" }}>
        {!!errors.equityLowerBound && (
          <Subtitle2 color={colors.critical.base}>{errors.equityLowerBound.message}</Subtitle2>
        )}
        {!!errors.equityUpperBound && (
          <Subtitle2 color={colors.critical.base}>{errors.equityUpperBound.message}</Subtitle2>
        )}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Toggle
          checked={toggleField.value}
          label="openToSharingEquity"
          onChange={(event: SyntheticEvent<HTMLInputElement>): void => {
            toggleField.onChange((event.target as HTMLInputElement).checked);
          }}
        />
        <BodySmall>Show equity on job posting</BodySmall>
      </Stack>
    </Stack>
  );
};

export default EquityFields;
