import { Box, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDebounce } from "react-use";
import styled from "styled-components";

import { ReactComponent as PencilEditIcon } from "assets/icons/pencil-edit.svg";
import { Button, ButtonVariant } from "components/library/Button";
import MentionsEditor from "components/library/TipTap/MentionsEditor";
import { EditorType } from "components/library/TipTap/types";
import { Body } from "components/library/typography";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useCreateCandidateNoteMutation } from "services/doverapi/endpoints/candidate";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { colors } from "styles/theme";
import { EMPTY_P_TAG, getHtmlFromUnknownContent } from "utils/draftJS";

export const textEditorId = "fda2be83-c275-4eaf-8f99-c6aaa6e700bf";
const addNoteText = "Post";

export const CandidateNote = ({ candidateId }: { candidateId: string }): React.ReactElement => {
  const [createCandidateNote, { isLoading: isCreatingNote }] = useCreateCandidateNoteMutation();

  const { data: user } = useGetAuthedUserInfoQuery();

  const [savedEditorState, setSavedEditorState] = useLocalStorage<string>(`candidate-note-${candidateId}`, "");
  const hasSavedNotes = savedEditorState && savedEditorState != EMPTY_P_TAG;

  const [editorState, setEditorState] = useState<string>(savedEditorState);
  const [editorFocused, setEditorFocused] = useState<boolean>(false);

  useDebounce(
    () => {
      if (editorFocused) {
        setSavedEditorState(editorState);
      }
    },
    500,
    [editorFocused, editorState]
  );

  const createNoteRef = useRef<() => void>();

  const createNote = useCallback(() => {
    const createNoteAsync = async (): Promise<void> => {
      if (!editorState || !user) {
        return;
      }

      await createCandidateNote({
        candidate: candidateId,
        content: getHtmlFromUnknownContent(editorState),
        author: user?.id ? user.id.toString() : "",
      });

      setEditorState("");
      setSavedEditorState("");
      setEditorFocused(false);
    };

    createNoteAsync();
  }, [candidateId, createCandidateNote, editorState, user, setSavedEditorState]);

  useEffect(() => {
    createNoteRef.current = createNote;
  }, [createNote]);

  const onModEnter = useCallback(() => {
    if (createNoteRef.current) {
      createNoteRef.current();
    }
  }, []);

  // This seems to work on mac even though the keys are differently named
  // Adding both the windows and mac names seems to make the event fire twice, which is bad
  useHotkeys("ctrl+enter", createNote, undefined, [createNote]);

  const textEditor = useMemo(() => {
    if (!editorFocused) {
      return (
        <Button
          variant={ButtonVariant.Secondary}
          onClick={(e): void => {
            setEditorFocused(true);
            e.preventDefault();
          }}
          id={textEditorId}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <PencilEditIcon color={colors.grayscale.gray500} className="svg-fill" />
            <Body color={colors.grayscale.gray500}>{hasSavedNotes ? "Edit existing draft" : "Post a note"}</Body>
          </Stack>
        </Button>
      );
    }

    return (
      <Box
        id={textEditorId}
        onClick={(event: React.MouseEvent): void => {
          event.preventDefault();
        }}
      >
        <MentionsEditor
          initialContent={savedEditorState}
          onContentChange={setEditorState}
          editorType={EditorType.MenuEditor}
          readOnly={!editorFocused}
          onModEnter={onModEnter}
        />
      </Box>
    );
  }, [editorFocused, onModEnter, savedEditorState, hasSavedNotes]);

  const buttonControls = useMemo(() => {
    if (!editorFocused) {
      return undefined;
    }

    return (
      <Stack direction="row" spacing={1}>
        <Button variant={ButtonVariant.Primary} onClick={createNote} loading={isCreatingNote}>
          <Body color={colors.white}>{addNoteText}</Body>
        </Button>
        <Button
          variant={ButtonVariant.Secondary}
          onClick={(): void => {
            setEditorFocused(false);
            setEditorState("");
            setSavedEditorState("");
          }}
        >
          <Body>{"Cancel"}</Body>
        </Button>
      </Stack>
    );
  }, [createNote, editorFocused, isCreatingNote, setSavedEditorState]);

  return (
    <StyledEditor $focused={editorFocused} id="candidate-note-editor">
      <Stack spacing={2}>
        {!!textEditor && textEditor}
        {editorFocused && (
          <Stack width="100%" direction="row" alignItems="center" justifyContent="start" spacing={2}>
            {!!buttonControls && buttonControls}
            <Body color={colors.grayscale.gray500}>{"Use @ to notify your team"}</Body>
          </Stack>
        )}
      </Stack>
    </StyledEditor>
  );
};

interface StyledEditorProps {
  $focused: boolean;
}

const StyledEditor = styled.div<StyledEditorProps>`
  background-color: ${(props): string => (props.$focused ? colors.grayscale.gray100 : colors.white)};
  padding: ${(props): string => (props.$focused ? "16px" : "0")};
  position: relative;
  .formatting-toolbar {
    display: ${(props): string => (props.$focused ? "block" : "none")};
  }
  .addButton {
    width: 120px;
    font-weight: 400;
    background-color: ${colors.white};
  }
`;
