import { Button } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { format } from "date-fns";
import React from "react";

import { ReactComponent as ExternalLinkSVG } from "assets/icons/external-link.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { Chip } from "components/library/Chip";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, BodyExtraSmall } from "components/library/typography";
import { FormattedInboundSourceExplorerRow, SourceStatus } from "services/doverapi/endpoints/job-source-settings/types";
import { CandidateSource } from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { StatusCell } from "views/job/JobBoards/components/StatusCell";
import { YC_WAAS_ID } from "views/job/JobBoards/constants";

const SourceInfoSpacer = (): React.ReactElement => {
  return <div>•</div>;
};

const SourceInfo: React.FC<React.PropsWithChildren<{ source: FormattedInboundSourceExplorerRow }>> = ({ source }) => {
  const price = source.enrichedSourceDetails.jobCandidateSourceSettings?.pricingTier?.label; // check with thomas
  const datePosted = source.enrichedSourceDetails.jobCandidateSourceSettings?.desiredStateLastMadeActiveOn;
  const renewalDate = source.enrichedSourceDetails.jobCandidateSourceSettings?.currentPeriodEnd;
  const autoRenews = source.enrichedSourceDetails.jobCandidateSourceSettings?.shouldAutoRenew;
  const isActive = source.enrichedSourceDetails.status === SourceStatus.Active;
  const hasBeenPreviouslyAdded = !!source.enrichedSourceDetails.jobCandidateSourceSettings;

  if (!hasBeenPreviouslyAdded || !isActive) {
    return (
      <Box mt={1}>
        <BodyExtraSmall weight="400" color={colors.grayscale.gray600}>
          {source.enrichedSourceDetails.candidateSource.pricingLabel}
        </BodyExtraSmall>
      </Box>
    );
  }

  const hasInfo = !!price || (!!datePosted && isActive) || !!renewalDate;

  if (!hasInfo) {
    return <></>;
  }

  return (
    <BodyExtraSmall weight="400" color={colors.grayscale.gray600}>
      <Stack direction="row" spacing={1} alignItems="center" mt={1}>
        {price && (
          <>
            <div>{price}</div>
            <SourceInfoSpacer />
          </>
        )}
        {datePosted && isActive && <div>Posted: {format(datePosted, "M/d/yy")}</div>}
        {renewalDate && (
          <>
            <SourceInfoSpacer />
            {autoRenews ? <div>Renews: {format(renewalDate, "M/d/yy")}</div> : <div>Auto-renew off</div>}
          </>
        )}
      </Stack>
    </BodyExtraSmall>
  );
};

export const SourceCard: React.FC<React.PropsWithChildren<{
  source: FormattedInboundSourceExplorerRow;
  onClick: () => void;
}>> = ({ source, onClick }) => {
  const candidateSource = source.enrichedSourceDetails.candidateSource as CandidateSource;
  const isEditable = source.enrichedSourceDetails.candidateSource?.externallyEditable;
  const sourceExternalLink = source.enrichedSourceDetails.jobCandidateSourceSettings?.externalJobIdentifier;
  const hasBeenPreviouslyAdded = !!source.enrichedSourceDetails.jobCandidateSourceSettings;

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      onClick={onClick}
      sx={{
        "&:hover": { backgroundColor: colors.grayscale.gray100 },
        cursor: "pointer",
        "&:not(:last-of-type)": { borderBottom: `1px solid ${colors.grayscale.gray200}` },
      }}
    >
      <Stack direction="row" alignItems="flex-start" spacing={3}>
        <Stack>
          {/* TODO: if featured and logo exists, render here instead of label */}
          <BodySmall weight="500">{candidateSource.label}</BodySmall>
          <SourceInfo source={source} />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {/* YC waas is always active whether you want it on or not so just hide this to avoid confusion to users */}
          <StatusCell enrichedSourceDetails={source.enrichedSourceDetails} />
          {source.id === YC_WAAS_ID && (
            <Tooltip
              leaveDelay={200}
              title={
                <>
                  Integration - YC companies can sync their jobs by adding their key to WaaS. More info{" "}
                  <ExternalLink
                    display="inline"
                    href="https://help.dover.com/en/articles/6649755-connect-yc-work-at-a-startup-to-dover"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </ExternalLink>
                </>
              }
            >
              <HelpIcon />
            </Tooltip>
          )}
          {source.enrichedSourceDetails.candidateSource.adminOnly && <Chip label="Admin only" variant="Gray" />}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="flex-end">
          {sourceExternalLink && (
            // TODO: create a component for this in dover-ui
            <ExternalLink href={sourceExternalLink} target="__blank" rel="noopener noreferrer">
              <Stack direction="row" alignItems="flex-end" spacing={0.5} onClick={(e): void => e.stopPropagation()}>
                {/* TODO: add this icon to dover-ui */}
                <ExternalLinkSVG color="link" className="svg-fill" />
                <BodyExtraSmall color="inherit">View</BodyExtraSmall>
              </Stack>
            </ExternalLink>
          )}

          {isEditable && (
            <Stack spacing={1} justifyContent={"center"} height="100%">
              <Button variant="inline" onPress={onClick}>
                <BodyExtraSmall color={colors.grayscale.gray600}>
                  {hasBeenPreviouslyAdded ? "Manage" : "Learn more"}
                </BodyExtraSmall>
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
