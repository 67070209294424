import React from "react";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { RPUpsellBanner } from "components/library/RPUpsellBanner";

export const RecruiterMarketplaceSplash = (): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "agencies", "match_with_a_recruiter");

  return (
    <RPUpsellBanner
      variant="full"
      title="Do you need help with hiring?"
      description="Access elite recruiters with proven success at fast-growing startups."
      imageSrc="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-WcNcZY0tfnYW7OS4VUVGcuMwv7K4Uu.png"
      customButtonAction={(): void => {
        window.open(calendlyUrl, "_blank", "noopener noreferrer");
      }}
    />
  );
};
