/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Referrer
 */
export interface Referrer {
    /**
     * 
     * @type {string}
     * @memberof Referrer
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Referrer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Referrer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Referrer
     */
    readonly clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Referrer
     */
    readonly agencyName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Referrer
     */
    readonly hasDisabledJobReferrer?: boolean;
}

export function ReferrerFromJSON(json: any): Referrer {
    return ReferrerFromJSONTyped(json, false);
}

export function ReferrerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referrer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'email': json['email'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'agencyName': !exists(json, 'agency_name') ? undefined : json['agency_name'],
        'hasDisabledJobReferrer': !exists(json, 'has_disabled_job_referrer') ? undefined : json['has_disabled_job_referrer'],
    };
}

export function ReferrerToJSON(value?: Referrer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
    };
}


