import createAuth0Client from "@auth0/auth0-spa-js";
import { useSetAtom } from "jotai";
import React, { useContext, useEffect, useState } from "react";

import { DoverLoginSource, doverLoginSourceAtom } from "App/routing/atoms";
import { getOpenApiClients, setOpenApiAuth0Client, setToken } from "services/api";
import config from "services/auth_config";
import { getUrlParams } from "services/utils";
import { EXTENSION_ERROR_IGNORE_LIST } from "utils/constants";

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  /**
   * See [dover-django/config/auth/AUTH0_README.md](AUTH0_README.md) for the notes on our auth0 setup
   **/
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [failedToLogin, setFailedToLogin] = useState(false);
  const [loginFailureErrorCode, setLoginFailureErrorCode] = useState(false);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState();
  const [internalUser, setInternalUser] = useState();
  const [loginAppState, setLoginAppState] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [openApiClients, setOpenApiClients] = useState(null);

  const setDoverLoginSource = useSetAtom(doverLoginSourceAtom);

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("extension")) {
    setDoverLoginSource(DoverLoginSource.Extension);
  }

  useEffect(() => {
    if (internalUser) {
      // Set the user to the alias user if it exists
      let tmpUser = internalUser[config.aliasInfoUrl] ? internalUser[config.aliasInfoUrl] : internalUser;
      setUser(tmpUser);
    }
  }, [internalUser]);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("error")) {
        const [errorCode, email] = urlParams.get("error_description").split("|");
        setFailedToLogin(true);
        setLoginFailureErrorCode(errorCode);
        setEmail(email);
      }

      if (window.location.search.includes("code=")) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          setLoginAppState(appState);
          onRedirectCallback(appState);
        } catch (e) {
          console.log("Auth0 redirect error", e);
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setInternalUser(user);
        // fetch a token in the background
        // this uses an iframe a popup or refresh_tokens depending on config
        // https://auth0.github.io/auth0-spa-js/classes/Auth0Client.html#getTokenSilently
        // https://github.com/auth0/auth0-spa-js/blob/5ca5720/src/Auth0Client.ts#L603
        const token = await auth0FromHook.getTokenSilently();
        setToken(token);
        try {
          if (chrome?.runtime?.sendMessage) {
            // Send the token to the sourcing extension
            chrome.runtime
              .sendMessage(config.manuallySourceChromeExtensionId, { auth0token: token, type: "token" })
              .catch(e => {
                if (EXTENSION_ERROR_IGNORE_LIST.includes(e.message)) {
                  console.log(e);
                } else {
                  console.error(e);
                }
              });
            // Send the token to the admin extension
            chrome.runtime
              .sendMessage(config.adminChromeExtensionId, { auth0token: token, _type: "token" })
              .catch(e => console.log(e));

            // Also send the token to the testing sourcing extension
            chrome.runtime
              .sendMessage(config.manuallySourceChromeExtensionTestingId, {
                auth0token: token,
                type: "token",
              })
              .catch(e => console.log(e));
          }
        } catch (e) {
          console.log(e);
        }

        setOpenApiAuth0Client(auth0FromHook);
        const openApiClients = await getOpenApiClients({});
        setOpenApiClients(openApiClients);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setInternalUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setInternalUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        failedToLogin,
        loginFailureErrorCode,
        email,
        isAuthenticated,
        user,
        loginAppState,
        loading,
        popupOpen,
        openApiClients,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: ({ appState }) => {
          // If the appState is present, parse the href for query params
          const href = appState?.targetUrl?.href;
          if (href) {
            const params = getUrlParams(href);
            const utmMedium = params.get("utm_medium");
            const utmSource = params.get("utm_source");
            const utmCampaign = params.get("utm_campaign");
            const fullstoryCookie = params.get("fs_uid");

            // Conditionally add additional params if present in the URL
            if (utmMedium) {
              appState["utmMedium"] = utmMedium;
            }
            if (utmSource) {
              appState["utmSource"] = utmSource;
            }
            if (utmCampaign) {
              appState["utmCampaign"] = utmCampaign;
            }
            if (fullstoryCookie) {
              appState["fullstoryCookie"] = fullstoryCookie;
            }
          }

          auth0Client?.loginWithRedirect({ appState });
        },
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
