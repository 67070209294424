import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { LIST_AGENCIES, LIST_JOB_REFERRERS, LIST_REFERRERS } from "services/doverapi/endpointTagsConstants";
import {
  Agency,
  ApiApiCreateAgencyRecruiterRequest,
  ApiApiGetReferrerRequest,
  ApiApiInviteReferrersRequest,
  ApiApiListAgenciesRequest,
  ApiApiListJobReferrersRequest,
  ApiApiListReferrerJobsRequest,
  ApiApiListReferrersRequest,
  ApiApiPartialUpdateJobReferrerRequest,
  ApiApiUpdateJobAgencyReferrerRequest,
  CreateAgencyRecruiterResponse,
  InviteReferrerResponse,
  JobReferrer,
  Referrer,
  ReferrerJob,
  UpdateAgencyRecruiterResponse,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

interface ListReferrersResponse {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Referrer>;
}

export interface ListJobReferrersResponse {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<JobReferrer>;
}
interface ListAgenciesResponse {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: Array<Agency>;
}

const referralTabEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listAgencies: build.query<ListAgenciesResponse, ApiApiListAgenciesRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listAgencies(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to list agencies");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: LIST_AGENCIES }];
      },
    }),
    listReferrers: build.query<ListReferrersResponse, ApiApiListReferrersRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listReferrers(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to list referrers");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: LIST_REFERRERS }];
      },
    }),
    getReferrer: build.query<Referrer, ApiApiGetReferrerRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.getReferrer(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to get referrer");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listJobReferrers: build.query<ListJobReferrersResponse, ApiApiListJobReferrersRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listJobReferrers(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to list referrers");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (_, __, args) => {
        return [{ type: LIST_JOB_REFERRERS, id: args.jobId }];
      },
    }),
    listReferrerJobs: build.query<ReferrerJob[], ApiApiListReferrerJobsRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listReferrerJobs(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to list jobs");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    inviteReferrers: build.mutation<InviteReferrerResponse, ApiApiInviteReferrersRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.inviteReferrers(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to invite referrers");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (_, __, args) => [{ type: LIST_REFERRERS }, { type: LIST_JOB_REFERRERS, id: args.jobId }],
    }),
    createAgencyRecruiter: build.mutation<CreateAgencyRecruiterResponse, ApiApiCreateAgencyRecruiterRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.createAgencyRecruiter(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to add agency");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (_, __, args) => [{ type: LIST_REFERRERS }, { type: LIST_JOB_REFERRERS, id: args.jobId }],
    }),
    partialUpdateJobReferrer: build.mutation<JobReferrer, ApiApiPartialUpdateJobReferrerRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.partialUpdateJobReferrer(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update agency");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (_, __, args) => [{ type: LIST_JOB_REFERRERS, id: args.jobId }],
    }),
    updateAgencyRecruiter: build.mutation<UpdateAgencyRecruiterResponse, ApiApiUpdateJobAgencyReferrerRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.updateJobAgencyReferrer(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update job agency referrer");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (_, __, ___) => [{ type: LIST_JOB_REFERRERS }],
    }),
  }),
});

export const {
  useListReferrersQuery,
  useListJobReferrersQuery,
  useInviteReferrersMutation,
  useListAgenciesQuery,
  useCreateAgencyRecruiterMutation,
  useGetReferrerQuery,
  useListReferrerJobsQuery,
  usePartialUpdateJobReferrerMutation,
  useUpdateAgencyRecruiterMutation,
} = referralTabEndpoints;
