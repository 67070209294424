import React from "react";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as GreenCheck } from "assets/icons/simple-green-check.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { appConfig } from "config/appConfig";

interface RPUpsellBannerProps {
  variant?: "simple" | "full";
  title?: string;
  description?: string;
  buttonText?: string;
  customButtonAction?: () => void;
  imageSrc?: string;
}

const BannerContainer = styled.div<{ variant: "simple" | "full" }>`
  display: flex;
  align-items: center;
  justify-content: ${(props): string => (props.variant === "simple" ? "space-between" : "flex-start")};
  background: linear-gradient(to right, #ffffff, #f5f6ff);
  border-radius: 8px;
  padding: 12px 24px;
  width: 100%;
  border: 1px solid #eaecf0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }
`;

const ImageContainer = styled.div<{ src?: string }>`
  position: relative;
  width: 180px;
  height: 120px;
  flex-shrink: 0;
  margin-right: 24px;
  background-image: url(${(props): string => props.src || ""});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 768px) {
    width: 100%;
    height: 100px;
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

const ContentContainer = styled.div<{ variant: "simple" | "full" }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: ${(props): string => (props.variant === "simple" ? "0" : "16px")};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3<{ variant: "simple" | "full" }>`
  font-size: ${(props): string => (props.variant === "simple" ? "16px" : "18px")};
  font-weight: 600;
  color: #1d2939;
  margin: 0 0 4px 0;
  font-family: Inter, sans-serif;
`;

const Description = styled.p<{ variant: "simple" | "full" }>`
  font-size: 14px;
  color: #475467;
  margin: 0 0 ${(props): string => (props.variant === "simple" ? "0" : "16px")} 0;
  font-family: Inter, sans-serif;
`;

const ChecklistContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #475467;
  font-family: Inter, sans-serif;
`;

const StyledButton = styled(Button)<{ $variant: "simple" | "full" }>`
  height: ${(props): string => (props.$variant === "simple" ? "36px" : "32px")};
  padding: ${(props): string => (props.$variant === "simple" ? "8px 14px" : "8px 12px")};
  border-radius: 6px;
  background: transparent !important;
  color: #6172ff !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Inter, sans-serif;

  &:hover {
    background: rgba(97, 114, 255, 0.05) !important;
  }

  @media (max-width: 768px) {
    align-self: flex-start;
    width: auto;
  }
`;

const StyledGreenCheck = styled(GreenCheck)`
  width: 16px;
  height: 16px;
  color: #3ccb7f;
  flex-shrink: 0;
`;

const ButtonContainer = styled.div<{ variant: "simple" | "full" }>`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: ${(props): string => (props.variant === "simple" ? "flex-start" : "flex-start")};
  }
`;

export const RPUpsellBanner: React.FC<React.PropsWithChildren<RPUpsellBannerProps>> = ({
  variant = "full",
  title = "Do you need help with hiring?",
  description = "Access elite recruiters with proven success at fast-growing startups.",
  buttonText = "Explore Recruiters",
  customButtonAction,
  imageSrc,
}) => {
  const rpPageUrl = `${appConfig.appUrl}${APP_ROUTE_PATHS.recruitingPartners()}`;

  const handleButtonClick = (): void => {
    if (customButtonAction) {
      customButtonAction();
    } else {
      window.open(rpPageUrl, "_blank", "noopener noreferrer");
    }
  };

  const checklistItems = ["No minimums", "No commitments", "Pause whenever you need"];

  return (
    <BannerContainer variant={variant}>
      {variant === "full" && imageSrc && <ImageContainer src={imageSrc} />}
      <ContentContainer variant={variant}>
        <TextContainer>
          <Title variant={variant}>{title}</Title>
          <Description variant={variant}>{description}</Description>
        </TextContainer>
        {variant === "full" && (
          <ChecklistContainer>
            {checklistItems.map((item, index) => (
              <ChecklistItem key={index}>
                <StyledGreenCheck />
                <span>{item}</span>
              </ChecklistItem>
            ))}
          </ChecklistContainer>
        )}
      </ContentContainer>
      <ButtonContainer variant={variant}>
        <StyledButton variant={ButtonVariant.Ghost} onClick={handleButtonClick} $variant={variant}>
          {variant === "full" ? "Explore Recruiters" : buttonText}
        </StyledButton>
      </ButtonContainer>
    </BannerContainer>
  );
};
