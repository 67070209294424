import { Stack, Box, List, ListItemButton, Pagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import { ApplicationReview } from "services/openapi";
import ReviewItemTitle from "views/ApplicationsReviewer/ReviewItemTitle";

interface SelectableListProps {
  items: ApplicationReview[];
  onSelectItem: (item: ApplicationReview) => void;
  totalPages: number;
  onPageSelect: (page: number) => void;
  currentPage: number;
  selectedItem?: ApplicationReview;
}

const PaginatedSelectableList = ({
  items,
  onSelectItem,
  totalPages,
  onPageSelect,
  currentPage,
  selectedItem,
}: SelectableListProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <List
        sx={{
          width: "100%",
        }}
      >
        {items.map((item, i) => (
          <ListItemButton
            sx={{ width: "100%", padding: theme.spacing(2) }}
            key={`selectable-item-${i}`}
            selected={selectedItem?.id == item.id}
            onClick={(): void => {
              onSelectItem(item);
            }}
          >
            <ReviewItemTitle item={item} />
          </ListItemButton>
        ))}
      </List>
      {totalPages > 0 && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.default,
            padding: 1,
          }}
        >
          <Pagination
            size="small"
            count={totalPages}
            page={currentPage}
            onChange={(evt, page): void => {
              onPageSelect(page);
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default PaginatedSelectableList;
