import { TextField, CircularProgress, Chip, Autocomplete } from "@mui/material";
import debounce from "lodash/debounce";
import React, { useState, useMemo, ReactNode, ReactElement } from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

interface SimpleAutocompleteProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  getOptions: (name?: string) => Promise<string[]>;
  freeSolo?: boolean;
}

const MultipleAutocomplete = <T extends FieldValues>({
  control,
  name,
  getOptions,
  freeSolo,
}: SimpleAutocompleteProps<T>): ReactElement => {
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
    (async (): Promise<void> => {
      setLoading(true);
      const opts = await getOptions();
      opts.sort();
      setLoading(false);
      setOptions(opts);
    })();
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const fetchOptions = useMemo(
    () =>
      debounce(async (query?: string): Promise<void> => {
        setLoading(true);
        setOpen(true);
        try {
          const results = await getOptions(query);
          results.sort();
          setOptions(results);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        setLoading(false);
      }, 500),
    [getOptions]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): ReactElement => (
        <Autocomplete
          {...field}
          open={open}
          multiple
          limitTags={2}
          disableClearable
          disableCloseOnSelect
          onOpen={handleOpen}
          onClose={handleClose}
          freeSolo={freeSolo}
          options={options}
          loading={loading}
          value={field.value || []}
          renderTags={(value: readonly string[], getTagProps): ReactNode => {
            return value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
            }) as ReactNode;
          }}
          onInputChange={(_, newValue): void => {
            fetchOptions(newValue);
          }}
          onChange={(_, newValue): void => field.onChange(newValue)}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default MultipleAutocomplete;
