import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import React, { ReactElement } from "react";
import { useQueryParams } from "use-query-params";

import DataGridLoadingOverlay from "components/DataGridLoadingOverlay";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useListJobsQuery } from "services/doverapi/endpoints/job";
import { useGetNotificationConfigsQuery } from "services/doverapi/endpoints/notificationConfig";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { NotificationConfig, NotificationConfigNotifTypeEnum } from "services/openapi";
import { preferencesTableCols } from "views/CompanySetup/components/Notifications/components/Columns";
import {
  NotificationTableRow,
  weeklyNotifRows,
  interviewsNotifRows,
  appReviewRows,
  NotificationType,
  EnrichedNotificationTableRow,
} from "views/CompanySetup/components/Notifications/components/constants";
import { TableWrapper } from "views/CompanySetup/components/Notifications/styles";
import { queryParamConfig } from "views/CompanySetup/components/Notifications/types";

const Table = (): ReactElement => {
  const { notificationConfigsByJob, isLoading } = useGetNotificationConfigsQuery(undefined, {
    selectFromResult: ({ data: configs, isLoading }) => {
      const notificationConfigs: NotificationConfig[] = listAllEntities(configs);
      return {
        notificationConfigsByJob: groupBy<NotificationConfig>(
          notificationConfigs,
          (nc: NotificationConfig): string => nc.job
        ),
        isLoading,
      };
    },
  });

  const { isLoading: jobsLoading } = useListJobsQuery(undefined);
  const isBasePlan = useIsBasePlanCustomer();
  const initializing = isLoading || jobsLoading;
  const [query] = useQueryParams(queryParamConfig);

  const preferencesTableRows = React.useMemo<Partial<Record<NotificationType, NotificationTableRow[]>>>(() => {
    const jobNotificationConfigs = notificationConfigsByJob?.[query?.job ?? ""] ?? [];
    const configsByType = keyBy<NotificationConfig>(
      jobNotificationConfigs,
      (nc: NotificationConfig): string => nc.notifType
    );
    const generateRows = (notifRows: NotificationTableRow[]): EnrichedNotificationTableRow[] => {
      if (!isBasePlan) {
        notifRows = notifRows.filter((tr): any => tr.type !== NotificationConfigNotifTypeEnum.UnreviewedLeads);
      }
      return notifRows.map(
        (tr: NotificationTableRow): EnrichedNotificationTableRow => {
          return {
            ...tr,
            emailSetting: configsByType?.[tr.type]?.enabled ?? tr.defaultOn,
            original: configsByType?.[tr.type],
            job: query.job,
            type: tr.type,
          };
        }
      );
    };

    const allNotifRows: Partial<Record<NotificationType, NotificationTableRow[]>> = {
      [NotificationType.WeeklyNotifs]: generateRows(weeklyNotifRows),
      [NotificationType.InterviewsNotifs]: generateRows(interviewsNotifRows),
      [NotificationType.AppReviewNotifs]: generateRows(appReviewRows),
    };

    // Hide sourcing notifications for base plan users
    if (isBasePlan) {
      delete allNotifRows[NotificationType.SourcingAutopilot];
    }

    return allNotifRows;
  }, [query, isBasePlan, notificationConfigsByJob]);

  return (
    <TableWrapper>
      <Stack spacing={2}>
        {Object.keys(NotificationType).map((notifType: string) => {
          const rows = preferencesTableRows[notifType as NotificationType];
          if (!rows) return null;

          return (
            <DataGrid
              key={notifType}
              autoHeight
              headerHeight={35}
              rowHeight={72}
              rows={initializing ? [] : rows}
              columns={preferencesTableCols[notifType as NotificationType]}
              hideFooter
              disableColumnFilter
              disableSelectionOnClick
              disableColumnMenu
              loading={initializing}
              components={{
                LoadingOverlay: DataGridLoadingOverlay,
              }}
            />
          );
        })}
      </Stack>
    </TableWrapper>
  );
};

export default Table;
