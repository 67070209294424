import { z } from "zod";

export const ApplicationReviewSchema = z.object({
  id: z.string().optional(),
  annotatedTotalYoe: z.number().optional(),
  annotatedTitlesT2: z.array(z.string()),
  reviewed: z.boolean().optional(),
  annotatedLocation: z.string(),
  title: z
    .string()
    .optional()
    .nullable(),
});

export type ApplicationReviewSchemaType = z.infer<typeof ApplicationReviewSchema>;
