import { Stack } from "@mui/material";
import React, { FC } from "react";

import { LearnMoreLink } from "components/library/LearnMoreLink";
import { H2 } from "styles/typography/headers";
import { CompanySetupSectionConfig } from "views/CompanySetup/constants";

interface SectionWrapperProps {
  config: CompanySetupSectionConfig;
}

const SectionWrapper: FC<React.PropsWithChildren<SectionWrapperProps>> = ({ children, config }) => {
  return (
    <Stack maxWidth={"1400px"} width={"100%"} flexGrow={1} spacing={2}>
      {(config.sectionLabel || config.description) && (
        <Stack spacing={1}>
          {config.sectionLabel && (
            <Stack width="100%" justifyContent="space-between" direction="row">
              <H2>{config.sectionLabel}</H2>
            </Stack>
          )}
          {config.description && (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Stack width={config.sectionLabel === "Email Templates" ? "88%" : "100%"}>
                <LearnMoreLink linkUrl={config.helpDeskArticle} description={config.description} />
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
      {children}
    </Stack>
  );
};

export default SectionWrapper;
