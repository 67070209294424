/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HiringPipelineStageMilestone,
    HiringPipelineStageMilestoneFromJSON,
    HiringPipelineStageMilestoneFromJSONTyped,
    HiringPipelineStageMilestoneToJSON,
    HiringPipelineStageType,
    HiringPipelineStageTypeFromJSON,
    HiringPipelineStageTypeFromJSONTyped,
    HiringPipelineStageTypeToJSON,
    InterviewPlanMultipartInterviewStage,
    InterviewPlanMultipartInterviewStageFromJSON,
    InterviewPlanMultipartInterviewStageFromJSONTyped,
    InterviewPlanMultipartInterviewStageToJSON,
    SubstageType,
    SubstageTypeFromJSON,
    SubstageTypeFromJSONTyped,
    SubstageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface HiringPipelineStage
 */
export interface HiringPipelineStage {
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStage
     */
    state?: HiringPipelineStageStateEnum;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStage
     */
    setupState?: HiringPipelineStageSetupStateEnum;
    /**
     * 
     * @type {HiringPipelineStageType}
     * @memberof HiringPipelineStage
     */
    stageType: HiringPipelineStageType;
    /**
     * A relative ordering on active HiringPipelineStages.
     * @type {number}
     * @memberof HiringPipelineStage
     */
    orderIndex?: number | null;
    /**
     * 
     * @type {HiringPipelineStageMilestone}
     * @memberof HiringPipelineStage
     */
    milestone: HiringPipelineStageMilestone | null;
    /**
     * 
     * @type {InterviewPlanMultipartInterviewStage}
     * @memberof HiringPipelineStage
     */
    multipartInterviewStage?: InterviewPlanMultipartInterviewStage;
    /**
     * 
     * @type {SubstageType}
     * @memberof HiringPipelineStage
     */
    contentTypeName?: SubstageType;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStage
     */
    atsStageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStage
     */
    schedulingEmailTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStage
     */
    rejectionEmailTemplateId?: string;
    /**
     * 
     * @type {Date}
     * @memberof HiringPipelineStage
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HiringPipelineStage
     */
    readonly modified?: Date;
}

/**
* @export
* @enum {string}
*/
export enum HiringPipelineStageStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}/**
* @export
* @enum {string}
*/
export enum HiringPipelineStageSetupStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function HiringPipelineStageFromJSON(json: any): HiringPipelineStage {
    return HiringPipelineStageFromJSONTyped(json, false);
}

export function HiringPipelineStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiringPipelineStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'setupState': !exists(json, 'setup_state') ? undefined : json['setup_state'],
        'stageType': HiringPipelineStageTypeFromJSON(json['stage_type']),
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
        'milestone': HiringPipelineStageMilestoneFromJSON(json['milestone']),
        'multipartInterviewStage': !exists(json, 'multipart_interview_stage') ? undefined : InterviewPlanMultipartInterviewStageFromJSON(json['multipart_interview_stage']),
        'contentTypeName': !exists(json, 'content_type_name') ? undefined : SubstageTypeFromJSON(json['content_type_name']),
        'atsStageId': !exists(json, 'ats_stage_id') ? undefined : json['ats_stage_id'],
        'schedulingEmailTemplateId': !exists(json, 'scheduling_email_template_id') ? undefined : json['scheduling_email_template_id'],
        'rejectionEmailTemplateId': !exists(json, 'rejection_email_template_id') ? undefined : json['rejection_email_template_id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
    };
}

export function HiringPipelineStageToJSON(value?: HiringPipelineStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'state': value.state,
        'setup_state': value.setupState,
        'stage_type': HiringPipelineStageTypeToJSON(value.stageType),
        'order_index': value.orderIndex,
        'milestone': HiringPipelineStageMilestoneToJSON(value.milestone),
        'multipart_interview_stage': InterviewPlanMultipartInterviewStageToJSON(value.multipartInterviewStage),
        'content_type_name': SubstageTypeToJSON(value.contentTypeName),
        'ats_stage_id': value.atsStageId,
        'scheduling_email_template_id': value.schedulingEmailTemplateId,
        'rejection_email_template_id': value.rejectionEmailTemplateId,
    };
}


