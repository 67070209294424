import { Progress } from "@doverhq/dover-ui";
import { Stack, Tab, Tabs } from "@mui/material";
import React from "react";

import { Subtitle1 } from "components/library/typography";
import { ApplicationReview } from "services/openapi";
import PaginatedSelectableList from "views/ApplicationsReviewer/PaginatedSelectableList";

interface ApplicationBrowserProps {
  onItemSelected: (item: ApplicationReview, nextItem?: ApplicationReview) => void;
  currentItem?: ApplicationReview;
  data: ApplicationReview[];
  loading: boolean;
  onPageChange: (page: number) => void;
  page: number;
  showReviewed: boolean;
  setShowReviewed: (value: boolean) => void;
  pagesCount: number;
}

const ApplicationsBrowser = ({
  onItemSelected,
  data,
  loading,
  onPageChange,
  page,
  showReviewed,
  setShowReviewed,
  currentItem,
  pagesCount,
}: ApplicationBrowserProps): React.ReactElement => {
  const table = (
    <PaginatedSelectableList
      selectedItem={currentItem}
      currentPage={page}
      totalPages={pagesCount}
      onPageSelect={(page: number): void => {
        onPageChange(page);
      }}
      onSelectItem={(item: ApplicationReview): void => {
        onItemSelected(item);
      }}
      items={data}
    />
  );

  return (
    <Stack>
      <Tabs value={Number(showReviewed)} onChange={(): void => setShowReviewed(!showReviewed)}>
        <Tab label="To Do" id="todo-panel" />
        <Tab label="Reviewed" id="reviewed-panel" />
      </Tabs>
      <Stack
        sx={{
          minHeight: "200px",
        }}
      >
        {loading && <Progress text="" size="large" />}
        {!loading && data.length > 0 && table}
        {!loading && data.length == 0 && <Subtitle1>No data to display</Subtitle1>}
      </Stack>
    </Stack>
  );
};

export default ApplicationsBrowser;
