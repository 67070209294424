import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";

import { SourcingExtensionCard } from "components/dover/sourcing/SourcingExtensionCard";
import PageHelmet from "components/PageHelmet";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import SourcingPageCampaignSection from "views/job/JobSetup/steps/CampaignVariants/SourcingPageCampaignSection";

/* -----------------------------------------------------------------------------
 * JobSourcingPage
 * -------------------------------------------------------------------------- */

const JobSourcingPage = (): React.ReactElement => {
  const [jobId] = useJobId();
  const { data: job } = useGetJobQuery(jobId ?? skipToken);

  return (
    <Stack spacing={3} maxWidth="800px" margin="0 auto" pb={2}>
      <PageHelmet title={job?.title ? `${job.title} - Sourcing` : "Sourcing"} />
      <SourcingExtensionCard />
      <SourcingPageCampaignSection />
    </Stack>
  );
};

export default JobSourcingPage;
