const VIEWED_APPLICANTS_KEY = "viewedApplicants";
const DEFAULT_TTL_DAYS = 180;

const setLocalStorageItem = (key: string, ttlDays: number = DEFAULT_TTL_DAYS): void => {
  const expiry = Date.now() + ttlDays * 24 * 60 * 60 * 1000;
  localStorage.setItem(key, expiry.toString());
};

const getLocalStorageItem = (key: string): boolean => {
  const expiryStr = localStorage.getItem(key);
  if (!expiryStr) return false;

  try {
    const expiry = parseInt(expiryStr, 10);

    // Check if the item has expired
    if (Date.now() > expiry) {
      localStorage.removeItem(key);
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const markApplicantAsViewed = (applicantId: string): void => {
  if (!applicantId) return;
  try {
    const key = `${VIEWED_APPLICANTS_KEY}_${applicantId}`;
    setLocalStorageItem(key);
  } catch (e) {
    // Silently fail
  }
};

export const isApplicantViewed = (applicantId: string): boolean => {
  if (!applicantId) return false;
  try {
    const key = `${VIEWED_APPLICANTS_KEY}_${applicantId}`;
    return getLocalStorageItem(key);
  } catch (e) {
    return false;
  }
};
