import styled from "styled-components";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { filters } from "styles/theme";

interface SizeableSpinnerProps {
  fontSize: string;
  height: string;
  width: string;
}

export const SizeableSpinner = styled(DoverLoadingSpinner)<SizeableSpinnerProps>`
  font-size: ${({ fontSize }): string => fontSize};
  height: ${({ height }): string => height};
  width: ${({ height }): string => height};
`;

export const LoadingRelative = styled(DoverLoadingSpinner)`
  width: 3rem;
  height: 3rem;
  filter: ${filters.filterDarkGreen};
`;

const SpinLoading = styled(DoverLoadingSpinner)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
  filter: ${filters.filterDarkGreen};
  z-index: 1000;
`;

export default SpinLoading;
