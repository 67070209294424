import { useEffect, useState } from "react";

import { EXTENSION_ID, EXTENSION_ERROR_IGNORE_LIST } from "utils/constants";

export const useIsExtensionInstalled = (): boolean | undefined => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isExtensionInstalled !== undefined) {
      return;
    }

    (async (): Promise<void> => {
      try {
        if (chrome?.runtime?.sendMessage) {
          const resp = await chrome.runtime.sendMessage(EXTENSION_ID, { message: "is_installed" }).catch(e => {
            if (EXTENSION_ERROR_IGNORE_LIST.includes(e.message)) {
              console.log(e);
            } else {
              console.error(e);
            }
          });

          setIsExtensionInstalled(!!resp?.is_installed);
        } else {
          setIsExtensionInstalled(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [isExtensionInstalled]);

  return isExtensionInstalled;
};
