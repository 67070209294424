import { Button, AnchorTagNav, Scrollable } from "@doverhq/dover-ui";
import { AnchorTagNavItem } from "@doverhq/dover-ui/dist/types/AnchorTagNav";
import { ReactComponent as InfoIcon } from "@doverhq/dover-ui/icons/info.svg";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BooleanParam, useQueryParam } from "use-query-params";
import * as z from "zod";

import { ReactComponent as ExternalLinkIcon } from "assets/icons/blue-external-link.svg";
import { BlockNav } from "components/library/BlockNav";
import { Card } from "components/library/Card";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import PageHelmet from "components/PageHelmet";
import { useHasElevatedPermissions } from "components/RBAC";
import { AntSwitch } from "components/StyledMuiComponents";
import { useModal } from "GlobalOverlays/atoms";
import { useElementDimensions } from "hooks/useElementDimensions";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetDoverCareersPageSource } from "services/doverapi/cross-endpoint-hooks/useGetDoverOutboundCandidateSource";
import { useGetClientId, useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobSetupQuery, useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import {
  useGetJobDescriptionQuery,
  useUpdateJobDescriptionMutation,
} from "services/doverapi/endpoints/job-description/endpoints";
import { useSubmitJobCandidateSourceFormMutation } from "services/doverapi/endpoints/job-source-settings/endpoints";
import {
  DoverJobDescription,
  JobCandidateSourceSettingDesiredStateEnum,
  JobLocation,
  JobLocationLocationTypeEnum,
  JobSetup,
} from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast, toastOptions } from "utils/showToast";
import { RemotePolicies, SCROLLABLE_CONTAINER_WIDTH, VisaSupport } from "views/job/constants";
import EditableApplicationForm, {
  EditableApplicationFormRef,
} from "views/job/JobSetup/steps/JobPosting/components/EditableApplicationForm";
import { EmailsAndAutomationSection } from "views/job/JobSetup/steps/JobPosting/components/EmailsAndAutomationSection";
import { JobDescriptionSection } from "views/job/JobSetup/steps/JobPosting/components/JobDescriptionSection";
import { JobDetailsSection } from "views/job/JobSetup/steps/JobPosting/components/JobDetailsSection";
import { newJobUpsellModalAtom } from "views/job/JobSetup/steps/JobPosting/components/NewJobUpsellModal";

/* -----------------------------------------------------------------------------
 * constants
 * -------------------------------------------------------------------------- */

export const JOB_POSTING_SCROLL_MARGIN_TOP = "48px";
export const SCROLLABLE_CONTAINER_ID = "job-posting-scrollable-container";
export const DETAILS_SECTION_ID = "details-section";
export const DESCRIPTION_SECTION_ID = "description-section";
export const APPLICATION_FORM_SECTION_ID = "application-form-section";
export const EMAILS_AND_AUTOMATION_SECTION_ID = "emails-and-automation-section";
export const EMPTY_JD = "<p></p>";
export const EMPTY_JD_REGEX = /^(<\w+>\s*<\/\w+>)*$/gim;

/* -----------------------------------------------------------------------------
 * form schemas
 * -------------------------------------------------------------------------- */

const locationOptionSchema = z.object({ displayName: z.string(), locationOption: z.string() });
export type LocationOptionSchema = z.infer<typeof locationOptionSchema>;

const detailsFormSchema = z
  .object({
    remotePolicy: z.nativeEnum(RemotePolicies),
    currencyCode: z.string(),
    compLowerBound: z
      .number()
      .min(1000)
      .optional()
      .nullable(),
    compUpperBound: z
      .number()
      .min(1000)
      .optional()
      .nullable(),
    openToSharingComp: z.boolean().optional(),
    supportVisas: z.nativeEnum(VisaSupport).optional(),
    remoteRegions: z.array(locationOptionSchema),
    onsiteCities: z.array(locationOptionSchema),
    wfhAllowed: z.boolean().optional(),
    openToSharingEquity: z.boolean().optional(),
    equityLowerBound: z
      .number()
      .min(0)
      .max(100, { message: "Total equity percentage cannot exceed 100%. Please enter a range between 0-100%." })
      .optional()
      .nullable(),
    equityUpperBound: z
      .number()
      .min(0)
      .max(100, { message: "Total equity percentage cannot exceed 100%. Please enter a range between 0-100%." })
      .optional()
      .nullable(),
  })
  .refine(data => !data.compLowerBound || !data.compUpperBound || data.compLowerBound <= data.compUpperBound, {
    path: ["compLowerBound"],
    message: "Lower bound must be less than or equal to upper bound",
  })
  .refine(data => !(data.remotePolicy === RemotePolicies.Both && data.remoteRegions.length === 0), {
    path: ["remoteRegions"],
    message: "Must select at least one location",
  })
  .refine(data => !(data.remotePolicy === RemotePolicies.Both && data.onsiteCities.length === 0), {
    path: ["onsiteCities"],
    message: "Must select at least one location",
  })
  .refine(data => !(data.remotePolicy === RemotePolicies.InOffice && data.onsiteCities.length === 0), {
    path: ["onsiteCities"],
    message: "Must select at least one location",
  })
  .refine(data => !(data.remotePolicy === RemotePolicies.Remote && data.remoteRegions.length === 0), {
    path: ["remoteRegions"],
    message: "Must select at least one location",
  })
  .refine(data => !data.equityLowerBound || !data.equityUpperBound || data.equityLowerBound <= data.equityUpperBound, {
    path: ["equityLowerBound"],
    message: "The equity range should go from lowest to highest. Please adjust your range.",
  })
  .refine(data => !(data.equityLowerBound && (!data.equityUpperBound || data.equityUpperBound === 0)), {
    path: ["equityUpperBound"],
    message: "You must specify a valid upper bound if a lower bound is provided",
  });
export type DetailsFormSchema = z.infer<typeof detailsFormSchema>;

const descriptionFormSchema = z.object({
  jobDescription: z.string().optional(),
});
export type DescriptionFormSchema = z.infer<typeof descriptionFormSchema>;

const emailsAndAutomationSchema = z.object({
  applicationReceived: z.boolean().optional(),
  rejection: z.boolean().optional(),
  scheduling: z.boolean().optional(),
});
export type EmailsAndAutomationSchema = z.infer<typeof emailsAndAutomationSchema>;

/* -----------------------------------------------------------------------------
 * JobPostingForm
 * -------------------------------------------------------------------------- */

interface JobPostingFormProps {
  job?: JobSetup;
  jobDescription?: DoverJobDescription;
  initialRemotePolicy?: RemotePolicies;
  initialOnsiteLocations?: JobLocation[];
  initialRemoteRegions?: JobLocation[];
  initialVisaSupport?: VisaSupport;
  initialJobDescription?: string;
}

const JobPostingForm = ({
  job,
  jobDescription,
  initialRemotePolicy,
  initialOnsiteLocations,
  initialRemoteRegions,
  initialVisaSupport,
  initialJobDescription,
}: JobPostingFormProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const locationRef = useRef<HTMLDivElement>(null);
  const jobDescriptionRef = useRef<HTMLDivElement>(null);
  const applicationFormRef = useRef<EditableApplicationFormRef>(null);
  const clientId = useGetClientId();
  const isOnFreePlan = useIsFreeCustomer();
  const jobId = job?.id;
  const jobHasHybridLocations = job?.locations?.some(
    location => location.locationType === JobLocationLocationTypeEnum.Hybrid
  );
  const { width: containerWidth } = useElementDimensions(containerRef);

  const hasElevatedPermissions = useHasElevatedPermissions(job?.id);
  const { data: doverCareersPageSource } = useGetDoverCareersPageSource();
  const [publishToCareersPage, setPublishToCareersPage] = useState(job?.isPublished ?? true);

  const formattedInitialRemoteRegions = initialRemoteRegions
    ?.filter(location => !!location.locationOption!.id)
    .map(location => ({
      displayName: location.name,
      locationOption: location.locationOption!.id!,
    }));
  const formattedInitialOnsiteCities = initialOnsiteLocations?.map(location => ({
    displayName: location.name,
    locationOption: location.locationOption!.id,
  }));
  const detailsFormMethods = useForm<DetailsFormSchema>({
    resolver: zodResolver(detailsFormSchema),
    defaultValues: {
      remotePolicy: initialRemotePolicy,
      remoteRegions: formattedInitialRemoteRegions,
      onsiteCities: formattedInitialOnsiteCities,
      supportVisas: initialVisaSupport,
      wfhAllowed: jobHasHybridLocations ?? false,
      currencyCode: job?.compensation?.currencyCode ?? "USD",
      compLowerBound: job?.compensation?.lowerBound ?? null,
      compUpperBound: job?.compensation?.upperBound ?? null,
      openToSharingComp: job?.compensation?.openToSharingComp ?? true,
      openToSharingEquity: job?.compensation?.openToSharingEquity ?? true,
      equityLowerBound: job?.compensation?.equityLowerBound ?? null,
      equityUpperBound: job?.compensation?.equityUpperBound ?? null,
    },
  });
  const descriptionFormMethods = useForm<DescriptionFormSchema>({
    resolver: zodResolver(descriptionFormSchema),
    defaultValues: {
      jobDescription: initialJobDescription,
    },
  });
  const emailsAndAutomationFormMethods = useForm<EmailsAndAutomationSchema>({
    resolver: zodResolver(emailsAndAutomationSchema),
    defaultValues: {
      applicationReceived: job?.shouldSendApplicationConfirmationEmails ?? false,
      rejection: job?.shouldSendInboundRejectionEmails ?? false,
      scheduling: isOnFreePlan ? false : true,
    },
  });

  // NOTE: The isDirty state of the forms was not working properly so to work around the
  // issue, we are calculating the dirty state ourselves
  const isDetailsDirty =
    detailsFormMethods.watch("remotePolicy") !== initialRemotePolicy ||
    detailsFormMethods.watch("remoteRegions")?.length !== formattedInitialRemoteRegions?.length ||
    detailsFormMethods.watch("onsiteCities")?.length !== formattedInitialOnsiteCities?.length ||
    detailsFormMethods.watch("supportVisas") !== initialVisaSupport ||
    detailsFormMethods.formState.isDirty;
  const isDescriptionDirty = descriptionFormMethods.watch("jobDescription") !== initialJobDescription;
  const isEmailsAndAutomationDirty =
    emailsAndAutomationFormMethods.watch("applicationReceived") !== job?.shouldSendApplicationConfirmationEmails ||
    emailsAndAutomationFormMethods.watch("rejection") !== job?.shouldSendInboundRejectionEmails;

  const [isApplicationFormDirty, setIsApplicationFormDirty] = useState(false);
  const isDirty = isDetailsDirty || isDescriptionDirty || isEmailsAndAutomationDirty || isApplicationFormDirty;

  const hasLocationError =
    !!detailsFormMethods.formState.errors.remotePolicy ||
    !!detailsFormMethods.formState.errors.onsiteCities ||
    !!detailsFormMethods.formState.errors.remoteRegions ||
    (!detailsFormMethods.getValues("onsiteCities")?.length && !detailsFormMethods.getValues("remoteRegions")?.length);
  const hasJobDescriptionError =
    !descriptionFormMethods.getValues("jobDescription") ||
    descriptionFormMethods.getValues("jobDescription") === EMPTY_JD ||
    !!descriptionFormMethods.getValues("jobDescription")?.match(EMPTY_JD_REGEX);

  const [updateJobSetup] = useUpdateJobSetupMutation();
  const [updateJobDescription] = useUpdateJobDescriptionMutation({
    fixedCacheKey: "updateJobDescription",
  });
  const [submitJobCandidateSourceForm] = useSubmitJobCandidateSourceFormMutation();

  const [isSaving, setIsSaving] = useState(false);

  const handleSaveDetails = async (data: DetailsFormSchema): Promise<void> => {
    const cityLocationType = data.wfhAllowed
      ? JobLocationLocationTypeEnum.Hybrid
      : JobLocationLocationTypeEnum.InOffice;
    const cityLocations = data?.onsiteCities?.map(city => ({
      name: city.displayName,
      locationOptionId: city.locationOption,
      locationType: cityLocationType,
    }));
    const remoteLocations = data?.remoteRegions?.map(region => ({
      name: region.displayName,
      locationOptionId: region.locationOption,
      locationType: JobLocationLocationTypeEnum.Remote,
    }));

    const locations = [
      ...([RemotePolicies.Remote, RemotePolicies.Both].includes(data.remotePolicy) ? remoteLocations : []),
      ...([RemotePolicies.InOffice, RemotePolicies.Both].includes(data.remotePolicy) ? cityLocations : []),
    ];

    await updateJobSetup({
      id: jobId!,
      visaSupport: data.supportVisas === VisaSupport.Yes,
      compensation: {
        lowerBound: data.compLowerBound,
        upperBound: data.compUpperBound,
        currencyCode: data.currencyCode,
        openToSharingComp: data.openToSharingComp ?? false,
        equityLowerBound: data.equityLowerBound,
        equityUpperBound: data.equityUpperBound,
        openToSharingEquity: !!data.openToSharingEquity,
      },
      locations,
    }).unwrap();
  };

  const handleSaveDescription = async (data: DescriptionFormSchema): Promise<void> => {
    if (data.jobDescription) {
      await updateJobDescription({
        id: jobDescription?.id!,
        updatedJobDescription: {
          userProvidedDescription: data.jobDescription,
          isPublished: true,
          useDoverJd: false,
        },
        showToast: false,
      }).unwrap();
    }
  };

  const handleSaveEmailsAndAutomation = async (data: EmailsAndAutomationSchema): Promise<void> => {
    await updateJobSetup({
      id: jobId!,
      shouldSendApplicationConfirmationEmails: data.applicationReceived,
      shouldSendInboundRejectionEmails: data.rejection,
    }).unwrap();
  };

  const handlePublishToCareersPage = async (): Promise<void> => {
    if (!hasElevatedPermissions || !job?.id || !doverCareersPageSource?.id) {
      return Promise.resolve();
    }
    // default state of `publishToCareersPage` is true
    // - for new jobs with publish=False, this will publish if true and no-op for false. (We're optimizing for the true case here)
    // - for existing jobs, this will publish if true and unpublish if false

    await submitJobCandidateSourceForm({
      jobId: job.id,
      candidateSourceId: doverCareersPageSource.id,
      desiredState: publishToCareersPage
        ? JobCandidateSourceSettingDesiredStateEnum.Active
        : JobCandidateSourceSettingDesiredStateEnum.Inactive,
    }).unwrap();
  };

  const handleSave = async (): Promise<void> => {
    setIsSaving(true);
    try {
      const isDetailsValid = await detailsFormMethods.trigger();
      if (!isDetailsValid) {
        setIsSaving(false);
        return;
      }

      applicationFormRef.current?.saveApplicationQuestions(false);
      const p1 = handleSaveDetails(detailsFormMethods.getValues());
      const p2 = handleSaveDescription(descriptionFormMethods.getValues());
      const p3 = handleSaveEmailsAndAutomation(emailsAndAutomationFormMethods.getValues());
      const p4 = handlePublishToCareersPage();

      toast.promise(
        Promise.all([p1, p2, p3, p4]),
        {
          pending: "Saving...",
          success: "Saved!",
          error: "Failed to save job posting.",
        },
        { ...toastOptions }
      );
    } catch (_) {
      showErrorToast("We encountered an error saving the job details, please try again.");
    }
    setIsSaving(false);
  };

  const anchorTagNavItems: AnchorTagNavItem[] = [
    {
      label: "Job Description",
      href: `#${DESCRIPTION_SECTION_ID}`,
      icon: hasJobDescriptionError
        ? {
            Icon: InfoIcon,
          }
        : undefined,
    },
    {
      label: "Job Details",
      href: `#${DETAILS_SECTION_ID}`,
      icon: hasLocationError
        ? {
            Icon: InfoIcon,
          }
        : undefined,
    },
    {
      label: "Application Form",
      href: `#${APPLICATION_FORM_SECTION_ID}`,
    },
    {
      label: "Automation",
      href: `#${EMAILS_AND_AUTOMATION_SECTION_ID}`,
    },
  ];

  return (
    // NOTE: the padding here should be removed if this view gets added as a route because
    // then it will be wrapped by the layout component with built in padding
    <Stack
      px={3}
      py={2.5}
      direction="row"
      width="100%"
      spacing={2}
      height="calc(100vh - 98px)"
      position="relative"
      justifyContent="center"
    >
      <Box position="sticky" top={0} minWidth="170px" display={{ xs: "none", sm: "block" }}>
        <AnchorTagNav items={anchorTagNavItems} scrollableContainerId={SCROLLABLE_CONTAINER_ID} />
        <Stack width="100%" height="1px" bgcolor={colors.grayscale.gray200} />
        <Link to={`/apply/${clientId}/${jobId}/?rs=15190316`} target="_blank" rel="noopener noreferrer">
          <Stack direction="row" alignItems="center" pl={1} pt={1} spacing={1}>
            <ExternalLinkIcon />
            <BodySmall color={colors.linkLight}>Preview</BodySmall>
          </Stack>
        </Link>
      </Box>
      <Stack spacing={1} pt={1.5} ref={containerRef} width="100%" maxWidth={SCROLLABLE_CONTAINER_WIDTH}>
        <Scrollable id={SCROLLABLE_CONTAINER_ID} height="100%">
          <Stack spacing={1.5} pb="62px">
            <FormProvider {...descriptionFormMethods}>
              <JobDescriptionSection
                jobDescriptionRef={jobDescriptionRef}
                id={DESCRIPTION_SECTION_ID}
                scrollableContainerId={SCROLLABLE_CONTAINER_ID}
              />
            </FormProvider>
            <FormProvider {...detailsFormMethods}>
              <JobDetailsSection
                locationRef={locationRef}
                id={DETAILS_SECTION_ID}
                formattedInitialRemoteRegions={formattedInitialRemoteRegions}
                hasLocationError={hasLocationError}
              />
            </FormProvider>
            <EditableApplicationForm
              enableSave={false}
              ref={applicationFormRef}
              id={APPLICATION_FORM_SECTION_ID}
              onSetFormDirty={setIsApplicationFormDirty}
            />
            <FormProvider {...emailsAndAutomationFormMethods}>
              <EmailsAndAutomationSection id={EMAILS_AND_AUTOMATION_SECTION_ID} />
            </FormProvider>
            <Box position="fixed" bottom={0} width={containerWidth}>
              <Card padding="8px">
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems={"center"} justifyContent={"center"} spacing={1}>
                    <Tooltip
                      title={
                        job?.isSample
                          ? "Cannot publish sample jobs to careers page"
                          : hasElevatedPermissions
                          ? undefined
                          : "You do not have permission to publish/unpublish this job"
                      }
                    >
                      <AntSwitch
                        checked={publishToCareersPage}
                        onChange={(_, checked): void => {
                          setPublishToCareersPage(checked);
                        }}
                        disabled={!hasElevatedPermissions}
                      />
                    </Tooltip>
                    <BodySmall>Publish to Careers Page</BodySmall>
                  </Stack>

                  <Button variant="primaryFilled" onPress={handleSave} isDisabled={isSaving} isLoading={isSaving}>
                    Save
                  </Button>
                </Stack>
              </Card>
            </Box>
          </Stack>
        </Scrollable>
      </Stack>
      <BlockNav when={isDirty} />
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * JobPosting
 * -------------------------------------------------------------------------- */

interface JobDetailProps {
  initialRemoteRegions?: JobLocation[];
  initialOnsiteLocations?: JobLocation[];
  initialRemotePolicy: RemotePolicies;
  initialVisaSupport: VisaSupport;
}

export const getJobDetailProps = (job?: JobSetup): JobDetailProps => {
  const initialRemoteRegions = job?.locations
    ?.filter(location => location.locationType === JobLocationLocationTypeEnum.Remote)
    .filter(location => !!location.locationOption?.id);

  const initialOnsiteLocations = job?.locations
    ?.filter(
      location =>
        location.locationType === JobLocationLocationTypeEnum.InOffice ||
        location.locationType === JobLocationLocationTypeEnum.Hybrid
    )
    .filter(location => !!location.locationOption?.id);

  const initialRemotePolicy = initialRemoteRegions?.length
    ? initialOnsiteLocations?.length
      ? RemotePolicies.Both
      : RemotePolicies.Remote
    : RemotePolicies.InOffice;

  const initialVisaSupport = job?.visaSupport ? VisaSupport.Yes : VisaSupport.No;

  return {
    initialRemoteRegions,
    initialOnsiteLocations,
    initialRemotePolicy,
    initialVisaSupport,
  };
};

export const JobPosting = (): ReactElement => {
  const jobId = useJobIdFromUrl();
  const { data: job, isLoading: isLoadingJobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  const { data: existingJobDescription, isLoading: isLoadingJobDescription } = useGetJobDescriptionQuery(
    jobId ? { jobId } : skipToken
  );

  const [isNewJob] = useQueryParam("isNewJob", BooleanParam);

  const { open } = useModal(newJobUpsellModalAtom);

  const { initialRemoteRegions, initialOnsiteLocations, initialRemotePolicy, initialVisaSupport } = getJobDetailProps(
    job
  );

  // TODO: should use a skeleton loading state of the four sections
  if (isLoadingJobSetup || isLoadingJobDescription) return <DoverLoadingSpinner />;

  if (isNewJob && !job?.recruiter?.isRecruiter) {
    open();
  }

  return (
    <>
      <PageHelmet title={job?.title ? `${job.title} - Job Posting` : "Job Posting"} />
      <JobPostingForm
        job={job}
        jobDescription={existingJobDescription}
        initialRemotePolicy={initialRemotePolicy}
        initialRemoteRegions={initialRemoteRegions}
        initialOnsiteLocations={initialOnsiteLocations}
        initialVisaSupport={initialVisaSupport}
        initialJobDescription={existingJobDescription?.userProvidedDescription ?? undefined}
      />
    </>
  );
};
