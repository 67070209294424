import React from "react";

import { ReactComponent as GmailSVG } from "assets/icons/gmail.svg";
import { ReactComponent as GoogleCalendarSVG } from "assets/icons/google-calendar.svg";
import { ReactComponent as GoogleSVG } from "assets/icons/google.svg";
import { ReactComponent as MicrosoftCalendarSVG } from "assets/icons/microsoft-calendar.svg";
import { ReactComponent as MicrosoftSVG } from "assets/icons/microsoft.svg";
import { ReactComponent as OutlookSVG } from "assets/icons/outlook.svg";
import ConnectGmailButton from "components/dover/OnlineAccounts/ConnectGmailButton";
import ConnectGoogleCalendarButton from "components/dover/OnlineAccounts/ConnectGoogleCalendarButton";
import ConnectMicrosoftButton from "components/dover/OnlineAccounts/ConnectMicrosoftButton";
import { OnlineAccountProviderConfig, OnlineAccountProviderEnum } from "components/dover/OnlineAccounts/types";

export const ONLINE_ACCOUNT_PROVIDER_CONFIGS: OnlineAccountProviderConfig[] = [
  {
    type: OnlineAccountProviderEnum.Google,
    name: "Google",
    icon: <GoogleSVG width="14px" height="14px" />,
    emailConnectButton: <ConnectGmailButton />,
    calendarConnectButton: <ConnectGoogleCalendarButton />,
    emailIcon: <GmailSVG />,
    calendarIcon: <GoogleCalendarSVG />,
  },
  {
    type: OnlineAccountProviderEnum.Microsoft,
    name: "Microsoft",
    icon: <MicrosoftSVG width="14px" height="14px" />,
    emailIcon: <OutlookSVG />,
    calendarIcon: <MicrosoftCalendarSVG />,
    emailConnectButton: <ConnectMicrosoftButton />,
    calendarConnectButton: <ConnectMicrosoftButton />,
  },
];
