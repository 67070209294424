import { Typography, Stack } from "@mui/material";
import moment from "moment";
import React from "react";

import { Subtitle1 } from "components/library/typography";
import { ApplicationReview } from "services/openapi";

interface ReviewItemProps {
  item: ApplicationReview;
}

const ReviewItemTitle: React.FC<ReviewItemProps> = ({ item }) => {
  return (
    <Stack>
      <Subtitle1>{item.title}</Subtitle1>
      {item.userName && (
        <Typography variant="body1">
          Reviewed by {item.userName} {item.modified ? moment(item.modified).fromNow() : ""}
        </Typography>
      )}
    </Stack>
  );
};

export default ReviewItemTitle;
