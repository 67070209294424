import { Box, Stack } from "@mui/material";
import React from "react";

import { ONLINE_ACCOUNT_PROVIDER_CONFIGS } from "components/dover/OnlineAccounts/constants";
import { Body, BodySmall, Subtitle1 } from "components/library/typography";
import { Spacer } from "components/Spacer";

export const ConnectCalendarCard: React.FC<React.PropsWithChildren<any>> = () => {
  return (
    <Box>
      <Body weight="500">Provide your availability</Body>
      <Spacer height="48px" />
      <Stack sx={{ alignItems: "center", justifyContent: "center" }} spacing={3}>
        <Stack sx={{ alignItems: "center", justifyContent: "center" }} spacing={2}>
          <Stack direction="row" spacing={1}>
            {ONLINE_ACCOUNT_PROVIDER_CONFIGS.map(oap => (
              <Box sx={{ width: "32px", height: "32px" }} key={`email-icon-${oap.name}`}>
                {oap.calendarIcon}
              </Box>
            ))}
          </Stack>
          <Subtitle1>Connect your calendar</Subtitle1>
          <BodySmall>Dover will create calendar events for candidate interviews.</BodySmall>
        </Stack>
        <Stack direction="row" spacing={1}>
          {ONLINE_ACCOUNT_PROVIDER_CONFIGS.map(oap => oap.calendarConnectButton)}
        </Stack>
      </Stack>
    </Box>
  );
};
