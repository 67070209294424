import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as SortIcon } from "assets/icons/sort.svg";
import { AskConciergeButton } from "components/AskConciergeButton";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Subtitle1, Subtitle2 } from "components/library/typography";
import { SAMPLE_CANDS_DISABLED_TOOLTIP, ScheduleRejectButtons } from "components/ScheduleRejectButtons";
import { selectCurrentCandidateId } from "domains/candidate/selectors";
import { useDisableAtsControls } from "hooks/useDisableAtsControls";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { useDoverManagedSchedulingConfig } from "services/doverapi/cross-endpoint-hooks/useGetSchedulingConfig";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import {
  CandidateBio,
  CandidateBioSchedulingOwnershipEnum,
  CandidateBioStatusEnum,
  JobFeatureFeatureNameEnum,
  NextActionSchedulingStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { IllHandleButton } from "views/CandidateDetail/components/next-action/IllHandleButton";
import { SnoozeButton } from "views/CandidateDetail/components/next-action/SnoozeButton";
import { ArchiveBanner } from "views/CandidateDetail/components/StageAndStatusCard/ArchiveBanner";
import { RelatedCandidatesList } from "views/CandidateDetail/components/StageAndStatusCard/RelatedCandidatesList";
import { StageSelect } from "views/CandidateDetail/components/StageAndStatusCard/StageSelect";
import { StatusSelect } from "views/CandidateDetail/components/StageAndStatusCard/StatusSelect";
import { Timeline } from "views/CandidateDetail/components/Timeline";

// We allow passing in the candidate bio for the application review page.
// On that page we fetch the candidate bio with an application id instead of
// a candidate id sometimes. This causes rtkq to treat them as seperate caches
// and was causing us to double call the route and wait for it to load twice.
// So instead we pass in the bio directly.
export const StageAndStatusCardV2 = ({
  candidateBioFromProp,
}: {
  candidateBioFromProp?: CandidateBio;
}): React.ReactElement => {
  const candidateId = useSelector(selectCurrentCandidateId);
  const { showMoveJobModal } = useTopLevelModalManagerOptions();

  const { data: candidateBioFromApi, isLoading: loadingCandidateBio } = useGetCandidateBioQuery(
    candidateBioFromProp ? skipToken : candidateId ? candidateId : skipToken
  );

  const candidateBio = candidateBioFromProp ?? candidateBioFromApi;
  const jobId = candidateBio?.job;

  const disableAtsControls = useDisableAtsControls(candidateBio?.id);
  const isE2ESchedulingEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  const invalidCandidateActionPermsTooltip = useDisableCandidateActionsTooltipText(jobId);
  const invalidCandidateActionPerms = !!invalidCandidateActionPermsTooltip;

  const isDemoInboundCand = candidateBio?.isDemoInboundCand;
  const disabled = invalidCandidateActionPerms || disableAtsControls || isDemoInboundCand;

  const clickMoveJob = useCallback(() => {
    if (disabled || !candidateBio?.id) {
      return;
    }

    showMoveJobModal({ candidateId: candidateBio.id });
  }, [candidateBio?.id, disabled, showMoveJobModal]);

  // Figure out if we need to show the ask concierge to schedule button
  const hasDoverManagedScheduling = useDoverManagedSchedulingConfig(candidateBio?.job);
  const clientHandlesScheduling =
    candidateBio?.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling;
  const doverHandlesScheduling =
    candidateBio?.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling;

  const inClientReviewOrCallCompleted =
    candidateBio?.nextAction?.schedulingState === NextActionSchedulingStateEnum.InitialReview ||
    candidateBio?.nextAction?.schedulingState === NextActionSchedulingStateEnum.CallCompleted;
  const showAskConciergeButton = hasDoverManagedScheduling && clientHandlesScheduling && inClientReviewOrCallCompleted;
  const showIllHandleButton = isE2ESchedulingEnabled && doverHandlesScheduling;

  if (loadingCandidateBio || !candidateBio) {
    return <></>;
  }
  const showArchiveBanner = !!candidateBio.status;
  const disabledTooltip = isDemoInboundCand
    ? SAMPLE_CANDS_DISABLED_TOOLTIP
    : disableAtsControls
    ? `Contact your Recruiting Partner to make changes or select "I'll handle from here"`
    : invalidCandidateActionPermsTooltip;
  return (
    <Box p="12px" border={`1px solid ${colors.grayscale.gray200}`} borderRadius="4px" bgcolor={colors.white}>
      <Stack spacing={1.5}>
        <Stack spacing={0.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Subtitle1>
              {candidateBio.job ? (
                <JobLink to={`/job/${candidateBio.job}/setup`} target="_blank" rel="noopener noreferrer">
                  {candidateBio.jobTitle}
                </JobLink>
              ) : (
                <>{candidateBio.jobTitle}</>
              )}
            </Subtitle1>
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              onClick={clickMoveJob}
              sx={{ cursor: disabled ? "default" : "pointer" }}
            >
              <SortIcon />
              <Tooltip title={invalidCandidateActionPermsTooltip ?? ""}>
                <BodySmall color={disabled ? colors.grayscale.gray400 : "inherit"}>Move</BodySmall>
              </Tooltip>
            </Stack>
          </Stack>
          {showArchiveBanner ? (
            <ArchiveBanner candidateId={candidateBio.id} archiveReason={candidateBio.archiveReason} />
          ) : (
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <ScheduleRejectButtons
                  candidateId={candidateBio.id}
                  disabled={disabled}
                  disabledTooltip={disabledTooltip}
                  scheduleBtnLabel="Advance"
                />
                <SnoozeButton candidateId={candidateId} disabled={disabled} disabledTooltip={disabledTooltip} />
              </Stack>
              {showIllHandleButton && (
                <Box display="flex" flexDirection="row">
                  <IllHandleButton bio={candidateBio} />
                </Box>
              )}
              {showAskConciergeButton && <AskConciergeButton bio={candidateBio} disabled={disabled} />}
            </Stack>
          )}
        </Stack>
        <Stack spacing={0.5}>
          <Subtitle2>Stage</Subtitle2>
          {candidateBio.id && <StageSelect key={candidateBio.id} candidateId={candidateBio.id} />}
          {candidateBio.status !== CandidateBioStatusEnum.Hired && <Timeline candidateBio={candidateBio} />}
        </Stack>
        <Stack spacing={0.5}>
          <Subtitle2>Status</Subtitle2>
          <StatusSelect key={candidateBio.id} candidateBio={candidateBio} />
        </Stack>
        <RelatedCandidatesList contactId={candidateBio.contact?.id} candidateId={candidateBio.id} />
      </Stack>
    </Box>
  );
};

const JobLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    color: ${colors.informational.base};
  }
`;
