import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ReactComponent as GoogleSVG } from "assets/icons/google.svg";
import { ReactComponent as GoogleAuthDetailsSVG } from "assets/images/google-auth-details.svg";
import ConnectButton from "components/dover/OnlineAccounts/ConnectButton";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import Modal from "components/Modal";
import { Spacer } from "components/Spacer";
import { appConfig } from "config/appConfig";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useRevokeCalendarAuthMutation } from "services/doverapi/endpoints/interviewer";
import { useGetUserInterviewerSchedulingInfoQuery } from "services/doverapi/endpoints/proUser";

const ConnectGoogleCalendarButton = (): React.ReactElement => {
  const [pollingInterval, setPollingInterval] = useState<number>(3000);
  const [authed, setAuthed] = useState<boolean>(false);
  const { data, isLoading } = useGetUserInterviewerSchedulingInfoQuery(undefined, {
    pollingInterval: pollingInterval,
  });

  useEffect(() => {
    setAuthed(!!data?.calendarConnected);
    setPollingInterval(data?.calendarConnected ? 0 : 3000);
  }, [data?.calendarConnected]);

  const [revokeCall, { isLoading: isRevoking }] = useRevokeCalendarAuthMutation();

  const [openModal, setOpenModal] = useState(false);

  const click = async (): Promise<void> => {
    if (!authed) {
      setOpenModal(true);
      return;
    }

    if (data?.id) await revokeCall(data.id).unwrap();
  };

  const useMinimalCalAuth = useFeatureFlag(FeatureFlag.MinimalCalAuth);

  const calAuthUrl = useMinimalCalAuth ? `${appConfig.legacyAppUrl}/cal/auth-v2` : `${appConfig.legacyAppUrl}/cal/auth`;

  const modalContent = (
    <Stack alignItems="center" spacing={2}>
      <GoogleAuthDetailsSVG />
      <Body style={{ textAlign: "center" }}>
        Connect your Google calendar so that Dover can schedule interviews based on your availability.
      </Body>
      <Body style={{ textAlign: "center" }}>
        {useMinimalCalAuth ? (
          <>Dover uses limited permissions (free/busy only) and places scheduling events on a central Dover calendar.</>
        ) : (
          <>
            Dover <strong>only</strong> edits calendar events regarding candidate interviews.
          </>
        )}
      </Body>
      <Box display="flex" justifyContent="center">
        <a href={calAuthUrl} target="_blank" rel="noopener noreferrer">
          <Button variant={ButtonVariant.Primary} onClick={(): void => setOpenModal(false)}>
            Continue
          </Button>
        </a>
      </Box>
      <Spacer height="8px" />
    </Stack>
  );

  return (
    <>
      <ConnectButton
        isLoading={isLoading || isRevoking}
        text={isLoading || isRevoking ? "" : `${authed ? "Revoke" : "Connect"} Google`}
        icon={<GoogleSVG width="14px" height="14px" />}
        onClick={click}
      />
      <Modal open={openModal} onClose={(): void => setOpenModal(false)} maxWidth="sm">
        {modalContent}
      </Modal>
    </>
  );
};

export default ConnectGoogleCalendarButton;
