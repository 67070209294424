import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import IconButton from "@mui/material/IconButton";
import { SkipToken } from "@reduxjs/toolkit/query";
import React, { FC, useState } from "react";

import { Tooltip } from "components/library/Tooltip";
import { ListPipelineCandidatesArgs } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import {
  useToggleStarredCandidateMutation,
  ToggleStarCandidateArgs,
} from "services/doverapi/endpoints/starredCandidate";
import { colors } from "styles/theme";

interface StarCandidateProps {
  id: string | undefined;
  jobId: string | undefined;
  isStarred: boolean | undefined;
  fullName: string | undefined;
}

interface StarCandidateButtonProps {
  candidate?: StarCandidateProps;
  removePadding?: boolean; // Removes padding
  hideTooltip?: boolean; // Hides the tooltips
  size?: "small" | "medium" | "large"; // Match MUI IconButton size prop type
  alwaysShowStarred?: boolean; // Remove the hover css class if starred so that it always shows
  candidateListArgs?: ListPipelineCandidatesArgs | SkipToken;
}

export const StarCandidateButton: FC<React.PropsWithChildren<StarCandidateButtonProps>> = ({
  candidate,
  removePadding: compact,
  hideTooltip,
  size,
  alwaysShowStarred,
  candidateListArgs,
}) => {
  const [toggleStarCandidate, { isLoading }] = useToggleStarredCandidateMutation();
  const [optimisticIsStarred, setOptimisticIsStarred] = useState<boolean | undefined>(candidate?.isStarred);

  // Just hide the button if candidate is undefined for any other reason
  if (!candidate) {
    return null;
  }

  const click = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    // This is sometimes inside another clickable element or anchor like the kanban cards
    e.stopPropagation();
    e.preventDefault();

    if (!candidate || !candidate.id || !candidate.fullName || !candidate.jobId) {
      return;
    }

    // Optimistically update the star state
    setOptimisticIsStarred(!optimisticIsStarred);

    const args: ToggleStarCandidateArgs = {
      fullName: candidate.fullName,
      jobId: candidate.jobId,
      data: {
        candidateId: candidate.id,
      },
      candidateListArgs: typeof candidateListArgs === "symbol" ? undefined : candidateListArgs,
    };

    toggleStarCandidate(args)
      .unwrap()
      .catch(() => {
        // Revert optimistic update on error
        setOptimisticIsStarred(candidate.isStarred);
      });
  };

  const isDisabled = isLoading;
  const iconColor = isDisabled ? colors.warning.dark : colors.warning.base;

  const button = (
    <IconButton
      onClick={click}
      disabled={isDisabled}
      size={size}
      className={optimisticIsStarred || alwaysShowStarred ? undefined : "hover-star-candidate"}
      sx={{
        padding: compact ? 0 : undefined,
      }}
    >
      {optimisticIsStarred ? (
        <StarIcon sx={{ color: iconColor }} />
      ) : (
        <StarBorderOutlinedIcon sx={{ color: iconColor }} />
      )}
    </IconButton>
  );

  if (hideTooltip) {
    return button;
  }

  return (
    <Tooltip title={optimisticIsStarred ? "Unstar Candidate" : "Star Candidate"} placement="top">
      {button}
    </Tooltip>
  );
};
