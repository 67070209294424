import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";

import { DoverLoginSource, doverLoginSourceAtom } from "App/routing/atoms";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ScheduleOnboardingAlexandraSVG } from "assets/images/schedule-onboarding-alexandra.svg";
import { ReactComponent as ScheduleOnboardingGeorgeSVG } from "assets/images/schedule-onboarding-george.svg";
import { GEORGE_ONLY_CALENDLY } from "components/constants";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import DoverLogo from "components/icons/DoverLogo";
import { Button, ButtonVariant } from "components/library/Button";
import { Heading, Subtitle1 } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { CalendlyEmbed } from "components/onboarding/CalendlyEmbed";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { useAuth0 } from "services/react-auth0-spa";
import { colors, screenSizesNumbers } from "styles/theme";

export const ScheduleOnboarding = (): React.ReactElement => {
  const muiTheme = useTheme();
  const isLaptopL = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptopL));
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptop));
  const navigate = useNavigate();

  const { user } = useAuth0();
  const { data: userInfo, isFetching: userFetching } = useGetAuthedUserInfoQuery();
  const calendlyUrl = useGetCalendlyUrl("app", "onboarding_flow", "schedule_onboarding_call");

  const loginSourceValue = useAtomValue(doverLoginSourceAtom);

  if (userFetching) {
    return <DoverLoadingOverlay active={true} />;
  }

  const onScheduled = (): void => {
    // users that originate from the extension should be redirected to the extension create job flow
    if (loginSourceValue === DoverLoginSource.Extension) {
      navigate({ pathname: APP_ROUTE_PATHS.job.extensionCreateJob() });
    } else {
      navigate({ pathname: APP_ROUTE_PATHS.home() });
    }
  };

  const onSkip = (): void => {
    navigate({ pathname: APP_ROUTE_PATHS.onboarding.recruitingPartners() });
  };

  const calendlyWidget = (
    <CalendlyEmbed
      calendlyUrl={calendlyUrl}
      name={userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : user.name}
      email={user.email}
      utmCampaign="schedule_onboarding_call"
      utmMedium="app"
      utmSource="onboarding_flow"
      onEventScheduled={onScheduled}
      // these fixed dimensions are a bit weird, but because the embed is an iframe
      // it is hard to style, these are optimized for the most common screen sizes
      styles={{
        height: "100%",
        maxHeight: "680px",
        width: "100%",
        marginTop: isLaptopL ? "-30px" : undefined,
      }}
      dateAndTimeSelectedMaxHeight="800px"
    />
  );

  // Determine which SVG to show based on the calendly URL
  const ScheduleOnboardingSVG = calendlyUrl.includes(GEORGE_ONLY_CALENDLY)
    ? ScheduleOnboardingGeorgeSVG
    : ScheduleOnboardingAlexandraSVG;

  return (
    <Stack direction="row" height={"100%"} overflow="hidden" justifyContent="flex-start">
      <Box
        px={isSmallScreen ? 2 : 4}
        width={isSmallScreen ? "100%" : "35%"}
        borderRight={`solid 1px ${colors.grayscale.gray200}`}
        height="100%"
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          position: "relative",
          "&:-webkit-scrollbar": "none",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&-ms-overflow-style:": {
            display: "none",
          },
        }}
      >
        <Box
          position="sticky"
          top={0}
          left={0}
          zIndex={100}
          sx={{ backgroundColor: "white", paddingBottom: "0px !important" }}
          p={2}
          ml="-16px"
        >
          <DoverLogo width={72} />
        </Box>

        <Stack spacing={0} height="100%" justifyContent={"center"} alignItems={"center"}>
          <Stack spacing={1.5} maxWidth={"420px"} height={isSmallScreen ? "40%" : "100%"} justifyContent={"center"}>
            <Heading>Book a Free ATS Strategy Call</Heading>
            <Subtitle1 weight="300" color={colors.grayscale.gray500}>
              Learn how high-growth startups set up their ATS for success in hiring top-tier talent.
            </Subtitle1>
            <ScheduleOnboardingSVG />
          </Stack>
          {isSmallScreen && (
            <Stack height="50%">
              {calendlyWidget}
              <Button removePadding variant={ButtonVariant.Ghost} onClick={onSkip}>
                Skip
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
      {!isSmallScreen && (
        <Box
          flexGrow={1}
          borderRight={`solid 1px ${colors.grayscale.gray200}`}
          height="100%"
          sx={{
            overflowX: "hidden",
            overflowY: "auto",
            position: "relative",
            backgroundColor: colors.grayscale.gray100,
          }}
          width="50%"
          minWidth="560px"
        >
          <Stack height="100%" alignItems="center" justifyContent={"center"} overflow="auto" spacing={2}>
            {calendlyWidget}
            <Stack>
              <Button removePadding variant={ButtonVariant.Ghost} onClick={onSkip}>
                Skip
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};
