import { Grow, Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { BaseTypeProps, Body } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { filters } from "styles/theme";

interface SpicyLoadingProps {
  options: string[];
  showMs?: number;
  hideMs?: number;
}

export const SpicyLoadingText = ({
  options,
  showMs = 4500,
  hideMs = 1000,
  color,
  icon,
}: SpicyLoadingProps & BaseTypeProps & { icon?: React.ReactNode }): React.ReactElement => {
  const [idx, setIdx] = useState(Math.floor(Math.random() * options.length));
  const [show, setShow] = useState(false);

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (!show) {
          setIdx(idx + 1);
        }
        setShow(!show);
      },
      show ? showMs : hideMs
    );

    return (): void => clearInterval(interval);
  }, [hideMs, idx, show, showMs]);

  const text = options[idx % options.length];

  return (
    <GrowBottom in={show} timeout={{ enter: 1000, exit: 500 }}>
      <Stack direction="row" spacing={2} sx={{ whiteSpace: "nowrap" }} width="100%" justifyContent="center">
        {icon && icon}
        <Body italic color={color} centered>
          {text}
        </Body>
      </Stack>
    </GrowBottom>
  );
};

export const SpicyLoading = ({ options, showMs = 4500, hideMs = 1000 }: SpicyLoadingProps): React.ReactElement => {
  return (
    <Background>
      <Box position="relative" width="100%" height="100%">
        <Center>
          <Stack spacing={3} alignItems="center" mb={2}>
            <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />
          </Stack>
          <SpicyLoadingText options={options} showMs={showMs} hideMs={hideMs} />
        </Center>
      </Box>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
`;

const Center = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const GrowBottom = styled(Grow)`
  transform-origin: 50% -100%;
`;

export default SpicyLoading;
