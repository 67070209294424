import { Divider, SelectChangeEvent, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import clipboardCopy from "clipboard-copy";
import { useAtomValue } from "jotai";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import styled from "styled-components";

import { ENTER } from "App/hotkeys/hotkeys";
import { useHotkey } from "App/hotkeys/useHotkey";
import CopyIcon from "assets/icons/copy.svg";
import { useActionVersion } from "components/dover/top-level-modal-manager/hooks/useActionVersion";
import { useApplicationId } from "components/dover/top-level-modal-manager/hooks/useApplicationId";
import { useEmailState } from "components/dover/top-level-modal-manager/hooks/useEmailState";
import { useInterviewer } from "components/dover/top-level-modal-manager/hooks/useInterviewer";
import { useSchedulingOwnership } from "components/dover/top-level-modal-manager/hooks/useSchedulingOwnership";
import { useStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { useValidate } from "components/dover/top-level-modal-manager/hooks/useValidate";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import {
  CandidateActionEmailEditor,
  CandidateActionEmailEditorRefType,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor";
import {
  SchedulingLinkBanner,
  WarningBanner,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/WarningBanner";
import { ClientEmailTemplateSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/ClientEmailTemplateSelect";
import { DurationSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/DurationSelect";
import {
  InterviewerSelect,
  shouldDisableForInterviewerPreferences,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerSelect";
import { StageSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/StageSelect";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { convertClientInterviewer } from "components/dover/top-level-modal-manager/utils/convertClientInterviewer";
import { createInterviewPanel } from "components/dover/top-level-modal-manager/utils/createInterviewPanel";
import { getModalWidth, isMultipartInterview } from "components/dover/top-level-modal-manager/utils/getModalWidth";
import { isInterviewerEmpty } from "components/dover/top-level-modal-manager/utils/isInterviewerEmpty";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { DOVER_INTERVIEWER_ID } from "components/NextInterviewer/usePossibleInterviewers";
import { ScheduledActionButton } from "components/ScheduledActionButton";
import { appConfig } from "config/appConfig";
import { useCandidateJobId } from "hooks/useCandidateJobId";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useSubmitDecision } from "hooks/useSubmitDecision";
import {
  useGetCandidateBioQuery,
  useQueueEmailMutation,
} from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useListClientEmailTemplatesQuery } from "services/doverapi/endpoints/client/endpoints";
import { useCreateContactEmailsMutation } from "services/doverapi/endpoints/contact";
import { useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import {
  BaseInterviewSubstage,
  CandidateActionEmailArgs,
  CandidateBioSchedulingOwnershipEnum,
  ClientInterviewer,
  GetEmailTemplateRequestV2DecisionEnum,
  SubmitDecisionRequestDecisionEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { toastOptions } from "utils/showToast";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";

const SchedulingModal = ({ isOpen, closeModal, candidateId }: CandidateActionModalProps): React.ReactElement => {
  const hasImprovedSchedulingUX = useFeatureFlag(FeatureFlag.ImprovedSchedulingUX);

  const dtnMode = useAtomValue(isDtnMode);

  const candidateActionVersion = useActionVersion(candidateId);
  const schedulingOwnership = useSchedulingOwnership(candidateId);
  const customerManagedScheduling =
    schedulingOwnership === CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling;
  const applicationId = useApplicationId(candidateId);
  const jobId = useCandidateJobId(candidateId);

  const submitDecision = useSubmitDecision();
  const [queueEmail] = useQueueEmailMutation();

  const [proceedWithoutLink, setProceedWithoutLink] = useState(false);
  const [sendAt, setSendAt] = useState<Date>(new Date());
  const [substages, setSubstages] = useState<BaseInterviewSubstage[]>([]);
  const [clientEmailTemplateId, setClientEmailTemplateId] = useState<string | null | undefined>(undefined);

  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const [createEmail] = useCreateContactEmailsMutation({
    fixedCacheKey: candidateBio?.contact?.id,
  });
  const { stage, setStage, stages, substage, isTakeHome, isUpdatingStage } = useStage({ candidateId });
  const [interviewDuration, setInterviewDuration] = useState<number | undefined>(undefined);
  const { interviewer, setInterviewer } = useInterviewer(candidateId, stage);
  const interviewers = useListInterviewerEntities();
  const { currentData: { results: emailTemplates } = {} } = useListClientEmailTemplatesQuery(
    hasImprovedSchedulingUX ? { limit: 999 } : skipToken
  );

  // Fall back to what's defined on the stage if the user hasn't defined anything
  const stageSpecificSchedulingTemplateId = stage?.schedulingEmailTemplateId;
  // ternary needed instead of ?? because null is a valid value
  // there was a bug because of this
  // null = SMART_REPLY
  const selectedEmailTemplateId =
    clientEmailTemplateId === undefined ? stageSpecificSchedulingTemplateId : clientEmailTemplateId;
  const selectedEmailTemplate = emailTemplates?.find(t => t.id === selectedEmailTemplateId);

  // whenever we switch stage should reset some state
  // in particular, reset client email template ID so it resets to stage specific default
  const setStageWrapper = (newStageId: string): void => {
    setClientEmailTemplateId(undefined);
    setStage(newStageId);
  };

  // Used to relocate the menu bar save as new template action outside of the editor
  const editorRef = useRef<CandidateActionEmailEditorRefType>(null);

  const interviewPanel = createInterviewPanel({
    interviewer,
    stage,
    substages,
    substageId: substage?.id,
    substageDuration: interviewDuration,
    schedulingOwnership,
    isTakeHome,
  });

  // NOTE: When scheduling after moving jobs, there's an issue where we invoke fetchEmailTemplate
  // before we rederive the candidate's desired `stage` via the `useStage` hook.
  // This hook involves a call to `listHiringPipelineStages` using the candidate's *new* job.
  // This causes `fetchEmailTemplate` to fail since it's using a HPS from the old job.
  // I'm not sure how to block on the resolution of the `HPS` call.
  const {
    to,
    setRecipient,
    recipientOptions,
    from,
    setFrom,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    messageKey,
    threadId,
    isFetching,
    isValidatingLink,
    invalidLink,
    hasFindatimeLink,
    hasCalendlyLink,
    completedInterviews,
  } = useEmailState({
    candidateId,
    clientEmailTemplateId: selectedEmailTemplateId,
    decision: GetEmailTemplateRequestV2DecisionEnum.Approve,
    stage,
    interviewPanel,
    skipLinkValidation: dtnMode,
    ignoreInterviewPanelBlock: hasImprovedSchedulingUX,
  });

  const { warning, tooltip, disabledMap, hideEditor } = useValidate("Schedule", {
    fetchingTemplateAndBioData: isFetching,
    subject,
    to,
    from,
    body,
    desiredHiringPipelineStage: stage,
    substage,
    schedulingOwnership,
    interviewer,
    interviewPanel,
    isTakeHome,
    proceedWithoutLink,
    isValidatingLink,
    invalidLink,
    completedInterviews,
  });

  const [clicked, setClicked] = React.useState(false);

  // Improved scheduling UX pull the interviewer of the template
  useEffect(() => {
    if (hasImprovedSchedulingUX && selectedEmailTemplate?.defaultInterviewer) {
      const templateInterviewer = convertClientInterviewer(
        interviewers.find(
          (etInterviewer: ClientInterviewer) => etInterviewer.id === selectedEmailTemplate.defaultInterviewer
        )
      );
      if (templateInterviewer) {
        setInterviewer(templateInterviewer);
      }
    }
  }, [selectedEmailTemplate, hasImprovedSchedulingUX, interviewers, setInterviewer]);

  // Reset proceed without link if the interviewer changes
  useEffect(() => {
    setProceedWithoutLink(false);
    if (interviewer.value === DOVER_INTERVIEWER_ID) {
      setInterviewDuration(substage?.duration);
    }
  }, [interviewer, substage]);

  // set initial duration to substage duration
  useEffect(() => {
    setInterviewDuration(substage?.duration);
  }, [setInterviewDuration, substage]);

  // TODO: this can be the late stage check as well
  const usingMultipartEditor = isMultipartInterview(stage);
  const modalWidth = getModalWidth(stage);
  const showInterviewerPrefWarning =
    !hasImprovedSchedulingUX &&
    !isInterviewerEmpty(interviewer) &&
    shouldDisableForInterviewerPreferences({ interviewer, proceedWithoutLink });

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = useCallback(async () => {
    if (!jobId || !stage || (!stage.id && !stage.isOneOffInterview) || !from) {
      return;
    }
    setSubmitting(true);

    // Do we need to add the email to the candidate?
    if (!to.label) {
      // Recently added emails have no label
      createEmail({
        data: { contactId: candidateBio?.contact?.id!, emails: [to.email] },
        candidateId: candidateId,
      }).unwrap();
    }

    const emailArgs: CandidateActionEmailArgs = {
      emailAlias: from.id,
      toEmails: [to.email],
      ccEmails: cc.map(o => o.email),
      bccEmails: bcc.map(o => o.email),
      subject,
      body,
      threadId,
      messageKey: messageKey,
      sendAt: sendAt,
    };

    const args = {
      jobId,
      applicationId,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Approve,
          auto: false,
          emailArgs,
          desiredHiringPipelineStage: stage?.id,
          interviewPanel,
          archiveReason: null,
        },
      },
    };

    let submit;
    // NOTE: When stage.id is undefined, we are scheduling a one-off interview. This is because one-off interviews
    // are not associated with a hiring pipeline stage. We can just queue an email which includes the scheduling link.
    if (stage?.id) {
      submit = submitDecision(args).unwrap();
    } else {
      submit = queueEmail({
        id: candidateId,
        data: { candidateActionVersion, emailArgs },
      }).unwrap();
    }

    try {
      closeModal();

      await toast.promise(
        submit,
        {
          error: "Error updating candidate",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    } finally {
      setSubmitting(false);
    }
  }, [
    bcc,
    body,
    candidateActionVersion,
    candidateId,
    cc,
    closeModal,
    sendAt,
    from,
    interviewPanel,
    jobId,
    messageKey,
    stage,
    subject,
    submitDecision,
    queueEmail,
    threadId,
    to,
    applicationId,
    setSubmitting,
    candidateBio?.contact?.id,
    createEmail,
  ]);

  useHotkey(ENTER, "Schedule candidate", onSubmit);

  const ActionSection = useMemo(
    () => (
      <Stack width="100%" spacing={1}>
        {!isFetching && <WarningBanner warning={warning} />}
        {hasImprovedSchedulingUX && !usingMultipartEditor && !isFetching && (
          <SchedulingLinkBanner bannerInfo={{ hasFindatimeLink, hasCalendlyLink }} />
        )}
        <Stack
          width="100%"
          spacing={2}
          direction="row"
          justifyContent="space-between"
          padding="12px"
          sx={{
            cursor: submitting ? "not-allowed" : "pointer",
            opacity: submitting ? 0.5 : 1,
          }}
        >
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Body>Send </Body>
            <ScheduledActionButton setActionAt={setSendAt} />
          </Stack>
          <Stack direction="row" spacing={2}>
            {hasImprovedSchedulingUX && (
              <Button
                variant={ButtonVariant.SecondaryLight}
                onClick={(): void => {
                  if (editorRef.current?.handleSaveAsNewTemplate) {
                    editorRef.current?.handleSaveAsNewTemplate({
                      enabled: true,
                      defaultInterviewer: interviewer,
                    });
                  }
                }}
                disabled={submitting}
              >
                Save as template
              </Button>
            )}
            <Button
              variant={ButtonVariant.SecondarySuccess}
              onClick={onSubmit}
              disabled={submitting || disabledMap.Send}
              tooltip={tooltip}
            >
              {isUpdatingStage && !dtnMode ? "Send and update stage" : "Send"}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      disabledMap.Send,
      setSendAt,
      isFetching,
      isUpdatingStage,
      onSubmit,
      tooltip,
      warning,
      dtnMode,
      submitting,
      hasCalendlyLink,
      hasFindatimeLink,
      hasImprovedSchedulingUX,
      editorRef,
      usingMultipartEditor,
      interviewer,
    ]
  );

  return (
    <>
      <CustomModal
        open={isOpen}
        onClose={closeModal}
        title={
          <Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>
            {hasImprovedSchedulingUX && stage && candidateBio?.contact?.fullName
              ? `Schedule ${candidateBio?.contact?.fullName} for ${stage?.name}`
              : "Schedule"}
          </Body>
        }
        maxWidth={modalWidth}
        customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
        customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
        dialogActions={ActionSection}
        dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
      >
        <Stack spacing={2}>
          {usingMultipartEditor ? (
            <Stack spacing={2} direction="column">
              <StageSelect stageId={stage?.id} setStage={setStageWrapper} stages={stages} />
              <InterviewerSelect
                candidateId={candidateId}
                desiredHiringPipelineStage={stage}
                interviewer={interviewer}
                setInterviewer={setInterviewer}
                setSubstages={setSubstages}
                proceedWithoutLink={proceedWithoutLink}
                isTakeHome={isTakeHome}
              />
            </Stack>
          ) : (
            <Stack spacing={1}>
              {!hasImprovedSchedulingUX && (
                <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <StageSelect
                    stageId={stage?.id}
                    setStage={setStageWrapper}
                    stages={stages}
                    disabled={dtnMode}
                    disabledTooltipText={
                      dtnMode
                        ? "Dover Talent Network candidates can only be approved to the initial call stage"
                        : undefined
                    }
                  />
                  <InterviewerSelect
                    candidateId={candidateId}
                    desiredHiringPipelineStage={stage}
                    interviewer={interviewer}
                    setInterviewer={setInterviewer}
                    setSubstages={setSubstages}
                    proceedWithoutLink={proceedWithoutLink}
                    isTakeHome={isTakeHome}
                  />
                  <DurationSelect
                    disabled={interviewer.value === DOVER_INTERVIEWER_ID || isTakeHome}
                    duration={interviewDuration}
                    disabledTooltipText={
                      interviewer.value === DOVER_INTERVIEWER_ID
                        ? "Duration cannot be edited for Dover Interviews"
                        : isTakeHome
                        ? "Duration not applicable for Assessment / Non-interview stages"
                        : undefined
                    }
                    setDuration={(e: SelectChangeEvent<number | unknown>): void =>
                      setInterviewDuration(e.target.value as number)
                    }
                  />
                </Stack>
              )}
              {showInterviewerPrefWarning && (
                <Banner variant={BannerVariant.Warning}>
                  <Stack spacing={1}>
                    <BodySmall>
                      {interviewer.fullName} must complete their{" "}
                      <InternalLink target="_blank" to="/settings/interviews">
                        interview preferences
                      </InternalLink>{" "}
                      in order to autofill a scheduling link. (
                      <Tooltip
                        variant={TooltipVariant.Dark}
                        title={clicked ? "Copied!" : "Copy to clipboard"}
                        placement="top"
                        onOpen={(): void => setClicked(false)}
                        onClick={(): void => {
                          clipboardCopy(`${appConfig.appUrl}/settings/interviews`);
                          setClicked(true);
                        }}
                      >
                        <FakeLink>
                          copy to clipboard <StyledReactSVG src={CopyIcon} />
                        </FakeLink>
                      </Tooltip>
                      )
                    </BodySmall>
                    {!hasImprovedSchedulingUX && (
                      <Button
                        variant={ButtonVariant.Secondary}
                        onClick={(): void => setProceedWithoutLink(true)}
                        width="max-content"
                      >
                        <BodySmall>Proceed without scheduling link</BodySmall>
                      </Button>
                    )}
                  </Stack>
                </Banner>
              )}
            </Stack>
          )}
          <ClientEmailTemplateSelect
            disabled={dtnMode}
            disabledTooltipText={dtnMode ? "Dover Talent Network scheduling emails cannot be customized" : undefined}
            clientEmailTemplateId={selectedEmailTemplateId}
            setClientEmailTemplateId={setClientEmailTemplateId}
            candidateId={candidateId}
            showSetAsDefault={hasImprovedSchedulingUX && !usingMultipartEditor}
          />
          {!hideEditor && (
            <>
              <Divider
                sx={{
                  margin: "24px -24px !important",
                }}
              />
              <Stack spacing={1}>
                <BodySmall weight="500">Email</BodySmall>
                <CandidateActionEmailEditor
                  candidateId={candidateId}
                  interviewer={interviewer}
                  stage={stage}
                  desiredHiringPipelineStageId={stage?.isOneOffInterview ? undefined : stage?.id}
                  customerManagedScheduling={customerManagedScheduling}
                  duration={interviewDuration}
                  hideSchedulingLink={proceedWithoutLink || usingMultipartEditor}
                  body={initialBody}
                  onBodyChanged={setBody}
                  subject={initialSubject}
                  onSubjectChanged={setSubject}
                  from={from}
                  onFromChanged={setFrom}
                  to={to}
                  toFreeSolo={hasImprovedSchedulingUX}
                  onToChanged={hasImprovedSchedulingUX ? setRecipient : undefined}
                  toOptions={hasImprovedSchedulingUX ? recipientOptions : undefined}
                  cc={cc}
                  onCcChanged={setCc}
                  bcc={bcc}
                  onBccChanged={setBcc}
                  disableFrom={disabledMap.From}
                  disableTo={disabledMap.To}
                  disableCc={disabledMap.CC}
                  disableBcc={disabledMap.BCC}
                  disableSubject={disabledMap.Subject || dtnMode}
                  disableBody={disabledMap.Body || dtnMode}
                  loading={isFetching}
                  useInterviewerSchedulingLinkSelector={hasImprovedSchedulingUX && !usingMultipartEditor}
                  hideSaveTemplateButton={hasImprovedSchedulingUX}
                  ref={editorRef}
                />
              </Stack>
            </>
          )}
        </Stack>
      </CustomModal>
    </>
  );
};

export default SchedulingModal;

const StyledReactSVG = styled(ReactSVG)`
  display: inline;
  margin-right: 2px;
  path {
    stroke: ${colors.link};
  }
`;

const FakeLink = styled.span`
  color: ${colors.link};
  cursor: pointer;
`;
