import { zodResolver } from "@hookform/resolvers/zod";
import { InputAdornment, Stack, TextField, createFilterOptions } from "@mui/material";
import React, { ReactElement, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import { ReactComponent as CheckGreenFilled } from "assets/icons/check-green-filled.svg";
import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Heading, BodyExtraSmall, BodySmall } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import CustomModal from "components/Modal";
import { appConfig } from "config/appConfig";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  Agency,
  CreateAgencyRecruiterRateTypeEnum,
  JobReferrerRateTypeEnum,
  PutAgencyRecruiterRateTypeEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import { AgencyRecruiter } from "views/agencies/AgenciesTable";
import { AddAgencyFormSchema, addAgencyFormSchema } from "views/agencies/types";
import {
  useCreateAgencyRecruiterMutation,
  useListAgenciesQuery,
  useUpdateAgencyRecruiterMutation,
} from "views/referralsV2/endpoints";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    border: none;
  }
`;

const OffsetButton = styled(Button)`
  margin-right: -1px;
`;

interface AutocompleteAgency extends Agency {
  inputValue?: string;
}

interface SuccessScreenProps {
  agencyId: string;
  agencyName: string;
  isEdit?: boolean;
}

interface EditAgencyRecruiterData {
  agencyRecruiter?: AgencyRecruiter;
}

const SuccessScreen: React.FC<SuccessScreenProps> = ({ agencyId, agencyName, isEdit }) => {
  const clientId = useGetClientId();
  const agencyLink = `${appConfig.appUrl}/agency-portal/${agencyId}?clientId=${clientId}`;

  return (
    <Stack
      sx={{ display: "flex", flex: 1, minHeight: 250, alignItems: "center", justifyContent: "center" }}
      spacing={2}
    >
      <CheckGreenFilled width="40px" />
      <Heading weight="600">{`Agency ${isEdit ? "Edited!" : "added!"}`}</Heading>
      <Body>We&apos;ve emailed the {agencyName} with a link to submit candidates.</Body>
      <Body>
        Preview the link they received{" "}
        <a href={agencyLink} target="_blank" rel="noreferrer">
          here
        </a>
        .
      </Body>
    </Stack>
  );
};

export const AddAgencyModal: React.FC<React.PropsWithChildren<GlobalModalProps & EditAgencyRecruiterData>> = ({
  isOpen,
  close,
  agencyRecruiter,
}) => {
  const jobId = useJobIdFromUrl();
  const filter = createFilterOptions<AutocompleteAgency>();

  const [page, setPage] = React.useState<number>(0);
  const { data: agencies, isFetching: isFetchingAgencies } = useListAgenciesQuery({ limit: 300 });

  const [selectedAgency, setSelectedAgency] = React.useState<AutocompleteAgency | null>(null);

  const [selectedRateType, setSelectedRateType] = React.useState<CreateAgencyRecruiterRateTypeEnum>(
    CreateAgencyRecruiterRateTypeEnum.Percentage
  );

  const formMethods = useForm<AddAgencyFormSchema>({
    resolver: zodResolver(addAgencyFormSchema),
    defaultValues: {
      jobId,
      agencyName: "",
      email: "",
      firstName: "",
      lastName: "",
      rate: undefined,
      rateType: CreateAgencyRecruiterRateTypeEnum.Percentage,
    },
  });

  const {
    register,
    formState: { errors: formErrors },
    setValue,
    getValues,
    handleSubmit,
  } = formMethods;

  useEffect(() => {
    if (agencyRecruiter) {
      setSelectedAgency({
        id: agencyRecruiter.agencyId,
        name: agencyRecruiter.agencyName,
        inputValue: agencyRecruiter.agencyName,
      });

      if (agencyRecruiter.name) {
        const parts = agencyRecruiter.name.split(" ");
        if (parts.length) {
          setValue("firstName", parts[0]);
          if (parts.length > 1) {
            setValue("lastName", parts[1]);
          }
        }
      }

      if (agencyRecruiter.email) {
        setValue("email", agencyRecruiter.email);
      }

      if (agencyRecruiter.rateType === JobReferrerRateTypeEnum.FlatRate && agencyRecruiter.estimatedCost) {
        setValue("rateType", CreateAgencyRecruiterRateTypeEnum.FlatRate);
        setSelectedRateType(CreateAgencyRecruiterRateTypeEnum.FlatRate);
        setValue("rate", agencyRecruiter.estimatedCost.replace("$", ""));
      }

      if (agencyRecruiter.rateType === JobReferrerRateTypeEnum.Percentage && agencyRecruiter.fee) {
        setValue("rateType", CreateAgencyRecruiterRateTypeEnum.Percentage);
        setSelectedRateType(CreateAgencyRecruiterRateTypeEnum.Percentage);
        setValue("rate", agencyRecruiter.fee.replace("%", ""));
      }
    }
  }, [agencyRecruiter, setValue]);

  const [addAgencyRecruiter, { isLoading: isCreatingAgencyRecruiter }] = useCreateAgencyRecruiterMutation();
  const [updateAgencyJobReferrer, { isLoading: isUpdating }] = useUpdateAgencyRecruiterMutation();

  const addAgency = async (): Promise<void> => {
    if (!jobId) {
      return;
    }

    const formValues = getValues();
    const res = await addAgencyRecruiter({
      jobId,
      data: {
        agencyId: selectedAgency?.id || undefined,
        agencyName: selectedAgency?.id ? undefined : selectedAgency?.name,
        recruiterEmail: formValues.email.trim(),
        recruiterName: `${formValues.firstName} ${formValues.lastName}`,
        rate: formValues.rate,
        rateType: selectedRateType,
      },
    }).unwrap();

    if (res.success) {
      setSelectedAgency({
        id: res.referrerId,
        name: selectedAgency?.name || "",
      });

      setPage(1);
    } else {
      showErrorToast(res?.message || "Failed to add agency");
    }
  };

  const editAgency = async (): Promise<void> => {
    if (!(agencyRecruiter && agencyRecruiter.id && selectedAgency?.name)) {
      return;
    }

    const formValues = getValues();
    const res = await updateAgencyJobReferrer({
      agencyReferrerId: agencyRecruiter.id,
      data: {
        agencyName: selectedAgency.name,
        recruiterEmail: formValues.email.trim(),
        recruiterName: `${formValues.firstName} ${formValues.lastName}`,
        rate: formValues.rate,
        rateType:
          selectedRateType === CreateAgencyRecruiterRateTypeEnum.FlatRate
            ? PutAgencyRecruiterRateTypeEnum.FlatRate
            : PutAgencyRecruiterRateTypeEnum.Percentage,
      },
    }).unwrap();

    if (res.success) {
      setSelectedAgency({
        id: res.referrerId,
        name: selectedAgency?.name || "",
      });

      setPage(1);
    } else {
      showErrorToast(res?.message || "Failed to add agency");
    }
  };

  const onAddClick = async (): Promise<void> => {
    setValue("agencyName", selectedAgency?.name || "");
    setValue("rateType", selectedRateType);
    handleSubmit(agencyRecruiter ? editAgency : addAgency)();
  };

  const title = `${agencyRecruiter ? "Edit" : "Add"} Agency`;

  return (
    <CustomModal
      title={page === 0 ? title : ""}
      loading={isFetchingAgencies}
      open={isOpen}
      onClose={close}
      maxWidth="sm"
      omitDividers
      showTitleSpacer={false}
    >
      {isCreatingAgencyRecruiter || isUpdating ? (
        <DoverLoadingOverlay active backgroundColor="white" />
      ) : (
        <FormProvider {...formMethods}>
          {page === 0 ? (
            <Stack spacing={3}>
              <Stack spacing={2}>
                <BodyExtraSmall weight={"500"} color={colors.grayscale.gray500}>
                  AGENCY INFORMATION
                </BodyExtraSmall>
                <Stack spacing={0.5}>
                  <BodySmall weight="600">Agency Name</BodySmall>
                  <Autocomplete
                    // @ts-ignore
                    freeSolo
                    autoHighlight
                    selectOnFocus
                    clearOnBlur
                    placeholder="Agency Name"
                    // @ts-ignore
                    initialValue={selectedAgency}
                    staticOptions={(agencies?.results || []) as AutocompleteAgency[]}
                    errorText={formErrors.agencyName?.message}
                    filterOptions={(options, params): any[] => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(option => inputValue === option.name);

                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          id: "",
                          name: `Add: "${inputValue}"`,
                          inputValue,
                        });
                      }

                      return filtered;
                    }}
                    onSelectedOptionChange={(value: string | object): void => {
                      if (typeof value === "string") {
                        setSelectedAgency({
                          id: "",
                          name: value,
                        });
                        // @ts-ignore
                      } else if (value && value.inputValue) {
                        // Create a new value from the user input
                        setSelectedAgency({
                          id: "",
                          // @ts-ignore
                          name: value.inputValue,
                        });
                      } else {
                        setSelectedAgency((value as AutocompleteAgency) || null);
                      }
                    }}
                    isOptionEqualToValue={(option: AutocompleteAgency, value: AutocompleteAgency): boolean => {
                      return option.name === value.name;
                    }}
                    getOptionLabel={(option: string | AutocompleteAgency): string => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderOption={(props, option): ReactElement => {
                      const { ...optionProps } = props;
                      return (
                        <li key={option.name} {...optionProps}>
                          {option.name}
                        </li>
                      );
                    }}
                  />
                </Stack>
              </Stack>

              <Stack spacing={2}>
                <BodyExtraSmall weight={"500"} color={colors.grayscale.gray500}>
                  RECRUITER INFORMATION
                </BodyExtraSmall>

                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <Stack spacing={0.5} flex={1}>
                      <BodySmall weight="600">First Name</BodySmall>
                      <StyledTextField
                        fullWidth
                        size="small"
                        placeholder="First Name"
                        required={true}
                        inputProps={{ ...register("firstName") }}
                        helperText={formErrors.firstName?.message}
                        error={!!formErrors.firstName}
                      />
                    </Stack>
                    <Stack spacing={0.5} flex={1}>
                      <BodySmall weight="600">Last Name</BodySmall>
                      <StyledTextField
                        fullWidth
                        size="small"
                        placeholder="Last Name"
                        required={true}
                        inputProps={{ ...register("lastName") }}
                        helperText={formErrors.lastName?.message}
                        error={!!formErrors.lastName}
                      />
                    </Stack>
                  </Stack>

                  <Stack spacing={0.5}>
                    <BodySmall weight="600">Email</BodySmall>
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Email"
                      required={true}
                      inputProps={{ ...register("email") }}
                      helperText={formErrors.email?.message}
                      error={!!formErrors.email}
                    />
                  </Stack>

                  <Stack spacing={0.5}>
                    <BodySmall weight="600">Fee</BodySmall>
                    <Stack direction="row" spacing={1}>
                      <Stack sx={{ flex: 1 }}>
                        <StyledTextField
                          fullWidth
                          size="small"
                          placeholder="Fee"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage ? "%" : "$"}
                              </InputAdornment>
                            ),
                          }}
                          required={true}
                          inputProps={{ ...register("rate") }}
                          helperText={formErrors.rate?.message}
                          error={!!formErrors.rate}
                        />
                      </Stack>

                      <Stack direction="row" height="40px">
                        <OffsetButton
                          removeOutline
                          variant={
                            selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage
                              ? ButtonVariant.GhostPrimary2
                              : ButtonVariant.GhostPrimary
                          }
                          borderRadius="4px 0px 0px 4px"
                          onClick={(): void => {
                            setSelectedRateType(CreateAgencyRecruiterRateTypeEnum.Percentage);
                          }}
                        >
                          <BodySmall
                            weight={
                              selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage ? "500" : undefined
                            }
                            color={
                              selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage
                                ? colors.brand
                                : undefined
                            }
                          >
                            % Percent
                          </BodySmall>
                        </OffsetButton>
                        <Button
                          removeOutline
                          variant={
                            selectedRateType === CreateAgencyRecruiterRateTypeEnum.FlatRate
                              ? ButtonVariant.GhostPrimary2
                              : ButtonVariant.GhostPrimary
                          }
                          borderRadius="0px 4px 4px 0px"
                          onClick={(): void => {
                            setSelectedRateType(CreateAgencyRecruiterRateTypeEnum.FlatRate);
                          }}
                        >
                          <BodySmall
                            weight={selectedRateType === CreateAgencyRecruiterRateTypeEnum.FlatRate ? "500" : undefined}
                            color={
                              selectedRateType === CreateAgencyRecruiterRateTypeEnum.FlatRate ? colors.brand : undefined
                            }
                          >
                            $ Fee
                          </BodySmall>
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent={"flex-end"} width="100%" sx={{ marginTop: "30px !important" }}>
                <Stack width="fit-content">
                  <Button
                    tooltip={agencyRecruiter ? "Saving will resend email to recruiter." : ""}
                    variant={ButtonVariant.Primary}
                    onClick={onAddClick}
                  >
                    {`${agencyRecruiter ? "Save" : "Add"}`}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <SuccessScreen
              agencyId={selectedAgency?.id || ""}
              agencyName={selectedAgency?.name || ""}
              isEdit={!!agencyRecruiter}
            />
          )}
        </FormProvider>
      )}
    </CustomModal>
  );
};

export const addAgencyModalAtom = modalAtom(AddAgencyModal);
