import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as MailSVG } from "assets/icons/mail-dark-outline.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";

interface EmailIconButtonProps {
  tooltip?: string;
  loading?: boolean;
  onClick?: () => void;
}

const EmailIconButton = ({ tooltip, loading, onClick }: EmailIconButtonProps): React.ReactElement => {
  return (
    <Button
      loading={loading}
      tooltip={tooltip}
      onClick={(): void => {
        if (onClick) onClick();
      }}
      variant={ButtonVariant.Secondary}
    >
      <Stack>
        <BodySmall>
          <MailSVG />
        </BodySmall>
      </Stack>
    </Button>
  );
};

export default EmailIconButton;
