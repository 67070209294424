import {
  CandidateFilterSourcingContextEnum,
  CandidateFilterStatusEnum,
  NextActionSchedulingStateEnum,
  NextAction,
  NextActionOwnerEnum,
  PipelineCandidate,
  SlimNextCampaignMessageRequestStateEnum,
  ContactedSubstageEnum,
  HiringPipelineStageType,
} from "services/openapi";
import {
  CandidateSourcingContext,
  CandidateStatusLabel,
  NextUpLabelEnum,
  OutReachEmailEditorOpenParam,
  PageParam,
  QuickFilterEnum,
  QuickFilterParam,
  SourcingContextParam,
  StatusParam,
  OrderingParam,
  HasMentionParam,
  StageIdParam,
  SubstageParam,
  SourcerParam,
} from "views/candidates/types";

export enum ColumnNameEnum {
  Name = "Name",
  SocialLinks = "Social Links",
  LastModified = "Last Modified",
  PipelineStage = "Current Stage",
  FailureReason = "Failure Reason",
  Actions = "Actions",
  NextUp = "Next Up",
  Match = "Match",
}

export type ContactedSubStageKeys =
  | ContactedSubstageEnum.BASE
  | ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_1
  | ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_2;
export const contactedFollowUpStageName: Record<ContactedSubStageKeys, string> = {
  [ContactedSubstageEnum.BASE]: "Outreach Sent",
  [ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_1]: "Follow up 1 sent",
  [ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_2]: "Follow up 2 sent",
};

export const getNextActionLabel = (
  nextAction?: NextAction,
  candidate?: PipelineCandidate
): NextUpLabelEnum | undefined => {
  if (!nextAction) {
    return undefined;
  }

  if (candidate?.candidatePipelineStage?.stageType === HiringPipelineStageType.CONTACTED) {
    if (candidate?.slimNextCampaignMessageRequest?.state === SlimNextCampaignMessageRequestStateEnum.Queued) {
      return NextUpLabelEnum.FollowupQueued;
    }
    return NextUpLabelEnum.Contacted;
  }

  const schedulingState = nextAction.schedulingState;
  const isDoverInterview = nextAction.currentInterview?.hasDoverInterviewer || false;
  const hasNotesSubmitted = isDoverInterview && nextAction.currentInterview?.hasCompletedNotes;
  const doverIsScheduling = nextAction.owner === NextActionOwnerEnum.Dover;

  switch (schedulingState) {
    case NextActionSchedulingStateEnum.NewLead: {
      const cmrState = candidate?.slimNextCampaignMessageRequest?.state;
      if (!cmrState) {
        return NextUpLabelEnum.Sourced;
      }
      switch (cmrState) {
        case SlimNextCampaignMessageRequestStateEnum.Failed:
          return NextUpLabelEnum.OutreachFailed;
        case SlimNextCampaignMessageRequestStateEnum.Queued:
        case SlimNextCampaignMessageRequestStateEnum.ManuallyQueued:
          return NextUpLabelEnum.OutreachQueued;
        default:
          return NextUpLabelEnum.NeedsReview;
      }
    }
    case NextActionSchedulingStateEnum.InitialReview:
      return NextUpLabelEnum.NeedsAction;
    case NextActionSchedulingStateEnum.OfferExtended:
    case NextActionSchedulingStateEnum.CallCompleted:
      return isDoverInterview && !hasNotesSubmitted
        ? NextUpLabelEnum.InterviewCompletedWithDover
        : NextUpLabelEnum.NeedsAction;
    case NextActionSchedulingStateEnum.Scheduled:
      return NextUpLabelEnum.InterviewScheduled;
    case NextActionSchedulingStateEnum.Scheduling:
      return doverIsScheduling ? NextUpLabelEnum.DoverScheduling : NextUpLabelEnum.YouAreScheduling;
  }
};

export const ProcessingChannels: CandidateSourcingContext[] = [
  { label: "Dover Sourcing", value: CandidateFilterSourcingContextEnum.Outbound },
  { label: "Manually Added", value: CandidateFilterSourcingContextEnum.ManuallyAdded },
  { label: "Referral", value: CandidateFilterSourcingContextEnum.Referral },
  { label: "Inbound Application", value: CandidateFilterSourcingContextEnum.Inbound },
  { label: "Agency", value: CandidateFilterSourcingContextEnum.Agency },
];

export const SourceLabelList: CandidateStatusLabel[] = [
  { label: "Active", value: CandidateFilterStatusEnum.Active },
  { label: "Withdrew", value: CandidateFilterStatusEnum.Withdrew },
  { label: "Hired", value: CandidateFilterStatusEnum.Hired },
  { label: "Rejected", value: CandidateFilterStatusEnum.Rejected },
  { label: "Snoozed", value: CandidateFilterStatusEnum.Snoozed },
];

export const MIN_TABLE_HEIGHT = "450px";
export const MAX_TABLE_HEIGHT = "calc(100vh - 400px)";

export const TABLE_STYLES = {
  borderRadius: "6px",
  minHeight: MIN_TABLE_HEIGHT,
  maxHeight: MAX_TABLE_HEIGHT,
};

export const CANDIDATES_LIMIT = 10;

export const filterParamConfig = {
  page: PageParam,
  ordering: OrderingParam,
  outreachEmailEditorOpen: OutReachEmailEditorOpenParam,
  hpsId: StageIdParam,
  substages: SubstageParam,
  status: StatusParam,
  sourcingContext: SourcingContextParam,
  hasMention: HasMentionParam,
  quickFilter: QuickFilterParam,
  sourcer: SourcerParam,
};

export const quickFilterTitles = {
  [QuickFilterEnum.AllNeedsAction]: "Needs Action",
  [QuickFilterEnum.Applicants]: "Applicants",
  [QuickFilterEnum.RespondedAsInterested]: "Responded as Interested",
  [QuickFilterEnum.PendingCustomerResponse]: "Pending Customer Response",
  [QuickFilterEnum.CompletedInterview]: "Completed Interview",
  [QuickFilterEnum.Mentions]: "Mentions",

  [QuickFilterEnum.Interviewing]: "Interviewing",
  [QuickFilterEnum.Scheduled]: "Scheduled",
  [QuickFilterEnum.Rejected]: "Rejected",
  [QuickFilterEnum.Snoozed]: "Snoozed",
};

export const quickFilterDescriptions: { [key: string]: string } = {
  [QuickFilterEnum.Scheduled]: "Candidate(s) actively interviewing.",
  [QuickFilterEnum.Interviewing]: "Candidate(s) scheduled for an interview.",
  [QuickFilterEnum.Rejected]: "Candidate(s) rejected.",
  [QuickFilterEnum.Snoozed]: "Candidate(s) snoozed.",
};

export const FAILURE_REASON_TOOLTIP_TEXT =
  "This column describes reasons why Dover was unable to contact the candidates you requested us to contact. More details on each of these cases can be found in the associated help center article.";

export const columnNameOrderingIds = {
  [ColumnNameEnum.Name]: "contact__full_name",
  [ColumnNameEnum.LastModified]: "last_event_ts",
  [ColumnNameEnum.PipelineStage]: "pipeline_stage",
};
