import { Button } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import { BooleanParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as NoApplicantsIcon } from "assets/icons/no-applicants-icon.svg";
import PeopleSVG from "assets/icons/people.svg";
import { ReactComponent as ReviewListIcon } from "assets/icons/review-list-icon.svg";
import { ReactComponent as GreenArrowRight } from "assets/icons/short-green-arrow-right.svg";
import { ReactComponent as NoCandidatesUpsellIcon } from "assets/images/no-candidates-upsell.svg";
import { Button as LegacyButton, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Body, BodySmall, Heading, Subtitle1 } from "components/library/typography";
import { ADD_CANDIDATE_DRAWER_QUERY_PARAM, FLOW_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { FlowTypeParam, FlowTypes } from "sections/addcandidate/types";
import { colors } from "styles/theme";

const CircleBullet = styled(Box)`
  padding: 0 6px;
  background-color: ${colors.white};
  border-radius: 20px;
  color: ${colors.black};
  font-size: 12px;
  font-weight: 600;
  font-family: Inter;
  outline-style: solid !important;
  outline: 1px;
  outline-color: ${colors.grayscale.gray400};
`;

interface NoCandidatesEmptyStateProps {
  jobId?: string;
  variant: "app-review" | "pipeline-view" | "todo-list";
}

export const NoCandidatesEmptyState = ({ jobId, variant }: NoCandidatesEmptyStateProps): React.ReactElement => {
  const navigate = useNavigate();
  const [, setIsOpenAddCandidateDrawerParam] = useQueryParam(ADD_CANDIDATE_DRAWER_QUERY_PARAM, BooleanParam);
  const [, setFlowTypeParam] = useQueryParam(FLOW_TYPE_QUERY_PARAM, FlowTypeParam);

  const headingText = (): string => {
    if (variant === "app-review") {
      return "No applicants to display";
    } else if (variant === "pipeline-view") {
      return "No candidates to display";
    } else {
      return "No candidates to review";
    }
  };

  if (!jobId) {
    if (variant === "app-review") {
      return (
        <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={2} px={1}>
          <NoApplicantsIcon />
          <Stack alignItems="center">
            <Subtitle1>No applicants to display</Subtitle1>
            <BodySmall centered>
              {"Post to LinkedIn (for free) or to 50+ supported job boards to get more applicants"}
            </BodySmall>
          </Stack>
          {jobId && (
            <Button
              variant="primaryOutlined"
              onPress={(): void => {
                navigate(APP_ROUTE_PATHS.job.jobBoards(jobId));
              }}
            >
              Post to job boards
            </Button>
          )}
        </Stack>
      );
    }

    if (variant === "pipeline-view") {
      return (
        <Stack spacing={2} alignItems={"center"}>
          <Stack spacing={0.5}>
            <ReactSVG src={PeopleSVG} style={{ color: colors.grayscale.gray400, fill: colors.grayscale.gray400 }} />
            <Body weight="600">{headingText()}</Body>
            <BodySmall weight="400">Adjust the filters above or add candidates.</BodySmall>
          </Stack>
          <Button
            variant="primaryOutlined"
            onPress={(): void => {
              setIsOpenAddCandidateDrawerParam(true);
              setFlowTypeParam(FlowTypes.INTERVIEW_PIPELINE);
            }}
          >
            + Add candidate
          </Button>
        </Stack>
      );
    }

    if (variant === "todo-list") {
      return (
        <Stack alignItems="center" justifyContent="center" height="100%" width="100%">
          <Stack justifyContent="center" alignItems="center" maxWidth="50%" spacing={0.5} sx={{ textAlign: "center" }}>
            <ReviewListIcon />
            <Heading>{headingText()}</Heading>
            <Body color={colors.grayscale.gray500}>
              {"Review candidates who’ve replied to outreach and completed interviews"}
            </Body>
          </Stack>
        </Stack>
      );
    }
  }

  // If jobId is present and Dover Outbound is not enabled

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={2}>
      <Heading>{headingText()}</Heading>
      <Card width={"55%"} maxWidth="560px">
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
            <CircleBullet>1</CircleBullet>
            {variant !== "pipeline-view" ? (
              <BodySmall weight="500">
                Post to more{" "}
                <LegacyButton
                  removePadding
                  variant={ButtonVariant.Link}
                  onClick={(): void => {
                    navigate(
                      APP_ROUTE_PATHS.job.jobBoards(
                        jobId! // should be type safe since we are checking for jobId above
                      )
                    );
                  }}
                >
                  job boards
                </LegacyButton>{" "}
                to get applicants
              </BodySmall>
            ) : (
              <BodySmall weight="500">Try adjusting your filters</BodySmall>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
            <Stack direction="row" spacing={2} alignItems="flex-start" width={"80%"}>
              <CircleBullet>2</CircleBullet>
              <Stack spacing={1} alignItems="flex-start">
                <BodySmall color={colors.grayscale.gray500} style={{ textAlign: "left" }}>
                  Connect with a Recruiting Partner who can help with sourcing
                </BodySmall>
                <Button
                  variant="primaryOutlined"
                  onPress={(): void => {
                    navigate(APP_ROUTE_PATHS.recruitingPartners());
                  }}
                >
                  Learn more
                  <GreenArrowRight width={"14px"} />
                </Button>
              </Stack>
            </Stack>
            <NoCandidatesUpsellIcon width={"120px"} height={"120px"} />
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default NoCandidatesEmptyState;
