/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackReminderState,
    FeedbackReminderStateFromJSON,
    FeedbackReminderStateFromJSONTyped,
    FeedbackReminderStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmitFeedbackReminderResponse
 */
export interface SubmitFeedbackReminderResponse {
    /**
     * 
     * @type {Array<FeedbackReminderState>}
     * @memberof SubmitFeedbackReminderResponse
     */
    results: Array<FeedbackReminderState>;
}

export function SubmitFeedbackReminderResponseFromJSON(json: any): SubmitFeedbackReminderResponse {
    return SubmitFeedbackReminderResponseFromJSONTyped(json, false);
}

export function SubmitFeedbackReminderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitFeedbackReminderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(FeedbackReminderStateFromJSON)),
    };
}

export function SubmitFeedbackReminderResponseToJSON(value?: SubmitFeedbackReminderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(FeedbackReminderStateToJSON)),
    };
}


