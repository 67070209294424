import { Box, Stack } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import React, { FC } from "react";

import { ReactComponent as ScoringAppsSVG } from "assets/icons/scoring-apps.svg";
import { Body } from "components/library/typography";
import LoadingEllipsis from "components/loading-overlay/LoadingEllipsis";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import {
  SaapReviewApplication,
  JobFeatureFeatureNameEnum,
  SaapReviewApplicationCandidateBucketLabelEnum,
} from "services/openapi";
import { hasFiltersAppliedAtom } from "views/candidates/ApplicationReview/atoms/filters";
import { isMainPanelOpenMobileAtom } from "views/candidates/ApplicationReview/atoms/mobileFriendly";
import {
  deselectApplicationAtom,
  multiSelectEnabledAtom,
  multiSelectIdMapAtom,
  selectApplicationAtom,
} from "views/candidates/ApplicationReview/atoms/multiSelect";
import { selectedApplicantListTabAtom } from "views/candidates/ApplicationReview/atoms/selectedApplicantListTab";
import { ApplicantCard } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantCard";
import MultiSelectControls from "views/candidates/ApplicationReview/components/ApplicantListPanel/MultiSelectControls";
import { NoExactMatchesCard } from "views/candidates/ApplicationReview/components/ApplicantListPanel/NoExactMatchesCard";
import { useApplications } from "views/candidates/ApplicationReview/hooks/useApplications";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";
import { useSelectedTabCount } from "views/candidates/ApplicationReview/hooks/useSelectedTabCount";
import { isApplicantEligibleForBulkReject } from "views/candidates/ApplicationReview/utils/isApplicantEligibleForBulkReject";
import { markApplicantAsViewed } from "views/candidates/ApplicationReview/utils/viewedApplicants";

interface ApplicantListProps {
  selectedApplication: SaapReviewApplication | undefined;
  setSelectedApplicationId: (application: string | undefined) => void;
}

export const ApplicantList: FC<React.PropsWithChildren<ApplicantListProps>> = ({
  selectedApplication,
  setSelectedApplicationId,
}) => {
  const jobId = useJobIdFromUrl();

  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);
  const multiSelectIdMap = useAtomValue(multiSelectIdMapAtom);
  const selectApplication = useSetAtom(selectApplicationAtom);
  const deselectApplication = useSetAtom(deselectApplicationAtom);
  const setIsMainPanelOpenMobile = useSetAtom(isMainPanelOpenMobileAtom);
  const { applications, isFetching } = useApplications();
  const count = useSelectedTabCount();
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();
  const selectedTab = useAtomValue(selectedApplicantListTabAtom);
  const isMatchingTabSelected = selectedTab === "Matching";

  const hasBestMatches = applications?.some(
    (application: SaapReviewApplication) =>
      application.candidateBucketLabel === SaapReviewApplicationCandidateBucketLabelEnum.BestMatch
  );

  const isJobE2eEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  // if any filters are applied we should avoid showing the "no best matches found" card
  // because maybe best matches do exist but are just filtered out by the filters
  // note this is just a quick fix as app review is likely to change drastically soon after which we
  // won't need this anymore
  const filtersApplied = useAtomValue(hasFiltersAppliedAtom);

  const showNoExactMatchesCard =
    jobId &&
    !isFetching &&
    jobHasScoringEnabled &&
    // if some people are returned (non best matches) or the selected tab is 'Matching' show the card
    (!!count || isMatchingTabSelected) &&
    !hasBestMatches &&
    !filtersApplied;

  if (isFetching) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <ScoringAppsSVG />
        <Stack direction="row" justifyContent="flex-start">
          <Body>Scoring applicants</Body>
          {/* Give it a box for consistent width (otherwise it grows and shrinks with num ellipsis) */}
          <Box width="18px">
            <LoadingEllipsis />
          </Box>
        </Stack>
      </Stack>
    );
  }

  if (!applications?.length) {
    return <>{showNoExactMatchesCard && <NoExactMatchesCard />}</>;
  }

  const onClick = (application: SaapReviewApplication): void => {
    if (!application?.inboundAppId) {
      return;
    }

    // Mark as viewed when selected
    markApplicantAsViewed(application.inboundAppId);

    if (multiSelectEnabled) {
      // Deselect
      if (multiSelectIdMap[application.inboundAppId]) {
        deselectApplication(application.inboundAppId);
        // Select
      } else if (application.candidateId) {
        // Make sure the candidate is eligble for bulk reject
        if (!isApplicantEligibleForBulkReject(application, isJobE2eEnabled)) {
          return;
        }

        selectApplication({
          candidateId: application.candidateId,
          applicationId: application.inboundAppId,
          candidateActionVersion: application.candidateActionVersion,
          schedulingOwnership: application.schedulingOwnership,
        });

        setSelectedApplicationId(application?.inboundAppId);
      }
    } else {
      setSelectedApplicationId(application?.inboundAppId);
      setIsMainPanelOpenMobile(true);
    }
  };

  return (
    <Stack height="100%" sx={{ overflowY: "auto" }}>
      <MultiSelectControls />
      {showNoExactMatchesCard && <NoExactMatchesCard />}
      {applications?.map(application => {
        const selected = multiSelectEnabled
          ? multiSelectIdMap[application.inboundAppId]
          : application === selectedApplication;

        const disabled = multiSelectEnabled && !isApplicantEligibleForBulkReject(application, isJobE2eEnabled);

        return (
          <ApplicantCard
            key={application.inboundAppId}
            application={application}
            selected={selected}
            disabled={disabled}
            onClick={(): void => onClick(application)}
          />
        );
      })}
    </Stack>
  );
};
