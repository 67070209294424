import { Box, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";

import Banner from "components/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { useModal } from "GlobalOverlays/atoms";
import { useJobId } from "hooks/useJobIdFromUrl";
import {
  selectFromListCampaignsQueryResult,
  useGenerateAndSaveCampaignContentMutation,
  useListCampaignsQuery,
} from "services/doverapi/endpoints/campaign";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { DoverUserGmailCredentialAuthStateEnum } from "services/openapi";
import CampaignCardV2 from "views/job/JobSetup/steps/CampaignVariants/CampaignCardV2";
import { CampaignDrawerAtom } from "views/job/JobSetup/steps/CampaignVariants/CampaignDrawer";
import { useShouldAttemptCampaignOutreachGeneration } from "views/job/JobSetup/steps/CampaignVariants/hooks";

const EmailCampaigns = (): React.ReactElement => {
  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const { open: openCampaignDrawer } = useModal(CampaignDrawerAtom);

  const [jobId] = useJobId();
  const { data: jobSetup, isFetching: isFetchingJobSetup } = useGetJobSetupQuery(jobId ?? skipToken);

  const { allCampaigns: campaigns, isSuccess, isFetching: isFetchingCampaigns } = useListCampaignsQuery(
    jobId ? { jobId } : skipToken,
    {
      selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
    }
  );

  const userIsUsedInAnyCampaign = campaigns?.some(campaign => campaign.emailAlias?.email === userInfo?.email);
  const userEmailAuthValid = userInfo?.gmailCredentialAuthState === DoverUserGmailCredentialAuthStateEnum.Valid;

  const shouldAttemptCampaignOutreachGeneration = useShouldAttemptCampaignOutreachGeneration(jobId, false);
  const [
    generateAndSaveCampaignContent,
    { isLoading: isGeneratingCampaignContent },
  ] = useGenerateAndSaveCampaignContentMutation();

  /* Local state */
  const [hasAttemptedToGenerateAndSaveCampaignContent, setHasAttemptedToGenerateAndSaveCampaignContent] = useState<
    boolean
  >(false);

  /* Conditionally generate first outreach */
  useEffect(() => {
    async function generateAndSaveCampaignContentAPI(): Promise<void> {
      await generateAndSaveCampaignContent({
        campaignId: jobSetup?.campaignId!,
      });
      setHasAttemptedToGenerateAndSaveCampaignContent(true);
    }
    if (
      !hasAttemptedToGenerateAndSaveCampaignContent &&
      shouldAttemptCampaignOutreachGeneration &&
      jobSetup?.campaignId
    ) {
      generateAndSaveCampaignContentAPI();
    }
  }, [
    generateAndSaveCampaignContent,
    hasAttemptedToGenerateAndSaveCampaignContent,
    jobSetup?.campaignId,
    shouldAttemptCampaignOutreachGeneration,
  ]);

  if (isGeneratingCampaignContent || isFetchingCampaigns || isFetchingJobSetup) {
    return <Skeleton width="100%" height="160px" />;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Subtitle2>Email Campaigns</Subtitle2>
        <Button variant={ButtonVariant.Primary} onClick={(): void => openCampaignDrawer({})}>
          + Campaign
        </Button>
      </Stack>

      {userIsUsedInAnyCampaign && !userEmailAuthValid && (
        <Banner variant="filled" type="warning" padding="8px">
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall>Link Dover to the Gmail account to send emails from {userInfo?.email}</BodySmall>
            <Button
              variant={ButtonVariant.Link}
              onClick={(): void => {
                window.open(`${appConfig.legacyAppUrl}/utils/auth`, "_blank");
              }}
            >
              Authenticate
            </Button>
          </Stack>
        </Banner>
      )}

      {isSuccess && campaigns && campaigns.length > 0 && (
        <Box className="hide-scrollbar" display="flex" maxWidth="800px" sx={{ overflowX: "auto", flexWrap: "nowrap" }}>
          {campaigns.map(campaign => {
            return <CampaignCardV2 campaign={campaign} />;
          })}
        </Box>
      )}
    </>
  );
};

export default EmailCampaigns;
