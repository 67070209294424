import { Stack } from "@mui/material";
import React from "react";

import QueuedEmailSVG from "assets/icons/queued-email.svg";
import { BodySmall } from "components/library/typography";
import { OutreachActionButtons } from "components/OutreachActionButtons";
import { HiringPipelineStageType, PipelineCandidate, SlimNextCampaignMessageRequestStateEnum } from "services/openapi";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { colorsMap } from "views/CandidateDetail/components/next-action/utils/constants";
import { QueuedEmailReactSVG } from "views/CandidateDetail/styles";

export const CandidateDetailOutboxNextActionBanner = ({
  emailSendAt,
  emailOutreachStep,
  candidate,
}: {
  emailSendAt: string;
  emailOutreachStep: string;
  candidate?: PipelineCandidate;
}): React.ReactElement => {
  const cmrQueued =
    candidate?.candidatePipelineStage?.stageType === HiringPipelineStageType.QUEUED ||
    candidate?.slimNextCampaignMessageRequest?.state === SlimNextCampaignMessageRequestStateEnum.Queued;

  const isFollowup =
    cmrQueued && candidate?.slimNextCampaignMessageRequest?.emailOutreachStep?.toLowerCase().includes("followup");

  const bodyText = cmrQueued
    ? `${emailOutreachStep} queued for ${emailSendAt}`
    : `Use "Queue to send" to send outreach to this candidate`;

  if (!cmrQueued) {
    return (
      <>
        <NextActionCardSkeleton
          title={"NEEDS DECISION"}
          colors={colorsMap.yellow}
          buttons={
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
              <BodySmall>{bodyText}</BodySmall>
              <OutreachActionButtons candidate={candidate} />
            </Stack>
          }
        />
      </>
    );
  }
  return (
    <>
      <NextActionCardSkeleton
        title={isFollowup ? "FOLLOW UP QUEUED" : "QUEUED FOR OUTREACH"}
        colors={colorsMap.blue}
        buttons={
          <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1}>
              <QueuedEmailReactSVG src={QueuedEmailSVG} />
              <BodySmall>{bodyText}</BodySmall>
            </Stack>
            <OutreachActionButtons candidate={candidate} />
          </Stack>
        }
      />
    </>
  );
};
