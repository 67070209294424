import { useSetAtom } from "jotai";
import { React, useEffect } from "react";

import { segmentLoadedAtom } from "App/routing/atoms";
import { setCookie } from "hooks/useIdentifyUser";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useAuth0 } from "services/react-auth0-spa";

export const Segment = () => {
  const { loginAppState } = useAuth0();
  const [desiredFsUid, setDoverFsUid] = useLocalStorage("dover_fs_uid", "");
  const [actualFsUid, setFsUid] = useLocalStorage("_fs_uid", "");

  const setSegmentLoaded = useSetAtom(segmentLoadedAtom);

  useEffect(() => {
    if (loginAppState?.fullstoryCookie) {
      setDoverFsUid(loginAppState.fullstoryCookie);
    }
  }, [loginAppState?.fullstoryCookie, setDoverFsUid]);

  useEffect(() => {
    if (!desiredFsUid) {
      return;
    }

    if (actualFsUid !== desiredFsUid) {
      console.log("Setting fs_uid to", desiredFsUid);
      setFsUid(desiredFsUid);
      setCookie("fs_uid", desiredFsUid);
    }
  }, [desiredFsUid, actualFsUid, setFsUid]);

  if (!actualFsUid && loginAppState?.fullstoryCookie) {
    console.log("Haven't set fs cookie yet when present, skipping analytics initialization");
    return <></>;
  }

  // Skip anonymous pages
  const inboundApplyRegex = new RegExp("^(/apply/.*)", "i");
  const careersPageRegex = new RegExp("^(/.*/careers/.*)", "i");
  const newCareersRegex = new RegExp("^(/.*/jobs/.*)", "i");
  // Skip analytics tracking via Segment for certain pages
  if (
    // Skip our candidate-facing inbound-apply UIs + careers page.
    // These pages cause us to exceed our max MTU's in segment since we end up creating so many anonymous users
    !inboundApplyRegex.test(window.location.pathname) &&
    !careersPageRegex.test(window.location.pathname) &&
    !newCareersRegex.test(window.location.pathname) &&
    // // Don't fire events when developing locally
    window.location.host !== "localhost:3000" &&
    // Don't fire events when developing in Ondemand
    !window.location.origin.includes("fullstackrandomizedtesting.com")
  ) {
    console.log("Initializing analytics tracking");
    setSegmentLoaded(c => c + 1);
    window.my_release = "%REACT_APP_RELEASE%";
    !(function() {
      var analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked) window.console && console.log && console.log("Segment snippet included twice.");
        else {
          analytics.invoked = !0;
          analytics.methods = [
            "trackSubmit",
            "trackClick",
            "trackLink",
            "trackForm",
            "pageview",
            "identify",
            "reset",
            "group",
            "track",
            "ready",
            "alias",
            "debug",
            "page",
            "once",
            "off",
            "on",
          ];
          analytics.factory = function(t) {
            return function() {
              var e = Array.prototype.slice.call(arguments);
              e.unshift(t);
              analytics.push(e);
              return analytics;
            };
          };
          for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e);
          }
          analytics.load = function(t, e) {
            var n = document.createElement("script");
            n.type = "text/javascript";
            n.async = !0;
            n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(n, a);
            analytics._loadOptions = e;
          };
          analytics.SNIPPET_VERSION = "4.1.0";
          analytics.load("ucHrrGi0qp3xuzdyhn0ElEjWR78n8Wt0");
          analytics.page();
        }
    })();
  } else {
    console.debug("Page does not support analytics tracking");
  }

  return <></>;
};
