import { Box, Stack } from "@mui/material";
import React from "react";

import { AccountSettingsSectionType } from "App/routing/types";
import { ClientPickerWarningBanner } from "components/dover/ClientPickerWarningBanner";
import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { ConnectedCard } from "components/dover/OnlineAccounts/ConnectedCard";
import { ConnectEmailCard } from "components/dover/OnlineAccounts/ConnectEmailCard";
import { LoadingRelative } from "components/HotLoading";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { accountSettingsConfiguration } from "views/CompanySetup/constants";

const EmailConnectionCard = (): React.ReactElement => {
  const { userAuthedSuccessfully: authed, isGmailAuthLoading: isLoading } = useUserAuthedSuccessfully();

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "440px" }}>
        <LoadingRelative />
      </Box>
    );
  }
  return (
    <SectionWrapper config={accountSettingsConfiguration[AccountSettingsSectionType.EMAIL_CONNECTION]}>
      <Stack spacing={1}>
        <ClientPickerWarningBanner
          warningText={`The client picker is currently set to a different client. You can still auth your own email, but not for anyone else.`}
        />
        {authed ? <ConnectedCard /> : <ConnectEmailCard />}
      </Stack>
    </SectionWrapper>
  );
};

export default EmailConnectionCard;
