import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as BulbIconSVG } from "assets/icons/bulb-icon.svg";
import { LearnMoreLink } from "components/library/LearnMoreLink";

export const AddCandidateCTA = ({
  description,
  linkUrl,
}: {
  description: string;
  linkUrl: string;
}): React.ReactElement => {
  return (
    <Stack
      alignItems="center"
      sx={{ borderRadius: "4px", backgroundColor: "#F4F6F8", height: "44px", padding: "12px 16px" }}
      p={1}
      spacing={1}
      direction="row"
    >
      <BulbIconSVG />
      <LearnMoreLink description={description} linkUrl={linkUrl} />
    </Stack>
  );
};
