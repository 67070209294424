export const maxFileSizeUpload = 5 * 1024 * 1024; // 5 MB
export const CAMPAIGN_MESSAGE_LIMIT = 5;
export const INVALID_PERMS_TOOLTIP_TEXT =
  "This action is restricted due to your permission level. Please contact a company admin to grant you access.";

export const GEORGE_ONLY_CALENDLY = "https://calendly.com/dover-team/dover-intro-2";
export const BDR_ONLY_CALENDLY = "https://calendly.com/dover-team/dover-onboarding";

export const SOURCING_EXTENSION_STORE_URL = "https://chromewebstore.google.com/detail/knjnhpcmdnakigmnekianbpmhdfkejom";
export const SOURCING_EXTENSION_HELP_ARTICLE_URL =
  "https://help.dover.com/en/articles/6301760-dover-sourcing-extension";
