import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useParams } from "react-router-dom";

import DeactivateFeatureConfirmationModal from "components/dover/feature-toggle-handler/DeactivateFeatureConfirmationModal";
import { ServiceFeatureModalProps } from "components/dover/job-feature-card/types";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { appConfig } from "config/appConfig";
import {
  useGetJobFeaturesQuery,
  useUpsertJobFeatureSettingMutation,
} from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import {
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { B1_doNotUse, ExternalLink } from "styles/typography";
import { showErrorToast } from "utils/showToast";

const DeactivateDoverInterviewerModal = ({
  isModalOpen,
  toggleModalOff,
}: ServiceFeatureModalProps): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();

  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
      };
    },
  });

  const modalText = React.useMemo(() => {
    if (!jobFeatures) {
      return (
        <Box display="flex" justifyContent="center">
          <DoverLoadingSpinner minHeight="40px" height="40px" spinnerSize="30px" width="40px" />
        </Box>
      );
    }

    if (jobFeatures[JobFeatureFeatureNameEnum.E2EScheduling]) {
      return (
        <Stack spacing={3}>
          <BodySmall>
            {
              "Disabling will not change the assigned interviewer for any candidates that have been or are in the process of being scheduled."
            }
          </BodySmall>
        </Stack>
      );
    } else {
      return (
        <Stack spacing={3}>
          <B1_doNotUse>
            {
              "All approved candidates moving forward will be scheduled with the first round interviewer listed in your interview plan. All existing interviews scheduled or in the process of being scheduled with a Dover Interviewer will still be conducted with a Dover Interviewer."
            }
          </B1_doNotUse>
          <B1_doNotUse>
            {"If you'd like to add new candidates to the pipeline, you can still do so using the "}
            <ExternalLink
              display="inline"
              href={"https://help.dover.com/en/articles/6301760-dover-sourcing-extension"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Sourcing Extension
            </ExternalLink>
            {" or "}
            <ExternalLink
              display="inline"
              href={`${appConfig.appUrl}/add-candidate`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Add Candidate.
            </ExternalLink>
            {
              " However, you will need to connect your work email to Dover and handle responses and scheduling yourself. "
            }
            <ExternalLink
              display="inline"
              href={"https://help.dover.com/en/articles/6480907-when-does-dover-use-my-work-email"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </ExternalLink>
          </B1_doNotUse>
          <B1_doNotUse>
            {
              "If you're looking to deactivate this job entirely, you can do so under Manage Job > Deactivate Job in the Job Overview page. Note that you will no longer be able to use any features for this job."
            }
          </B1_doNotUse>
        </Stack>
      );
    }
  }, [jobFeatures]);

  const onConfirmDisable = React.useCallback(() => {
    const tryUpsertFeatureSetting = async (): Promise<void> => {
      upsertJobFeatureSetting({
        upsertData: {
          data: {
            job: jobId!,
            featureName: UpsertJobFeatureSettingFeatureNameEnum.DoverInterviewer,
            state: UpsertJobFeatureSettingStateEnum.Disabled,
          },
        },
      })
        .unwrap()
        .catch(err => {
          console.error(err);
          showErrorToast("Failed to update job feature. Please refresh and try again.");
        });

      toggleModalOff();
    };

    tryUpsertFeatureSetting();
  }, [jobId, toggleModalOff, upsertJobFeatureSetting]);

  return (
    <DeactivateFeatureConfirmationModal
      isModalOpen={isModalOpen}
      title="Deactivate Dover Interviewer?"
      body={modalText}
      toggleModalOff={toggleModalOff}
      onConfirmDisable={onConfirmDisable}
    />
  );
};

export default DeactivateDoverInterviewerModal;
