import { Over, useDndContext, useDndMonitor, useDroppable } from "@dnd-kit/core";
import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC, ReactNode, useEffect, useRef } from "react";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { ButtonVariant } from "components/library/Button";
import { Overline } from "components/library/typography";
import { AddCandidateButton } from "sections/addcandidate";
import { HiringPipelineStage, PipelineCandidate } from "services/openapi";
import { colors } from "styles/theme";
import { isAppReviewStage } from "utils/isStage";
import { getCandidateFromMapAtom } from "views/candidates/CandidateTable/board/atoms";
import { expandParams } from "views/candidates/CandidateTable/board/CandidatesBoard";
import Page from "views/candidates/CandidateTable/board/components/Page";
import { BOARD_LIST_CANDIDATES_LIMIT } from "views/candidates/CandidateTable/board/hooks";
import { OpenAppReviewButton } from "views/candidates/CandidateTable/components/OpenAppReviewButton";

interface ColumnProps {
  stage: HiringPipelineStage;
  count: number; // The total number of candidates in this column
}

const Column: FC<React.PropsWithChildren<ColumnProps>> = ({ stage, count }) => {
  // Total number of pages we could potentially render
  // render at least 1 page to show the empty state
  const numPages = Math.max(1, Math.ceil(count / BOARD_LIST_CANDIDATES_LIMIT));
  const lastIndex = numPages - 1; // The index of the last page
  const lastPageCount = count % BOARD_LIST_CANDIDATES_LIMIT; // How many items the last page will have

  const [collapsed, setCollapsed] = React.useState(stage?.name === "Contacted" || count === 0);
  useEffect(() => {
    if (count > 0) {
      setCollapsed(false);
    }
  }, [count]);

  const { over, setNodeRef } = useDroppable({
    id: stage.id,
    data: { stageId: stage.id },
    resizeObserverConfig: {
      disabled: false,
      updateMeasurementsFor: [],
    },
  });

  const getCandidate = useAtomValue(getCandidateFromMapAtom);
  // active dragging candidate
  const [activeCandidate, setActiveCandidate] = React.useState<PipelineCandidate | undefined>(undefined);

  // state set in timeout to prevent flickering
  const overRef = useRef<Over | null>(null);
  const [previewCandidate, setPreviewCandidate] = React.useState<PipelineCandidate | undefined>(undefined);
  const previewCandidateRef = useRef<PipelineCandidate | undefined>(undefined);

  useEffect(() => {
    overRef.current = over;
  }, [over]);

  useEffect(() => {
    previewCandidateRef.current = activeCandidate;
  }, [activeCandidate]);

  const delayedSetPreviewCandidate = (): void => {
    if (overRef.current?.id.toString() === stage.id) {
      setPreviewCandidate(previewCandidateRef.current);
    } else {
      setPreviewCandidate(undefined);
    }
  };

  const { measureDroppableContainers } = useDndContext();
  useDndMonitor({
    onDragStart(event) {
      const candidate = getCandidate(event.active.id.toString());
      setActiveCandidate(candidate);
      // can immediate show the preview card for the stage the candidate is being dragged from
      if (event.active.data.current?.["stageId"] === stage.id) {
        setPreviewCandidate(candidate);
      }
    },
    onDragMove(event) {
      // remeasure containers so on drop it doesn't jump
      if (event.collisions?.some(c => c.id.toString() === stage.id)) {
        measureDroppableContainers(event.collisions.map(c => c.id));
      }

      // expand any column that has a candidate being dragged over it
      if (event.over?.id.toString() === stage.id) {
        setCollapsed(false);
      }
    },
    onDragOver(_) {
      setTimeout(delayedSetPreviewCandidate, 60);
    },
    onDragEnd(_) {
      setPreviewCandidate(undefined);
    },
  });

  const stageIsAppReview = isAppReviewStage(stage); // Calculate this here because it will be the same for the whole column

  const pageContent = (index: number): ReactNode => (
    <Stack spacing={1} mb={1}>
      <Page
        key={index}
        stageId={stage.id}
        page={index}
        count={index === lastIndex ? lastPageCount : BOARD_LIST_CANDIDATES_LIMIT}
        showMatchLabel={stageIsAppReview}
        expand={expandParams(stage.name)}
        previewCandidate={previewCandidate}
      />
    </Stack>
  );

  return (
    <Stack
      spacing={0.5}
      px={1}
      borderRight={`1px solid ${colors.grayscale.gray200}`}
      width={collapsed ? "50px" : undefined}
      ref={setNodeRef}
    >
      <Stack
        direction={collapsed ? "row-reverse" : "row"}
        alignItems="center"
        justifyContent="space-between"
        onClick={(): void => setCollapsed(!collapsed)}
        sx={{ cursor: "pointer", transform: collapsed ? "rotate(-90deg)" : undefined }}
        mt={collapsed ? "10px" : undefined}
      >
        <Overline noWrap color={colors.grayscale.gray500}>
          {genColumnTitle(stage, count)}
        </Overline>
        {!collapsed && (
          <AddCandidateButton
            iconOnly
            buttonProps={{
              variant: ButtonVariant.Ghost,
              removePadding: true,
              removeOutline: true,
            }}
            hiringPipelineStageId={stage.id}
          />
        )}
      </Stack>
      {stageIsAppReview && !collapsed && (
        <Stack py={0.5} px={1}>
          <OpenAppReviewButton fullWidth />
        </Stack>
      )}
      <ColumnWrapper isOver={!!previewCandidate} hidden={collapsed}>
        <Virtuoso style={{ height: "100%" }} totalCount={numPages} itemContent={pageContent} overscan={1500} />
      </ColumnWrapper>
    </Stack>
  );
};

const genColumnTitle = (stage: HiringPipelineStage, count: number): string => {
  return `${stage.name} (${count})`;
};

interface ColumnWrapperProps {
  isOver: boolean;
  hidden: boolean;
}

const ColumnWrapper = styled.div<ColumnWrapperProps>`
  display: ${({ hidden }): string => (hidden ? "none" : "block")};
  height: 100%;
  width: 275px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${({ isOver }): string | undefined => (isOver ? colors.grayscale.gray150 : undefined)};
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

export default Column;
