import { SerializedError } from "@reduxjs/toolkit";
import React from "react";
import { toast } from "react-toastify";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { unknownSeniorityOption } from "services/doverapi/endpoints/search-v3/constants";
import {
  CompanyListWithRequiredID,
  LocationWithValue,
  NewCompanyList,
  NewSchoolList,
  PersonaIDsRequired,
  SchoolListWithRequiredID,
  TitlePatternIDsRequired,
} from "services/doverapi/endpoints/search-v3/types";
import {
  COMPANY_LIST,
  LIST_TAG,
  SCHOOL_LIST,
  SEARCH_V3,
  SEARCH_V3_CHANGE_LOG,
  PERSONAS_V2,
  TITLE_PATTERNS,
  PATTERNS_FOR_PERSONA,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiGetSearchV3Request,
  ApiApiListSearchV3Request,
  ApiApiListSearchV3ProfileResultsRequest,
  Company,
  Country,
  ProfileResponse,
  SearchV3,
  ApiApiListPersonasRequest,
  Seniority,
  Title,
  ListCompanySizeSerializer,
  ApiApiGetSearchV3DepthResultRequest,
  DepthResponse,
  ApiApiListProfileSearchKeywordsRequest,
  ProfileSearchKeywordSerializer,
  School,
  ApiApiListProfileSearchIndustriesRequest,
  ProfileSearchIndustrySerializer,
  DiversityOption,
  ApiApiListSimilarCompaniesRequest,
  ApiApiListCompanyListsRequest,
  ApiApiListSimilarSchoolsRequest,
  ProfileSearchLocationSerializer,
  WorldRegionResponse,
  ListStatesResponse,
  SchoolList,
  CompanyList,
  ApiApiPartialUpdateSearchV3Request,
  SearchV3PersonaQuestionnaire,
  UpdateSearchFromFeaturesSuccess,
  ApiApiUpdateSearchFromFeaturesOperationRequest,
  CreateReengagementTransformResponseSuccess,
  ChangeLogEntry,
  ApiApiPartialUpdateSchoolListRequest,
  ApiApiPartialUpdateCompanyListRequest,
  RegisterSaapReviewedCandidateRequest,
  RegisterSimilarCandidateRequest,
  TitlePatternInfo,
  ApiApiListTitlePatternsRequest,
  ModifyTitlePatternsInSearchResponse,
  ApiApiModifyTitlePatternsInSearchOperationRequest,
  SearchIDAndNameAndJob,
  OtherSearchesUsingPersonaRequest,
  KeywordWithBucketIndicesRequest,
  KeywordWithBucketIndicesResponse,
  GetPersonaForJobResponse,
  GetPersonaForJobRequest,
} from "services/openapi";
import { showErrorToast, showSuccessToast, toastOptions } from "utils/showToast";
import { personasToPersonasIDsRequiredConverter } from "views/sourcing/Search/hooks";

const searchV3Endpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listSearchesV3: build.query<SearchV3[], ApiApiListSearchV3Request>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listSearchV3(args);
          return { data: response.results };
        } catch (error) {
          showErrorToast("Couldn't load searches. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: SEARCH_V3, id: LIST_TAG } as const] : [];
      },
    }),
    getSearchV3: build.query<SearchV3, ApiApiGetSearchV3Request>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.getSearchV3(args);
          return { data: response };
        } catch (error) {
          showErrorToast("Couldn't load this search. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: SEARCH_V3, id: result.id } as const] : [];
      },
    }),
    cloneSearchV3: build.mutation<string, string>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.cloneSearchV3({ data: { searchId: args } });
          return { data: response.searchId };
        } catch (error) {
          showErrorToast("Couldn't clone the search. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result
          ? [
              { type: SEARCH_V3, id: LIST_TAG }, // invalidate the list
            ]
          : [];
      },
    }),
    reEngageSearchV3: build.mutation<CreateReengagementTransformResponseSuccess, string>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.createReengagementTransform({ data: { searchId: args } });
          return { data: response };
        } catch (error) {
          let userFacingMessage: string;
          if (error.status === 400) {
            const errorMessage = await error.json();
            userFacingMessage = "Failed to create reengagement search: " + errorMessage.error;
          } else {
            userFacingMessage = "Failed to create reengagement search. Please try again later.";
          }
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    partialUpdateSearchV3: build.mutation<SearchV3, ApiApiPartialUpdateSearchV3Request>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.partialUpdateSearchV3(args);
          showSuccessToast("Search updated successfully");
          return { data: response };
        } catch (error) {
          showErrorToast("Couldn't update the search. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result
          ? [
              { type: SEARCH_V3, id: result.id },
              { type: SEARCH_V3, id: LIST_TAG }, // need to invalidate both, so that we list searchv3 correctly
            ]
          : [];
      },
    }),
    addCampaignToSearch: build.mutation<{ success: boolean }, { campaignId: string; searchId: string }>({
      queryFn: async ({ campaignId, searchId }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.addCampaignToSearch({ id: searchId, data: { campaignId } });
          return { data: response };
        } catch (error) {
          showErrorToast("Couldn't add campaign to the search. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [
              { type: SEARCH_V3, id: args.searchId },
              { type: SEARCH_V3, id: LIST_TAG },
            ]
          : [];
      },
    }),
    removeCampaignFromSearch: build.mutation<{ success: boolean }, { campaignId: string; searchId: string }>({
      queryFn: async ({ campaignId, searchId }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.removeCampaignFromSearch({ id: searchId, data: { campaignId } });
          return { data: response };
        } catch (error) {
          showErrorToast("Couldn't remove campaign from the search. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [
              { type: SEARCH_V3, id: args.searchId },
              { type: SEARCH_V3, id: LIST_TAG },
            ]
          : [];
      },
    }),
    createSearchFromPersonaQuestionnaire: build.mutation<
      { searchId: string },
      { answers: object; jobId: string; personaQuestionnaireId: string; searchName: string }
    >({
      queryFn: async ({ answers, jobId, personaQuestionnaireId, searchName }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.createSearchFromPQ({
            data: { answers, jobId, personaQuestionnaireId, searchName },
          });
          return { data: response };
        } catch (error) {
          showErrorToast("Couldn't save the questionnaire. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: SEARCH_V3, id: LIST_TAG }] : [];
      },
    }),
    createSearchV3: build.mutation<SearchV3, SearchV3>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.createSearchV3({
            data: args,
          });
          return { data: response };
        } catch (error) {
          showErrorToast("Couldn't create the search. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: SEARCH_V3, id: LIST_TAG }] : [];
      },
    }),
    listSearchV3Profiles: build.query<ProfileResponse[], ApiApiListSearchV3ProfileResultsRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listSearchV3ProfileResults(args);
          return { data: response };
        } catch (error) {
          showErrorToast("Couldn't load profiles for this search. Please try refreshing the page");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: SEARCH_V3, id: LIST_TAG } as const] : [];
      },
    }),
    listLocations: build.query<ProfileSearchLocationSerializer[], void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listLocations();
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load location options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listStates: build.query<ListStatesResponse[], void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});
        try {
          // TODO (trevor) - we likely want to revisit this to account for country codes
          const response = await apiApi.listStates();
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load state options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listCountries: build.query<Country[], void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listCountries();
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load country options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listWorldRegions: build.query<WorldRegionResponse[], void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listWorldRegions();
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load world region options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listPersonas: build.query<PersonaIDsRequired[], ApiApiListPersonasRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listPersonas(args);
          const personas = response.results;
          const requiredIDs = personasToPersonasIDsRequiredConverter(personas);

          return { data: requiredIDs };
        } catch (error) {
          showErrorToast("Failed to load persona options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: PERSONAS_V2, id: LIST_TAG }] : [];
      },
    }),
    listTitlePatterns: build.query<TitlePatternIDsRequired[], ApiApiListTitlePatternsRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listTitlePatterns(args);
          const titlePatterns = response.results;

          const titlePatternsIDsRequired = titlePatterns
            .filter(pattern => !!pattern.id)
            .map(pattern => {
              return {
                id: pattern.id!,
                pattern: pattern.pattern,
                allowSoftMatch: undefined,
              };
            });

          return { data: titlePatternsIDsRequired };
        } catch (error) {
          showErrorToast("Failed to load title pattern options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: TITLE_PATTERNS, id: LIST_TAG }] : [];
      },
    }),
    createTitlePattern: build.mutation<TitlePatternIDsRequired | null, string>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.createTitlePattern({ data: { pattern: args } });
          if (!response.id) {
            return { data: null };
          }
          return {
            data: { id: response.id!, pattern: response.pattern, allowSoftMatch: undefined } as TitlePatternIDsRequired,
          };
        } catch (error) {
          showErrorToast("Failed to create title pattern. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: TITLE_PATTERNS, id: LIST_TAG }] : [];
      },
    }),
    listTitlePatternsForPersona: build.query<TitlePatternInfo[], string>({
      queryFn: async personaId => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listTitlePatternsForPersona({ data: { personaId: personaId } });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load titles. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: PATTERNS_FOR_PERSONA, id: LIST_TAG }] : [];
      },
    }),
    listSearchesUsingPersona: build.query<SearchIDAndNameAndJob[], OtherSearchesUsingPersonaRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listOtherSearchesUsingPersona({ data: args });
          return { data: response.searches };
        } catch (error) {
          showErrorToast("Failed to load searches using persona. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    modifyTitlePatternsInSearch: build.mutation<
      ModifyTitlePatternsInSearchResponse,
      ApiApiModifyTitlePatternsInSearchOperationRequest
    >({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.modifyTitlePatternsInSearch(args);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to save title modifications. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result
          ? [
              { type: SEARCH_V3, id: result.searchId },
              { type: PERSONAS_V2, id: LIST_TAG },
              { type: PATTERNS_FOR_PERSONA, id: LIST_TAG },
              { type: SEARCH_V3, id: LIST_TAG },
              { type: SEARCH_V3_CHANGE_LOG, id: LIST_TAG },
            ]
          : [];
      },
    }),
    listSeniorities: build.query<Seniority[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listSeniorities();

          return { data: [unknownSeniorityOption, ...response] };
        } catch (error) {
          showErrorToast("Failed to load seniority options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listTitles: build.query<Title[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listTitles();

          return { data: [...response] };
        } catch (error) {
          showErrorToast("Failed to load titles. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listCompanySizes: build.query<ListCompanySizeSerializer[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listCompanySizes();
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load company size options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listIndividualCompanies: build.query<Company[], ApiApiListSimilarCompaniesRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listSimilarCompanies(args);
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load company options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listCompanyLists: build.query<CompanyListWithRequiredID[], ApiApiListCompanyListsRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = (await apiApi.listCompanyLists(args)).results;
          const transformedCompanyLists: CompanyListWithRequiredID[] = response
            .filter(companyList => companyList.id && companyList.id !== undefined)
            .map(companyList => {
              return {
                id: companyList.id!,
                scopedToClient: companyList.scopedToClient,
                name: companyList.name,
                paramsJson: companyList.paramsJson,
              };
            });
          return { data: transformedCompanyLists };
        } catch (error) {
          showErrorToast("Failed to load company list options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: COMPANY_LIST }] : [];
      },
    }),
    listIndividualSchools: build.query<School[], ApiApiListSimilarSchoolsRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listSimilarSchools(args);
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load school options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listSchoolLists: build.query<SchoolListWithRequiredID[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listSchoolList();
          const transformedSchoolLists: SchoolListWithRequiredID[] = response
            .filter(schoolList => schoolList.id && schoolList.id !== undefined)
            .map(schoolList => {
              return {
                id: schoolList.id!,
                scopedToClient: schoolList.scopedToClient,
                name: schoolList.name,
                paramsJson: schoolList.paramsJson,
              };
            });
          return { data: transformedSchoolLists };
        } catch (error) {
          showErrorToast("Failed to load school list options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: SCHOOL_LIST }] : [];
      },
    }),
    getSearchV3DepthResult: build.query<DepthResponse, ApiApiGetSearchV3DepthResultRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.getSearchV3DepthResult(args);
          return { data: response };
        } catch (error) {
          console.error("Failed to load search depth. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: SEARCH_V3, id: LIST_TAG } as const] : [];
      },
    }),
    listProfileSearchKeywords: build.query<ProfileSearchKeywordSerializer[], ApiApiListProfileSearchKeywordsRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listProfileSearchKeywords(args);
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load keyword options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getKeywordsWithBucketIndices: build.query<KeywordWithBucketIndicesResponse[], KeywordWithBucketIndicesRequest[]>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.getKeywordsWithBucketIndices({ data: args });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load keyword data for search. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getOrCreateKeywordWithName: build.mutation<ProfileSearchKeywordSerializer, string>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.getOrCreateKeywordWithName({ data: { name: args } });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to create a keyword with this name. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listBucketChildrenAndAliases: build.query<string[], string[]>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.listBucketChildrenAndAliases({
            data: { keywordsInBucket: args },
          });
          return { data: response.bucketChildrenAndAliases };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listProfileSearchIndustries: build.query<
      ProfileSearchIndustrySerializer[],
      ApiApiListProfileSearchIndustriesRequest
    >({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listProfileSearchIndustries(args);
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load industry options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listDiversityOptions: build.query<DiversityOption[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listDiversityOptions();
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load diversity options. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getPQUsingPersonaV2: build.query<SearchV3PersonaQuestionnaire, { personaId: string }>({
      queryFn: async ({ personaId }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.getPQUsingPersonaV2({ personaId });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to get questionnaire. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    createSchoolList: build.mutation<SchoolList, NewSchoolList>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.createSchoolList({ data: args });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to create school list. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: SCHOOL_LIST }] : [];
      },
    }),
    partialUpdateSchoolList: build.mutation<SchoolList, ApiApiPartialUpdateSchoolListRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.partialUpdateSchoolList(args);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update school list. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: SCHOOL_LIST }] : [];
      },
    }),
    createCompanyList: build.mutation<CompanyList, NewCompanyList>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.createCompanyList({ data: args });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to create company list. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: COMPANY_LIST }] : [];
      },
    }),
    createChangeLogEntry: build.mutation<boolean, { searchId: string }>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.createChangeLogForSearch({ data: args });
          return { data: response.success };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: SEARCH_V3_CHANGE_LOG, id: LIST_TAG }] : [];
      },
    }),
    getChangeLogEntries: build.query<ChangeLogEntry[], { searchId: string }>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.getChangeLogs({ data: args });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to get change logs for search.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: SEARCH_V3_CHANGE_LOG, id: LIST_TAG } as const] : [];
      },
    }),
    partialUpdateCompanyList: build.mutation<CompanyList, ApiApiPartialUpdateCompanyListRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.partialUpdateCompanyList(args);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update company list. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: COMPANY_LIST }] : [];
      },
    }),
    updateSearchFromFeatures: build.mutation<
      UpdateSearchFromFeaturesSuccess,
      ApiApiUpdateSearchFromFeaturesOperationRequest
    >({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.updateSearchFromFeatures(args);
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    registerSaapReviewedCandidateSubmission: build.mutation<{ success: boolean }, RegisterSaapReviewedCandidateRequest>(
      {
        queryFn: async args => {
          try {
            const { apiApi: client } = await getOpenApiClients({});
            await client.registerSaapReviewedCandidateSubmission({ data: args });
            return { data: { success: true } };
          } catch (error) {
            showErrorToast("Failed to register candidate review. Please refresh and try again.");
            return {
              error: {
                serializedError: error as SerializedError,
              },
            };
          }
        },
      }
    ),
    registerSimilarCandidateReview: build.mutation<{ success: boolean }, RegisterSimilarCandidateRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.registerSimilarCandidateReview({ data: args });
          return { data: { success: response.success } };
        } catch (error) {
          showErrorToast("Failed to register candidate review. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getSimilarCandidatesSearches: build.query<SearchV3[], void>({
      queryFn: async () => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.getSimilarCandidatesSearches({});
          return { data: result };
        } catch (error) {
          showErrorToast("Failed to get similar candidate searches. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    removeFailingPeopleFromOutbox: build.mutation<undefined, string>({
      queryFn: async searchId => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.removeFailingPeopleFromOutbox({ data: { searchId: searchId } });
          const numFailingPeople = response.numberOfPeopleRemoved;
          let userFacingMessage: string;
          let removedCandidates = false;
          if (response.message) {
            userFacingMessage = response.message;
            removedCandidates = true;
          } else if (numFailingPeople) {
            userFacingMessage = `Successfully removed ${numFailingPeople} failing people from outbox.`;
            removedCandidates = true;
          } else {
            userFacingMessage = "All existing queued candidates pass updated criteria. You're all set!";
          }
          toast(userFacingMessage, {
            ...toastOptions,
            type: removedCandidates ? toast.TYPE.SUCCESS : toast.TYPE.INFO,
          });
          return { data: undefined };
        } catch (error) {
          showErrorToast("Failed to remove failing candidates from outbox. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getPersonaForJob: build.mutation<GetPersonaForJobResponse, GetPersonaForJobRequest>({
      queryFn: async ({ jobId }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.getPersonaForJob({ data: { jobId } });

          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useListSearchesV3Query,
  useLazyListSearchesV3Query,
  useListSearchV3ProfilesQuery,
  useGetSearchV3Query,
  useLazyGetSearchV3Query,
  useCloneSearchV3Mutation,
  useReEngageSearchV3Mutation,
  usePartialUpdateSearchV3Mutation,
  useListLocationsQuery,
  useListPersonasQuery,
  useLazyListPersonasQuery,
  useListTitlePatternsQuery,
  useCreateTitlePatternMutation,
  useListTitlePatternsForPersonaQuery,
  useListSearchesUsingPersonaQuery,
  useModifyTitlePatternsInSearchMutation,
  useListSenioritiesQuery,
  useListTitlesQuery,
  useGetOrCreateKeywordWithNameMutation,
  useLazyGetKeywordsWithBucketIndicesQuery,
  useListCompanySizesQuery,
  useListIndividualCompaniesQuery,
  useLazyListIndividualCompaniesQuery,
  useListCompanyListsQuery,
  useListIndividualSchoolsQuery,
  useLazyListIndividualSchoolsQuery,
  useListSchoolListsQuery,
  useGetSearchV3DepthResultQuery,
  useListProfileSearchKeywordsQuery,
  useLazyListProfileSearchKeywordsQuery,
  useLazyListBucketChildrenAndAliasesQuery,
  useListProfileSearchIndustriesQuery,
  useLazyListProfileSearchIndustriesQuery,
  useListDiversityOptionsQuery,
  useListCountriesQuery,
  useListStatesQuery,
  useListWorldRegionsQuery,
  useCreateSchoolListMutation,
  usePartialUpdateSchoolListMutation,
  useCreateCompanyListMutation,
  usePartialUpdateCompanyListMutation,
  useAddCampaignToSearchMutation,
  useRemoveCampaignFromSearchMutation,
  useCreateSearchFromPersonaQuestionnaireMutation,
  useGetPQUsingPersonaV2Query,
  useCreateSearchV3Mutation,
  useRemoveFailingPeopleFromOutboxMutation,
  useGetChangeLogEntriesQuery,
  useCreateChangeLogEntryMutation,
  useRegisterSaapReviewedCandidateSubmissionMutation,
  useRegisterSimilarCandidateReviewMutation,
  useGetSimilarCandidatesSearchesQuery,
  useUpdateSearchFromFeaturesMutation,
  useGetPersonaForJobMutation,
} = searchV3Endpoints;

export function useListAllLocations(): LocationWithValue[] | undefined {
  const { data: locationsOptions } = useListLocationsQuery();
  const { data: statesOptions } = useListStatesQuery();
  const { data: countryOptions } = useListCountriesQuery();
  const { data: worldRegionsOptions } = useListWorldRegionsQuery();

  return React.useMemo(() => {
    if (!(!!locationsOptions && !!statesOptions && !!countryOptions && !!worldRegionsOptions)) {
      return undefined;
    }

    const transformedLocations: LocationWithValue[] = locationsOptions.map(location => {
      return { name: location.name, value: location.name };
    });
    const transformedCountries: LocationWithValue[] = countryOptions.map(country => {
      return { name: country.name, value: country.code };
    });
    const transformedStates: LocationWithValue[] = statesOptions
      .map(stateGroup => stateGroup.states.map(state => `${state} (${stateGroup.countryCode})`))
      .flat()
      .filter(state => !transformedCountries.find(country => country.name === state))
      .map(state => {
        return { name: state, value: state };
      });
    const transformedWorldRegions: LocationWithValue[] = worldRegionsOptions.map(worldRegion => {
      return { name: worldRegion.regionName, value: worldRegion.regionName };
    });

    const allLocations: LocationWithValue[] = [
      ...transformedLocations,
      ...transformedStates,
      ...transformedCountries,
      ...transformedWorldRegions,
    ];

    return allLocations;
  }, [countryOptions, locationsOptions, statesOptions, worldRegionsOptions]);
}
