import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo } from "react";

import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  useCreateApplicationQuestionMutation,
  useDeleteApplicationQuestionMutation,
  useListApplicationQuestionsQuery,
  usePartialUpdateApplicationQuestionMutation,
} from "services/doverapi/endpoints/applicationQuestion";
import { ApplicationQuestionInputTypeEnum, ApplicationQuestionQuestionTypeEnum } from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";
import { getInboundFormFields } from "views/inboundExternal/InboundApplication/helpers";
import { ApplicationFormQuestion } from "views/job/JobSetup/steps/JobPosting/components/EditableApplicationForm/types";

export const useEditableApplicationQuestions = (): {
  editedApplicationQuestions: ApplicationFormQuestion[];
  setEditedApplicationQuestions: React.Dispatch<React.SetStateAction<ApplicationFormQuestion[]>>;
  saveDisabled: boolean;
  hasDuplicateQuestion: boolean;
  saveApplicationQuestions: (showToast?: boolean) => Promise<void>;
  fetchingApplicationQuestions: boolean;
} => {
  const [editedApplicationQuestions, setEditedApplicationQuestions] = React.useState<ApplicationFormQuestion[]>([]);

  const jobId = useJobIdFromUrl();
  const { data: applicationQuestions, isFetching: fetchingApplicationQuestions } = useListApplicationQuestionsQuery(
    jobId ? { job: jobId } : skipToken
  );
  const [
    updateApplicationQuestionMutation,
    { isLoading: isUpdatingApplicationQuestion },
  ] = usePartialUpdateApplicationQuestionMutation();
  const [
    deleteApplicationQuestionMutation,
    { isLoading: isDeletingApplicationQuestion },
  ] = useDeleteApplicationQuestionMutation();
  const [
    createNewApplicationQuestionMutation,
    { isLoading: isCreatingApplicationQuestion },
  ] = useCreateApplicationQuestionMutation();

  // Combine the non-editable application questions with the editable application questions
  const allApplicationQuestions: ApplicationFormQuestion[] = useMemo(() => {
    if (!jobId || !applicationQuestions) {
      return [];
    }
    const linkedInUrlQuestion = applicationQuestions.find(
      question => question.questionType === ApplicationQuestionQuestionTypeEnum.LinkedinUrl
    );
    const resumeQuestion = applicationQuestions.find(
      question => question.questionType === ApplicationQuestionQuestionTypeEnum.Resume
    );
    const phoneNumberQuestion = applicationQuestions.find(
      question => question.questionType === ApplicationQuestionQuestionTypeEnum.PhoneNumber
    );

    const defaultQuestions = [
      ...getInboundFormFields(!!linkedInUrlQuestion?.required, jobId, linkedInUrlQuestion, phoneNumberQuestion),
      {
        question: "Resume Upload",
        required: resumeQuestion?.required ?? false,
        inputType: ApplicationQuestionInputTypeEnum.FileUpload,
        questionType: ApplicationQuestionQuestionTypeEnum.Resume,
        editable: true,
        jobId,
        id: resumeQuestion?.id,
      },
    ];
    return [
      ...defaultQuestions.map(question => ({
        ...question,
        jobId,
        editable:
          !!question.questionType &&
          [
            ApplicationQuestionQuestionTypeEnum.LinkedinUrl,
            ApplicationQuestionQuestionTypeEnum.Resume,
            ApplicationQuestionQuestionTypeEnum.PhoneNumber,
          ].includes(question.questionType),
      })),
      ...applicationQuestions
        .filter(question => question.questionType === ApplicationQuestionQuestionTypeEnum.Custom)
        .map(question => ({ ...question, editable: true })),
    ];
  }, [jobId, applicationQuestions]);

  // Initialize the editedApplicationQuestions state with the applicationQuestions from the API
  useEffect(() => {
    if (!jobId || !allApplicationQuestions) {
      return;
    }
    setEditedApplicationQuestions(allApplicationQuestions);
  }, [jobId, allApplicationQuestions]);

  const hasDuplicateQuestion = useMemo(() => {
    const questionSet = new Set();
    return editedApplicationQuestions
      .filter(
        question =>
          question.editable &&
          question.questionType === ApplicationQuestionQuestionTypeEnum.Custom &&
          question.status !== "deleted"
      )
      .some(question => {
        if (questionSet.has(question.question)) {
          return true;
        }
        questionSet.add(question.question);
        return false;
      });
  }, [editedApplicationQuestions]);

  const saveDisabled = useMemo((): boolean => {
    const hasEmptyQuestion = editedApplicationQuestions.some(
      question => question.status !== "deleted" && question.question.trim().length === 0
    );

    // Check if any multiple choice questions have no options or empty options
    const invalidMultipleChoiceQuestion = editedApplicationQuestions.some(
      question =>
        question.questionType === ApplicationQuestionQuestionTypeEnum.Custom &&
        question.inputType === ApplicationQuestionInputTypeEnum.MultipleChoice &&
        (!question.multipleChoiceOptions ||
          question.multipleChoiceOptions.length === 0 ||
          question.multipleChoiceOptions.some(option => option.trim().length === 0))
    );
    return (
      hasDuplicateQuestion ||
      hasEmptyQuestion ||
      invalidMultipleChoiceQuestion ||
      isUpdatingApplicationQuestion ||
      isDeletingApplicationQuestion ||
      isCreatingApplicationQuestion ||
      fetchingApplicationQuestions
    );
  }, [
    editedApplicationQuestions,
    isUpdatingApplicationQuestion,
    isDeletingApplicationQuestion,
    isCreatingApplicationQuestion,
    fetchingApplicationQuestions,
    hasDuplicateQuestion,
  ]);

  const saveApplicationQuestions = async (showToast?: boolean): Promise<void> => {
    const editedApplicationQuestionsWithOrderIndex = [];
    let currentOrderIndex = 0;
    for (let i = 0; i < editedApplicationQuestions.length; i++) {
      const currentApplicationQuestion = { ...editedApplicationQuestions[i] };
      // If the question is not deleted, then it should have an orderIndex
      if (
        currentApplicationQuestion.status !== "deleted" &&
        currentApplicationQuestion.questionType === ApplicationQuestionQuestionTypeEnum.Custom
      ) {
        // If the orderIndex has changed, then the question has been updated
        if (!currentApplicationQuestion.status && currentOrderIndex !== currentApplicationQuestion.orderIndex) {
          currentApplicationQuestion.status = "updated";
        }
        currentApplicationQuestion.orderIndex = currentOrderIndex;
        currentOrderIndex++;
      }
      editedApplicationQuestionsWithOrderIndex.push(currentApplicationQuestion);
    }

    const applicationQuestionsPromises = editedApplicationQuestionsWithOrderIndex.map(
      (question): Promise<any> => {
        if (question.status === "updated" && question.id) {
          return updateApplicationQuestionMutation({
            updatedApplicationQuestion: question,
            applicationQuestionId: question.id,
            jobId: question.jobId,
          }).unwrap();
        } else if (question.status === "new") {
          return createNewApplicationQuestionMutation({ ...question, jobId: question.jobId });
        } else if (question.status === "deleted" && question.id) {
          return deleteApplicationQuestionMutation({
            applicationQuestionId: question.id,
            jobId: question.jobId,
          });
        }
        return Promise.resolve();
      }
    );

    try {
      await Promise.all(applicationQuestionsPromises);
      if (showToast) {
        showSuccessToast("Successfully submitted application form updates...");
      }
    } catch {
      showErrorToast("Failed to save changes to application form. Please refresh and try again");

      return;
    }
  };

  return {
    editedApplicationQuestions,
    setEditedApplicationQuestions,
    saveDisabled,
    hasDuplicateQuestion,
    saveApplicationQuestions,
    fetchingApplicationQuestions,
  };
};
