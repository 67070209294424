import {
  AccountSettingsSectionType,
  AddCandidateDrawerTabPaths,
  BillingSessionPathEnum,
  CompanySetupSectionType,
  JobReferralTabPaths,
  NextStepType,
  UserSettingPaths,
} from "App/routing/types";

export const JOB_PATH = "/job/:jobId";
export const CANDIDATE_INTERVIEWS_PATH = "/candidate-interviews";

export const APPLICATION_REVIEW_PATH = "/application-review-v2";
export const DTN_PATH = "/dover-talent-network";

export const convertUrlQueryParamsToString = (searchParams: URLSearchParams): string => "?" + searchParams.toString();

export const FALLBACK_ROUTE = "/*";

export const APP_ROUTE_PATHS = {
  home: (): string => "/home",

  sourcingCopilot: (): string => "/sourcing-copilot",
  doverInterviewer: (): string => "/dover-interviewer",
  recruitingPartners: (): string => "/recruiting-partners",
  embeddedRecruiter: (): string => "/recruiting-partners",
  onboarding: {
    // Onboarding
    accountDetails: (): string => "/onboarding/account-details",
    scheduleOnboarding: (): string => "/onboarding/schedule-onboarding",
    recruitingPartners: (): string => "/onboarding/recruiting-partners",
    atsSetup: (): string => `/onboarding/ats-setup`,
    applicantSorting: (jobId?: string): string => `/onboarding/applicant-sorting${jobId ? `?jobId=${jobId}` : ""}`,
  },
  job: {
    // Default
    job: (jobId: string): string => `/job/${jobId}`,
    // Job Settings
    jobSetupStep: (jobId: string, step: string): string => `/job/${jobId}/${step}`,
    // TODO: Remove this path when george_mini_firehouse is GA
    overview: (jobId: string, queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";
      return `/job/${jobId}/overview` + queryParamStr;
    },
    jobPosting: (jobId: string, queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";
      return `/job/${jobId}/job-posting` + queryParamStr;
    },
    jobBoards: (jobId: string, queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";
      return `/job/${jobId}/job-boards` + queryParamStr;
    },
    applicationReviewV2: (jobId: string, queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";
      return `/job/${jobId}${APPLICATION_REVIEW_PATH}` + queryParamStr;
    },
    // TODO: Remove this path when george_mini_firehouse is GA
    outreach: (jobId: string): string => `/job/${jobId}/outreach`,
    interviewPlan: (jobId: string): string => `/job/${jobId}/interview-plan`,
    reporting: (jobId: string): string => `/job/${jobId}/reporting`,
    // TODO: Remove this path when george_mini_firehouse is GA
    notes: (jobId: string): string => `/job/${jobId}/notes`,
    sourcing: (jobId: string): string => `/job/${jobId}/sourcing`,
    interviewer: (jobId: string): string => `/job/${jobId}/interviewer`,
    embeddedRecruiter: (jobId: string): string => `/job/${jobId}/embedded-recruiter`,
    referrals: (jobId: string): string => `/job/${jobId}/referrals`,

    // Job Creation
    createJob: (context?: string): string => {
      return `${window.location.pathname}?context=${context ?? "DEFAULT"}&createJobDrawerOpen=1&step=create-job`;
    },
    extensionCreateJob: (step?: string): string => {
      if (step) {
        return `/create-job/extension/${step}`;
      }
      return `/create-job/extension`;
    },

    // Feature Setup Flows
    sourcingAutopilotSetup: (step?: string, jobId?: string, queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

      let urlPath = "/create-job/limited-outbound/";
      if (step && jobId) {
        urlPath += `${step}/${jobId}`;
      } else if (step) {
        urlPath += `${step}`;
      } else if (jobId) {
        urlPath += `step/${jobId}`;
      }
      return urlPath + queryParamStr;
    },
    doverInterviewerSetup: (jobId: string, step?: string): string => {
      if (step && jobId) {
        return `/setup/interviewer/${jobId}/${step}`;
      }
      // Will redirect to the correct step if given a jobId
      return `/setup/interviewer/${jobId}`;
    },

    // Job Setting Candidate Pages
    candidates: {
      candidatesTable: (jobId: string, queryParams?: URLSearchParams): string => {
        const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

        return `/job/${jobId}/candidates` + queryParamStr;
      },
      candidateDetail: (jobId: string, candidateId: string, queryParams?: URLSearchParams): string => {
        const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";
        return `/job/${jobId}/candidates/${candidateId}` + queryParamStr;
      },
    },
  },
  // Candidate / Applicant
  candidates: {
    candidatesTable: (queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

      return "/candidates" + queryParamStr;
    },
    candidateDetail: (candidateId: string, queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

      return `/candidates/${candidateId}` + queryParamStr;
    },
    interviewTracker: (candidateId: string, queryParams?: URLSearchParams): string => {
      const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

      return `/interview-tracker/${candidateId}` + queryParamStr;
    },
  },
  review: ({
    candidateId,
    jobId,
    queryParams,
  }: {
    candidateId?: string;
    jobId?: string;
    queryParams?: URLSearchParams;
  }): string => {
    if (jobId && queryParams) {
      queryParams.set("job", jobId);
    }

    const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

    if (candidateId) {
      return `/review/${candidateId}` + queryParamStr;
    }

    return "/review" + queryParamStr;
  },
  applicationReviewV2: (queryParams?: URLSearchParams): string => {
    const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";
    return APPLICATION_REVIEW_PATH + queryParamStr;
  },
  addCandidate: (addCandidateDrawerTab?: AddCandidateDrawerTabPaths): string => {
    if (addCandidateDrawerTab) {
      return `/candidates?addCandidateDrawerTab=${addCandidateDrawerTab}`;
    }
    return "/candidates?addCandidateDrawerTab=outreach";
  },
  inboundApplication: (inboundApplicationId: string): string => `/inbound-application/${inboundApplicationId}`,
  referrals: {
    uploadConnections: (): string => "/referrals/upload-connections",
    review: (triageTabPath?: JobReferralTabPaths): string => {
      if (triageTabPath) {
        return `/referrals/review/${triageTabPath}`;
      }
      return `/referrals/review`;
    },
  },

  // Interviews
  candidateInterview: (candidateId: string, interviewRubricId: string): string => {
    return `${CANDIDATE_INTERVIEWS_PATH}/${candidateId}/${interviewRubricId}`;
  },
  interviewScheduler: (): string => "/interview-scheduler",
  nextSteps: (candidateInterviewId: string, nextStepType?: NextStepType): string => {
    if (nextStepType) {
      return `/next-steps/${candidateInterviewId}/${nextStepType}`;
    }
    return `/next-steps/${candidateInterviewId}`;
  },

  // Company Setup
  companySetup: (companySetupStep?: CompanySetupSectionType, queryParams?: URLSearchParams): string => {
    const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";
    if (companySetupStep) {
      return `/settings/company/${companySetupStep}${queryParamStr}`;
    }
    return `/settings/company${queryParamStr}`;
  },
  contacts: (): string => "/contacts",

  // Account Setup (now nested under Company Setup)
  accountSetup: (accountSetupStep?: AccountSettingsSectionType): string => {
    if (accountSetupStep) {
      return `/settings/account/${accountSetupStep}`;
    }
    return "/settings/account";
  },

  // Careers Page
  editCareersPage: (publishTab?: boolean): string => {
    if (publishTab) {
      return "/edit-careers-page?tab=Publish%20Jobs";
    }
    return "/edit-careers-page";
  },
  // User Settings
  userSettings: {
    interviewPreferences: (section?: UserSettingPaths): string => {
      if (section) {
        return `/settings/${section}`;
      }
      return `/settings/interviews`;
    },
    gmailAuth: (): string => "/settings/gmail-auth",
    emailConnection: (): string => "/settings/account/email-connection",
  },

  reports: {
    reporting: (jobId?: string): string => {
      if (jobId) {
        return `/reports/${jobId}`;
      }
      return "/reports";
    },
    // Purposely did not include old job-report one because we should stop linking to that path
    visualExplorer: (): string => "/visual-explorer",
  },
  help: (): string => "/get-help",
  billing: {
    // Feels not good to control imports from an enum from the api client
    beginSession: (billingSessionType?: BillingSessionPathEnum): string => {
      if (billingSessionType) {
        return `/billing/session/begin/${billingSessionType}`;
      }
      return "/billing/session/begin";
    },
    sessionSuccess: (billingSessionType: string): string => `/billing/session/success/${billingSessionType}`,
  },
  hiringPlan: (): string => "/hiring-plan",
  searches: (queryParams?: URLSearchParams): string => {
    const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

    return "/searches" + queryParamStr;
  },
  sourcing: (searchId: string, queryParams?: URLSearchParams): string => {
    const queryParamStr = queryParams ? convertUrlQueryParamsToString(queryParams) : "";

    return `/sourcing/${searchId}` + queryParamStr;
  },

  // Admin-only pages
  admin: {
    impersonator: (): string => "/admin/impersonator",
    jobEmailTemplates: (jobId: string, emailTemplateId?: string): string => {
      if (emailTemplateId) {
        return `/admin/job/${jobId}/hiring-stage-email-templates/${emailTemplateId}`;
      }
      return `/admin/job/${jobId}/hiring-stage-email-templates`;
    },
    campaign: (campaignId: string): string => `/admin/campaign/${campaignId}`,
    search: {
      allSearches: (): string => "/admin/searches",
      createTransform: (searchId: string): string => `/admin/search/${searchId}/create-transform`,
      createSearch: (searchId: string): string => `/admin/search/${searchId}/create`,
      editSearch: (searchId: string): string => `/admin/search/${searchId}/edit`,
      calibrationCandidatesReview: (): string => "/admin/calibration-candidates-review",
      searchTemplates: (): string => "/admin/search-templates",
      attributeBuilder: (): string => "/admin/attribute-builder",
      similarCandidatesReviewer: (): string => "/admin/similar-candidates-reviewer",
      interviews: (): string => "/admin/interviews",
      profile: (): string => "/admin/profile",
    },
  },
  // Public Facing
  inboundApplicationPortal: (clientName: string, searchOrJobId: string): string =>
    `/apply/${clientName}/${searchOrJobId}`,
  careersPage: (clientName: string, clientId: string): string => `/${clientName}/careers/${clientId}`,
  unauthorized: (): string => "/unauthorized",
  applicationsReviewer: (): string => "/applications-reviewer",
};
