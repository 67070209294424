import TextField from "@mui/material/TextField";
import { parseInt } from "lodash";
import React from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";
import styled from "styled-components";

import { Subtitle2 } from "components/library/typography";
import { colors } from "styles/theme";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 40px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export interface StyledNumberFieldProps<T extends FieldValues> {
  error?: boolean;
  helperText?: string;
  control?: Control<T>;
  name: Path<T>;
}

const StyledNumberField = <T extends FieldValues>({
  error,
  helperText,
  control,
  name,
}: StyledNumberFieldProps<T>): React.ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): React.ReactElement => (
        <StyledTextField
          type="number"
          value={field.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            if (value === "") {
              field.onChange(null);
              return;
            }

            const parsedValue = value.replace(",", ".");

            if (!/^\d*\.?\d*$/.test(parsedValue)) return;

            let inputValue = parseInt(parsedValue);
            const [integerPart, decimalPart] = parsedValue.split(".");

            if (decimalPart) {
              inputValue = parseFloat(`${integerPart}.${decimalPart.slice(0, 2)}`);
            }

            field.onChange(inputValue);
          }}
          error={error}
          helperText={<Subtitle2 color={colors.critical.base}>{helperText}</Subtitle2>}
        />
      )}
    />
  );
};

export default StyledNumberField;
