import { CommandBarClientSDK } from "commandbar";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { NavigateFunction } from "react-router-dom";

import CommandBarCandidatePreview from "App/components/Nav/CommandBarCandidatePreview";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType, UserSettingPaths } from "App/routing/types";
import { ApiApi, CandidateBio, SearchDoverResult } from "services/openapi";

interface CommandBarNavigationItem {
  label: string;
  text?: string;
  path: string;
  external?: boolean;
}

// NOTE: DO NOT CHECK FOR FEATURE FLAGS OR AUTH0 USER INFO IN HERE
class DoverCommandBar {
  private static instance: DoverCommandBar;

  //------------------------------------------------------------------
  //  Constants
  //------------------------------------------------------------------

  // Readonly constants
  private readonly candidatesCategoryName = "Candidates";
  private readonly candidatesRecordKey = "candidate";
  private readonly candidatePreviewComponentId = "candidate-preview-component";
  private readonly navigationCategoryName = "Navigation";
  private readonly navigationItems: CommandBarNavigationItem[] = [
    {
      label: "Settings",
      path: APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.GENERAL),
    },
    {
      label: "Team Members & Permissions",
      path: APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.MEMBERS),
    },
    {
      label: "Interview and scheduling preferences",
      path: APP_ROUTE_PATHS.userSettings.interviewPreferences(UserSettingPaths.Interviews),
    },
    {
      label: "Notification preferences",
      path: APP_ROUTE_PATHS.userSettings.interviewPreferences(UserSettingPaths.Notifications),
    },
    {
      label: "Help Center",
      path: "https://help.dover.com/en/",
      external: true,
    },
    {
      label: "Exclude LinkedIn Connections",
      path: APP_ROUTE_PATHS.contacts(),
    },
    // Not nested
    {
      label: "Home",
      path: APP_ROUTE_PATHS.home(),
    },
    {
      label: "Review",
      path: APP_ROUTE_PATHS.review({}),
    },
    {
      label: "Careers Page",
      path: APP_ROUTE_PATHS.editCareersPage(),
    },
    {
      label: "Sourcing Copilot",
      path: APP_ROUTE_PATHS.sourcingCopilot(),
    },
    {
      label: "Reporting & Analytics",
      path: APP_ROUTE_PATHS.reports.visualExplorer(),
    },
  ];
  private readonly jobsCategoryName = "Jobs";
  private readonly jobsRecordKey = "jobs";

  //------------------------------------------------------------------
  //  Class variables
  //------------------------------------------------------------------

  private candidateBioCache: { [key: string]: CandidateBio } = {};
  private navigate: NavigateFunction;
  private apiClient: ApiApi;
  private initialized: boolean;
  private commandBarClient: CommandBarClientSDK;
  private userId: string;
  private showKeyboardShortcuts: () => void;
  private onCreateJob: () => void;
  private onAddCandidate: () => void;
  private onInviteUsers: () => void;
  private onSlackEmbbededRecruiter: () => void;

  //------------------------------------------------------------------
  //  Private constructor
  //------------------------------------------------------------------

  private constructor(
    navigate: NavigateFunction,
    apiClient: ApiApi,
    userId: string,
    showKeyboardShortcuts?: () => void,
    onCreateJob?: () => void,
    onAddCandidate?: () => void,
    onInviteUsers?: () => void,
    onSlackEmbbededRecruiter?: () => void
  ) {
    this.navigate = navigate;
    this.apiClient = apiClient;
    this.initialized = false;
    this.userId = userId;
    this.showKeyboardShortcuts = showKeyboardShortcuts ?? ((): void => {});
    this.onCreateJob = onCreateJob ?? ((): void => {});
    this.onAddCandidate = onAddCandidate ?? ((): void => {});
    this.onInviteUsers = onInviteUsers ?? ((): void => {});
    this.onSlackEmbbededRecruiter = onSlackEmbbededRecruiter ?? ((): void => {});

    if (!window.CommandBar) {
      throw new Error("CommandBar not found");
    }

    this.commandBarClient = window.CommandBar;
  }

  //------------------------------------------------------------------
  //  Public functions
  //------------------------------------------------------------------

  public static getInstance(
    navigate: NavigateFunction,
    apiClient: ApiApi,
    userId: string,
    showKeyboardShortcuts?: () => void,
    onCreateJob?: () => void,
    onAddCandidate?: () => void,
    onInviteUsers?: () => void,
    onSlackEmbbededRecruiter?: () => void
  ): DoverCommandBar {
    if (!DoverCommandBar.instance) {
      DoverCommandBar.instance = new DoverCommandBar(
        navigate,
        apiClient,
        userId,
        showKeyboardShortcuts,
        onCreateJob,
        onAddCandidate,
        onInviteUsers,
        onSlackEmbbededRecruiter
      );
    }

    return DoverCommandBar.instance;
  }

  public async register(): Promise<void> {
    try {
      if (!this.initialized) {
        // Boot it
        await this.commandBarClient.boot(this.userId);

        // Add navigation router
        this.commandBarClient.addRouter(this.navigateTo.bind(this));

        this.addNavigationItems();
        this.addActions();

        //------------------------------------------------------------------
        // Add Record types
        //------------------------------------------------------------------

        // Candidates
        this.addCandidateRecords(); // Bind record type to api search
        this.addCandidatePreivewComponent();
        this.addCandidateRecordActions();

        // Jobs
        this.addJobsRecords(); // Bind record type to api search
        this.addJobsRecordActions();
      }
    } catch (e) {
      console.error("Error registering CommandBar", e);
    } finally {
      this.initialized = true;
    }
  }

  //------------------------------------------------------------------
  //  Helper functions
  //------------------------------------------------------------------

  private navigateTo(path: string): void {
    this.navigate(path);
  }

  private addNavigationItems(): void {
    this.navigationItems.forEach(item => {
      if (item.external) {
        this.commandBarClient.addCommand({
          text: item.text ?? item.label,
          name: item.label,
          template: {
            type: "link",
            value: item.path,
            operation: "self",
          },
          category: this.navigationCategoryName,
        });
        return;
      } else {
        this.commandBarClient.addCommand({
          text: item.text ?? item.label,
          name: item.label,
          template: {
            type: "link",
            value: item.path,
            operation: "router",
          },
          category: this.navigationCategoryName,
        });
      }
    });
  }

  private addActions(): void {
    // Show keyboard shortcuts
    this.commandBarClient.addCallback<any, void>("action__show_keyboard_shortcuts", () => {
      this.showKeyboardShortcuts();
    });

    this.commandBarClient.addCommand({
      text: "Show Keyboard Shortcuts (/)",
      name: "Show Keyboard Shortcuts",
      template: {
        type: "callback",
        value: "action__show_keyboard_shortcuts",
      },
      icon: "⌥",
      category: "Actions",
    });

    // Create job
    this.commandBarClient.addCallback<any, void>("action__create_job", () => {
      this.onCreateJob();
    });
    this.commandBarClient.addCommand({
      text: "Create job (j)",
      name: "Create job",
      template: {
        type: "callback",
        value: "action__create_job",
      },
      icon: "💼",
      category: "Actions",
    });

    // Add candidate
    this.commandBarClient.addCallback<any, void>("action__add_candidate", () => {
      console.log("Add candidate");
      this.onAddCandidate();
    });
    this.commandBarClient.addCommand({
      text: "Add Candidate (c)",
      name: "Add Candidate",
      template: {
        type: "callback",
        value: "action__add_candidate",
      },
      icon: "👤",
      category: "Actions",
    });

    // Invite users
    this.commandBarClient.addCallback<any, void>("action__invite_user", () => {
      this.onInviteUsers();
    });
    this.commandBarClient.addCommand({
      text: "Invite user (t)",
      name: "Invite user",
      template: {
        type: "callback",
        value: "action__invite_user",
      },
      icon: "💌",
      category: "Actions",
    });

    // Slack Recruiting Partner
    this.commandBarClient.addCallback<any, void>("action__slack_embedded_recruiter", () => {
      this.onSlackEmbbededRecruiter();
    });
    this.commandBarClient.addCommand({
      text: "Message Recruiting Partner (m)",
      name: "Message Recruiting Partner",
      template: {
        type: "callback",
        value: "action__slack_embedded_recruiter",
      },
      icon: "💬",
      category: "Actions",
    });
  }

  //------------------------------------------------------------------
  // [Records] Candidates
  //------------------------------------------------------------------

  private addCandidateRecords(): void {
    this.commandBarClient.addRecords(this.candidatesRecordKey, [], {
      onInputChange: async (search: string): Promise<any> => {
        const searchResponse = await this.apiClient.searchDover({ search, type: "recent_active_candidates" });
        // TODO: filter at the API level
        return (searchResponse?.results ?? []).filter(r => r.type === "Candidate");
      },
      recordOptions: { categoryName: this.candidatesCategoryName },
      defaultIcon: "👤",
      labelKey: "name",
      searchTabEnabled: false,
      detail: { type: "component", value: this.candidatePreviewComponentId },
    });
  }

  private addCandidateRecordActions(): void {
    // Open detail view (default)
    this.commandBarClient.addCallback<any, void>("candidate__view_profile", args => {
      this.navigateTo(APP_ROUTE_PATHS.candidates.candidateDetail(args.record.id, new URLSearchParams({})));
    });
    this.commandBarClient.addRecordAction(
      this.candidatesRecordKey,
      {
        text: "View profile",
        name: "candidate__view_profile",
        template: {
          type: "callback",
          value: "candidate__view_profile",
        },
        icon: "👤",
      },
      true,
      false
    );

    // Add note
    this.commandBarClient.addCallback<any, void>("candidate__add_note", args => {
      this.navigateTo(
        APP_ROUTE_PATHS.candidates.candidateDetail(
          args.record.id,
          new URLSearchParams({
            activeFilter: "notes",
          })
        )
      );
    });
    this.commandBarClient.addRecordAction(
      this.candidatesRecordKey,
      {
        text: "Add note",
        name: "candidate__add_note",
        template: {
          type: "callback",
          value: "candidate__add_note",
        },
        icon: "💬",
      },
      false,
      false
    );

    // Schedule
    // TODO

    // Reject
    // TODO

    // Email
    // TODO

    // Open LinkedIn
    // TODO

    // Open Resume
    // TODO
  }

  private addCandidatePreivewComponent(): void {
    this.commandBarClient.addComponent(this.candidatePreviewComponentId, "Candidate Preview", {
      mount: elem => ({
        // @ts-ignore
        render: async (data: SearchDoverResult): Promise<void> => {
          elem.innerHTML = ReactDOMServer.renderToString(
            <CommandBarCandidatePreview
              details={{
                fullName: data.name,
                currentEmployment: data.candidateDetails?.currentEmployment,
                jobTitle: data.candidateDetails?.jobTitle ?? "",
                linkedInUrl: data.candidateDetails?.linkedinUrl ?? "",
                stageName: data.candidateDetails?.stageName,
                status: data.candidateDetails?.status,
              }}
            />
          );
        },
        unmount: (): void => {},
      }),
    });
  }

  //------------------------------------------------------------------
  // [Records] Jobs
  //------------------------------------------------------------------

  private addJobsRecords(): void {
    this.commandBarClient.addRecords(this.jobsRecordKey, [], {
      onInputChange: async (search: string): Promise<any> => {
        const searchResponse = await this.apiClient.searchDover({ search, type: "job_only" });
        return searchResponse?.results ?? [];
      },
      recordOptions: { categoryName: this.jobsCategoryName },
      defaultIcon: "💼",
      labelKey: "name",
      searchTabEnabled: false,
    });
  }

  private addJobsRecordActions(): void {
    // View job (default)
    this.commandBarClient.addRecordAction(
      this.jobsRecordKey,
      {
        text: "View job",
        name: "job__view",
        template: {
          type: "link",
          value: "{{record.path}}",
          operation: "router",
        },
        icon: "💼",
      },
      true
    );

    // Open Slack channel
    // TODO
    // this.commandBarClient.addCallback<any, void>("job__open_slack_channel", () => {
    //   alert("Would open slack channel...");
    // });
    // this.commandBarClient.addRecordAction(
    //   this.jobsRecordKey,
    //   {
    //     text: "Open slack channel",
    //     name: "job__open_slack_channel",
    //     template: {
    //       type: "callback",
    //       value: "job__open_slack_channel",
    //     },
    //     icon: "💬",
    //   },
    //   true
    // );

    // View candidates
    window.CommandBar.addCallback<{ record: { id: string } }, void>("job__view_candidates", args => {
      this.navigateTo(APP_ROUTE_PATHS.job.candidates.candidatesTable(args.record.id));
    });
    window.CommandBar.addRecordAction(
      this.jobsRecordKey,
      {
        text: "View candidates",
        name: "job__view_candidates",
        template: {
          type: "callback",
          value: "job__view_candidates",
        },
        icon: "👤",
      },
      false
    );

    // Applicant review
    window.CommandBar.addCallback<{ record: { id: string } }, void>("job__view_applicants", args => {
      this.navigateTo(APP_ROUTE_PATHS.job.applicationReviewV2(args.record.id));
    });
    window.CommandBar.addRecordAction(
      "jobs",
      {
        text: "View applicants",
        name: "job__view_applicants",
        template: {
          type: "callback",
          value: "job__view_applicants",
        },
        icon: "👤",
      },
      false
    );
  }
}

export default DoverCommandBar;
