import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import React, { FC } from "react";

import { GmailAuthContent } from "components/dover/GmailAuth";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { colors } from "styles/theme";

export const GoogleConnectModal: FC<GlobalModalProps> = ({ isOpen, close }) => {
  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md">
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            "&.MuiIconButton-root": {
              position: "absolute",
              right: 8,
              top: 8,
              color: colors.grayscale.gray500,
              zIndex: 1000,
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ justifyContent: "center", alignItems: "center" }} p={10}>
          <GmailAuthContent />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const googleConnectModalAtom = modalAtom(GoogleConnectModal);
