import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useCallback, useState } from "react";
import { Form } from "react-final-form";
import { useParams } from "react-router-dom";

import { ReactComponent as NewTabIconSVG } from "assets/icons/external-link.svg";
import { ButtonRadio } from "components/library/ButtonRadio";
import { BodySmall, Heading } from "components/library/typography";
import CustomModal from "components/Modal";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { useSubmitJobCandidateSourceFormMutation } from "services/doverapi/endpoints/job-source-settings/endpoints";
import { JobSourceFormValues } from "services/doverapi/endpoints/job-source-settings/types";
import {
  CandidateSource,
  CandidateSourceFeaturesEnum,
  JobCandidateSourceSettingDesiredStateEnum,
  JobCandidateSourceSettingExternalJobOriginEnum,
  JobCandidateSourceSettingStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { showSuccessToast } from "utils/showToast";
import {
  getSourceIsReadyForUse,
  isOneClickIntegration,
  setValue,
  showSubscriptionDetailsForActiveSource,
} from "views/job/JobBoards/components/ConfigureSource/helpers";
import { getInitialValues, validateFormValues } from "views/job/JobBoards/components/ConfigureSource/helpers";
import SelfToPostModalContent, {
  SelfPostingActionButtons,
} from "views/job/JobBoards/components/ConfigureSource/SelfToPostModalContent";
import SubscriptionDetails from "views/job/JobBoards/components/ConfigureSource/SubscriptionDetails";
import {
  AddEditModalProps,
  SubmitWithDesiredStateProps,
  JobSourceFormErrors,
} from "views/job/JobBoards/components/ConfigureSource/types";
import { JobSourceDetails } from "views/job/JobBoards/components/JobSourceDetails";
import { YC_WAAS_ID } from "views/job/JobBoards/constants";

/***** Sub-Components *****/

const ModalTitle = ({ source }: { source: CandidateSource }): ReactElement => {
  if (source.sourceDetailsUrl) {
    return (
      <ExternalLink href={source.sourceDetailsUrl} target="__blank" rel="noopener noreferrer">
        <Stack direction="row" spacing={1} alignItems="center">
          <Heading>{source.label}</Heading>
          <NewTabIconSVG className="svg-fill" color={colors.grayscale.gray700} />
        </Stack>
      </ExternalLink>
    );
  }
  return <Heading>{source.label}</Heading>;
};

/***** Main Component *****/

export const ConfigureSourceModal = ({
  isModalOpen,
  toggleModalOff,
  selectedEnrichedSource,
}: AddEditModalProps): ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();

  const { data: job } = useGetJobSetupQuery(jobId ?? skipToken);
  const isSampleJob = job?.isSample;

  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>(undefined);
  const [doverToPost, setDoverToPost] = useState<boolean>(true); // Default to true since these are one-click integrations

  const isBasePlanCustomer = useIsBasePlanCustomer();

  const closeModalHandler = React.useCallback((): void => {
    // Reset state
    setDoverToPost(true); // Reset to default value
    setPaymentErrorMessage(undefined);
    toggleModalOff();
  }, [toggleModalOff]);

  const [submitJobCandidateSourceForm] = useSubmitJobCandidateSourceFormMutation();

  const onSubmit = useCallback(
    async (values: JobSourceFormValues): Promise<Object | undefined> => {
      if (!jobId) {
        return;
      }
      const sourceIsActive =
        selectedEnrichedSource?.jobCandidateSourceSettings?.state === JobCandidateSourceSettingStateEnum.Active;

      const newExternalJobOrigin = doverToPost
        ? JobCandidateSourceSettingExternalJobOriginEnum.Dover
        : JobCandidateSourceSettingExternalJobOriginEnum.Client;

      try {
        await submitJobCandidateSourceForm({
          ...values,
          candidateSourceId: selectedEnrichedSource?.candidateSource.id!,
          externalJobOrigin: sourceIsActive ? undefined : newExternalJobOrigin,
          jobId: jobId,
        }).unwrap();
      } catch (error) {
        console.error(error);
        if (error && typeof error === "object" && "status" in error && (error.status === 402 || error.status === 404)) {
          const errorResponse = await (error as any).json();
          setPaymentErrorMessage(errorResponse.message);
        } else if (error && typeof error === "object" && "data" in error && (error as any).data?.error) {
          setPaymentErrorMessage((error as any).data.error.message);
        } else {
          setPaymentErrorMessage("There was an issue with your payment method. Please try again.");
        }

        return { submissionError: "Failed to submit form values" };
      }

      const successText =
        values.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active
          ? "Successfully added job board posting"
          : "Successfully removed job board posting";
      showSuccessToast(successText);
      closeModalHandler();

      return undefined;
    },
    [
      jobId,
      selectedEnrichedSource?.candidateSource.id,
      selectedEnrichedSource?.jobCandidateSourceSettings?.state,
      closeModalHandler,
      submitJobCandidateSourceForm,
      doverToPost,
    ]
  );

  if (!selectedEnrichedSource) {
    return <></>;
  }

  return (
    <CustomModal
      open={isModalOpen}
      onClose={(): void => {
        closeModalHandler();
      }}
      title={<ModalTitle source={selectedEnrichedSource.candidateSource} />}
      maxWidth={"sm"}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={getInitialValues(selectedEnrichedSource)}
        validate={(values: JobSourceFormValues): JobSourceFormErrors =>
          validateFormValues({
            values,
            source: selectedEnrichedSource.candidateSource,
          })
        }
        mutators={{ setValue }}
      >
        {({
          form: {
            submit,
            mutators: { setValue },
          },
          submitting,
          submitFailed,
          dirtyFields,
          valid,
          values,
        }): React.ReactNode => {
          const { candidateSource: source, jobCandidateSourceSettings: sourceSettings } = selectedEnrichedSource;

          const selectedPricingTier = source.pricingTiers?.find(pricingTier => pricingTier.id === values.pricingTierId);

          const isFormPristine: boolean = Object.keys(dirtyFields).length === 0;

          const readyForUse = getSourceIsReadyForUse(source, selectedPricingTier?.price);

          const isEditable = !!source.externallyEditable;
          const isActive = sourceSettings?.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active;
          const isWaaS = source.id === YC_WAAS_ID;
          const supportsAdditionalInfo = !!source.features?.includes(
            CandidateSourceFeaturesEnum.SupportsAdditionalInfo
          );

          const sourceIsOneClickIntegration = isOneClickIntegration(source);

          const shouldShowSubscriptionDetailsForActiveSource = showSubscriptionDetailsForActiveSource({
            enrichedCandidateSourceDetails: selectedEnrichedSource,
            isFreeCustomer: isBasePlanCustomer,
          });

          const setDesiredStateAndSubmit = ({ desiredState, forceSetState }: SubmitWithDesiredStateProps): void => {
            setValue("desiredState", desiredState);
            setValue("forceSetState", forceSetState);
            submit();
          };

          return (
            <Stack spacing={2}>
              <JobSourceDetails
                enrichedSourceDetails={selectedEnrichedSource}
                showExternalJobOptions={isEditable && readyForUse}
                showAdditionalInfoField={supportsAdditionalInfo}
                sourceSettings={sourceSettings}
              />
              {isSampleJob && <BodySmall weight="500">Posting to job boards is disabled for sample jobs.</BodySmall>}
              {!isSampleJob && !isActive && (
                <Stack spacing={2}>
                  {sourceIsOneClickIntegration ? (
                    <Stack spacing={1}>
                      <BodySmall weight="500">How will you set up your posting?</BodySmall>
                      <Stack direction="row" spacing={1}>
                        <ButtonRadio
                          active={doverToPost}
                          onClick={(): void => setDoverToPost(true)}
                          label="Dover one-click integration"
                        />
                        <ButtonRadio
                          active={!doverToPost}
                          onClick={(): void => setDoverToPost(false)}
                          label="I will do it manually"
                        />
                      </Stack>
                      {doverToPost ? (
                        <SubscriptionDetails
                          selectedEnrichedSource={selectedEnrichedSource}
                          setDesiredStateAndSubmit={setDesiredStateAndSubmit}
                          submitting={submitting}
                          submitFailed={submitFailed}
                          valid={valid}
                          pristine={isFormPristine}
                          paymentErrorMessage={paymentErrorMessage}
                          selectedPricingTier={selectedPricingTier}
                        />
                      ) : (
                        <SelfToPostModalContent
                          selectedEnrichedSource={selectedEnrichedSource}
                          closeModalHandler={closeModalHandler}
                          displayInstructions={!isWaaS}
                        />
                      )}
                    </Stack>
                  ) : (
                    <SelfToPostModalContent
                      selectedEnrichedSource={selectedEnrichedSource}
                      closeModalHandler={closeModalHandler}
                      displayInstructions={!isWaaS}
                    />
                  )}
                </Stack>
              )}
              {isActive && (
                <>
                  {shouldShowSubscriptionDetailsForActiveSource ? (
                    <SubscriptionDetails
                      selectedEnrichedSource={selectedEnrichedSource}
                      setDesiredStateAndSubmit={setDesiredStateAndSubmit}
                      submitting={submitting}
                      submitFailed={submitFailed}
                      valid={valid}
                      pristine={isFormPristine}
                      paymentErrorMessage={paymentErrorMessage}
                      selectedPricingTier={selectedPricingTier}
                    />
                  ) : (
                    <SelfPostingActionButtons
                      selectedEnrichedSource={selectedEnrichedSource}
                      closeModalHandler={closeModalHandler}
                    />
                  )}
                </>
              )}
            </Stack>
          );
        }}
      </Form>
    </CustomModal>
  );
};
