import { format } from "date-fns/fp";

import { CandidateBio } from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { NextUpLabelEnum } from "views/candidates/types";

export const formatTime = format("h:mmaaa");
export const formatDateShort = format("MMM d");

export const defaultSchedulingText =
  "If the candidate books a time via a Dover Scheduling link, we'll automatically track their interview status here.";

export interface NextActionStateComponentProps {
  candidateBio: CandidateBio;
}

export type NextUpColor = { backgroundColor: string; borderColor: string; headerTextColor: string };
export const colorsMap: Record<string, NextUpColor> = {
  yellow: {
    backgroundColor: backgrounds.yellow,
    borderColor: "#EFB449",
    headerTextColor: colors.warning.dark,
  },
  blue: {
    backgroundColor: colors.informational.light,
    borderColor: colors.linkLight,
    headerTextColor: colors.informational.dark,
  },
  red: {
    backgroundColor: colors.critical.light,
    borderColor: colors.critical.base,
    headerTextColor: colors.critical.dark,
  },
  green: {
    backgroundColor: colors.primary.light,
    borderColor: colors.primary.base,
    headerTextColor: colors.primary.hover,
  },
  white: {
    backgroundColor: colors.grayscale.gray100,
    borderColor: colors.grayscale.gray400,
    headerTextColor: "",
  },
};

export const nextActionLabelColorsMap: Record<NextUpLabelEnum, NextUpColor> = {
  // New Lead
  [NextUpLabelEnum.Sourced]: colorsMap.white,
  [NextUpLabelEnum.OutreachQueued]: colorsMap.green,
  [NextUpLabelEnum.OutreachFailed]: colorsMap.red,
  [NextUpLabelEnum.NeedsReview]: colorsMap.yellow,

  // Contacted
  [NextUpLabelEnum.Contacted]: colorsMap.blue,
  [NextUpLabelEnum.FollowupQueued]: colorsMap.blue,

  // Initial call and beyond
  [NextUpLabelEnum.PendingCustomerResponse]: colorsMap.yellow,
  [NextUpLabelEnum.InterviewCompletedWithDover]: colorsMap.blue,
  [NextUpLabelEnum.NeedsAction]: colorsMap.yellow,

  // scheduling
  [NextUpLabelEnum.DoverScheduling]: colorsMap.blue,
  [NextUpLabelEnum.YouAreScheduling]: colorsMap.yellow,

  // scheduled
  [NextUpLabelEnum.InterviewScheduled]: colorsMap.green,
};
