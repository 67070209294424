import { Params } from "react-router-dom";

import { FeatureFlag } from "hooks/useFeatureFlag";

/**
 * Describes the metadata relevant to a route that requires authentication.
 * @property allowedRoles - The user roles that are allowed to access the route. If omitted, all roles are allowed
 * @property requiresSelectedClientOverride - Whether the route requires a selected client to be set. Overrides the default behaviour of the AuthedRoute component based on whether or not the route is admin-only
 * @property requiresAuth - Whether the route requires authentication
 * @property requiredFeatureFlag - The feature flag required to access the route
 */
export interface AuthedRouteMetaData {
  allowedRoles?: string[];
  requiresSelectedClientOverride?: boolean;
  requiresAuth: boolean;
  requiredFeatureFlag?: FeatureFlag;
}
/**
 * The metadata for IndividualRouteConfig which is passed into route.handle which can be accessed via useMatches()
 */
export interface IndivididualRouteConfigMetaData {
  isTopLevel?: boolean;
  isMetaRoute?: boolean;
  authedRouteMetaData: AuthedRouteMetaData;
}

/**
 * Type for return value of react router useMatches() hook
 */
export declare type UseMatchesResponse = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: unknown;
}[];

/**
 * Describes a single route for the Dover web app
 * @property path - The path of the route
 * @property element - The React element to render for the route
 * @property authedRouteMetaData - Metadata for the route regarding auth.
 * @property children - Child routes of the route
 * @property isTopLevel - Whether the route is a top-level route (i.e. not a child route)
 * @property isMetaRoute - Whether the route is a meta route (i.e. not a route that renders a component directly)
 */
export interface IndividualRouteConfig extends IndivididualRouteConfigMetaData {
  path: string;
  element: React.ReactElement;
  children?: IndividualRouteConfig[];
}

export enum UserSettingPaths {
  Notifications = "notifications",
  Interviews = "interviews",
}

export enum JobReferralTabPaths {
  NeedsReview = "needs-review",
  NeedsManualOutreach = "needs-manual-outreach",
  Contacted = "contacted",
  DontReachOut = "dont-reach-out",
}

export type NextStepType = "approve" | "reject" | "no-show";

export enum AddCandidateDrawerTabPaths {
  Outreach = "outreach",
  Pipeline = "pipeline",
}

export enum SourcingAutopilotStepPaths {
  JdQuestionStep = "jd-question",
  JdCaptureStep = "jd-capture",
  JobCreationStep = "job-creation",
  JpqStep = "persona-questionnaire",
  SourcingStep = "sourcing",
  CalibrationSettingsStep = "calibration-settings",
  JobOutreachStep = "outreach",
  AutopilotStep = "email-config",
  ScheduleOnboardingStep = "schedule-onboarding",
  CreditCardCollectionStep = "card-collection",
  CompletedStep = "success",
}

export enum BillingSessionPathEnum {
  JobBoards = "job-boards",
  DefaultPaymentMethod = "default-payment-method",
}

export enum CompanySetupSectionType {
  // Company setup
  ATS_SETTINGS = "ats-settings",
  DOVER_INTERVIEWER = "dover-interviewer",
  GENERAL = "general",
  API_KEY = "api-key",
  MEMBERS = "members",
  EMAIL_TEMPLATES = "email-templates",
  FEEDBACK_FORMS = "feedback-forms",
}

export enum AccountSettingsSectionType {
  // Account settings
  EMAIL_CONNECTION = "email-connection",
  INTERVIEW_SCHEDULING = "interview-scheduling",
  NOTIFICATIONS = "notifications",
}
